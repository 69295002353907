.cpnt-foto-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.cpnt-foto-btns {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

@media (max-width: 480px) {
	.cpnt-foto-btns {
		flex-direction: column;
		align-items: center;
	}
}