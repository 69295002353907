.boxes {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));;
	gap: var(--gap-item);
	font-size: 16px;

	@media (max-width: 1220px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));

		.boxes-desc {
			font-size: 14px;
		}
	}
}
.conteudo {
	display: flex;
	flex-direction: column;
	margin-top: var(--gap-big);
	gap: var(--gap-big);

	@media (max-width: 680px) {
		margin-top: var(--gap-item);
		gap: var(--gap-item);

		.texto-mobile {
			display: block;
		}
	}
}