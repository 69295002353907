.cpnt-funil-overlay {
	width: 900px;
	background-color: #FDFDFD;
}
.cpnt-funilmatch-conteudo {
	position: relative;
	z-index: 2;
}
.cpnt-funil {
	padding: 20px 0
}
.cpnt-funilmatch-bg {
	position: absolute;
	left: -40px;
	right: -40px;
	top: -40px;
	bottom: -130px;
	background: url('./bg.png') repeat center center;
	background-size: 100px auto;
	z-index: 1;
}
.cpnt-funilmatch-bg:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.9);
}
.cpnt-funilmatch-bg:before,
.cpnt-funilmatch-bg:after {
	z-index: -1;
}
.cpnt-funilmatch-bg::after {
	content: '';
	position: absolute;
	left: 31%;
	top: 55%;
	transform: translateX(-50%) translateY(-50%);
	width: 380px;
	height: 380px;
	background: url('./pesquisa.svg') no-repeat center center;
	background-size: cover;
	opacity: 0.05;
}

/*
** Funil
** ================================================== */
.cpnt-funil-linha {
	display: flex;
}
.cpnt-funil-texto {
	flex: 1;
	display: inline-flex;
	justify-content: flex-end;
	align-items: center;
}

/* Imagens */
.cpnt-funil-img {
	position: relative;
}
.cpnt-funil-img-5 {
	width: 370px;
	margin-top: -15px;
	z-index: 5;
}
.cpnt-funil-img-4 {
	width: 290px;
	margin-top: -13px;
	z-index: 4;
	margin-right: 40px;
}
.cpnt-funil-img-3 {
	width: 220px;
	margin-top: -9px;
	z-index: 3;
	margin-right: 26px;
}
.cpnt-funil-img-2 {
	width: 143px;
	margin-top: -6px;
	z-index: 2;
	margin-right: 64px;
}
.cpnt-funil-img-1 {
	width: 70px;
	margin-top: -5px;
	z-index: 1;
	margin-right: 100px;
}

/* Texto */
.cpnt-funil-texto {
	text-align: right;
	display: inline-flex;
	justify-content: flex-end;
}
.cpnt-funil-texto-wrap {
	display: inline-flex;
	align-items: center;
	margin: 20px -40px 20px 0;
	padding: 10px 50px 10px 15px;
	color: #FFF;
	border-radius: 5px 0 0 5px;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.39);
	font-size: 16px;
}
.cpnt-funil-linha-3 .cpnt-funil-texto-wrap {
	margin-top: 30px;
}
.cpnt-funil-linha-2 .cpnt-funil-texto-wrap {
	margin-top: 15px;
}
.cpnt-funil-linha-1 .cpnt-funil-texto-wrap {
	margin-top: 10px;
	margin-right: -30px;
}
.cpnt-funil-numero {
	background-color: rgba(0, 0, 0, 0.3);
	padding: 3px 10px;
	border-radius: 5px;
	font-size: 14px;
	margin-left: 20px;
}

/* Texto bg */
.cpnt-funil-linha-5 .cpnt-funil-texto-wrap {
	background-color: rgba(220, 52, 52, 0.9);
}
.cpnt-funil-linha-4 .cpnt-funil-texto-wrap {
	background-color: rgba(255, 128, 49, 0.9);
}
.cpnt-funil-linha-3 .cpnt-funil-texto-wrap {
	background-color: rgba(255, 187, 0, 0.9);
}
.cpnt-funil-linha-2 .cpnt-funil-texto-wrap {
	background-color: rgba(177, 183, 1, 0.9);
}
.cpnt-funil-linha-1 .cpnt-funil-texto-wrap {
	background-color: rgba(0, 136, 0, 0.9);
}

/*
** Animação Funil
** ================================================== */
.cpnt-funil-linha {
	opacity: 0;
	position: relative;
	top: -25px;
}
/* .cpnt-funil-linha.cpnt-funil-linha-5 {
	transition: all 0.5s ease 0s;
}
.cpnt-funil-linha.cpnt-funil-linha-4 {
	transition: all 0.5s ease 0.2s;
} */
.cpnt-funil-linha.cpnt-funil-linha-3 {
	transition: all 0.5s ease 0s;
	z-index: 3;
}
.cpnt-funil-linha.cpnt-funil-linha-2 {
	transition: all 0.5s ease 0.2s;
	z-index: 2;
}
.cpnt-funil-linha.cpnt-funil-linha-1 {
	transition: all 0.5s ease 0.4s;
	z-index: 1;
}

/* Animar */
.cpnt-funil-visivel .cpnt-funil-linha {
	opacity: 1;
	top: 0;
}

/*
** Animação Texto
** ================================================== */
.cpnt-funil-texto {
	opacity: 0;
	position: relative;
	right: -15px;
}

/* .cpnt-funil-linha-5 .cpnt-funil-texto {
	transition: all 0.5s ease 0.1s;
}
.cpnt-funil-linha-4 .cpnt-funil-texto {
	transition: all 0.5s ease 0.3s;
} */
.cpnt-funil-linha-3 .cpnt-funil-texto {
	transition: all 0.5s ease 0.1s;
}
.cpnt-funil-linha-2 .cpnt-funil-texto {
	transition: all 0.5s ease 0.3s;
}
.cpnt-funil-linha-1 .cpnt-funil-texto {
	transition: all 0.5s ease 0.5s;
}

/* Animar */
.cpnt-funil-visivel .cpnt-funil-texto {
	opacity: 1;
	right: 0;
}
