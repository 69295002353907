.cpnt-profacoes-box {
	display: flex;
	justify-content: space-between;
}
.cpnt-profacoes {
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	background-color: #FFF;
}
.cpnt-profacoes-btns {
	font-size: 15px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.cpnt-profacoes-sep {
	margin: 0 10px;
	color: #CCC;
}

@media (max-width: 980px) {
	.cpnt-profacoes-box {
		flex-direction: column;
		align-items: center;
	}
	.cpnt-profsel-acoes {
		margin-top: 20px;
	}
}

@media (max-width: 680px) {
	.cpnt-profacoes-btns {
		flex-direction: column;
	}
	.cpnt-profacoes-sep {
		display: none;
	}
	.cpnt-profacoes-item {
		margin: 10px 0;
	}
}
