.cpnt-fotosagrupadas-img {
	width: 40px;
	height: 40px;
	display: inline-flex;
	border-radius: 50%;
	background-color: #FFF;
}
.cpnt-fotosagrupadas-img-borda {
	border: 2px solid #FFF;
}

.cpnt-fotosagrupadas-grupo {
	position: relative;
}
.cpnt-fotosagrupadas-grupo + .cpnt-fotosagrupadas-grupo {
	margin-left: -10px;
}

.cpnt-fotosagrupadas-grupo-mais {
	position: relative;
	margin-left: -10px;
	padding: 5px;
	border-radius: 50%;
	font-size: 11px;
	background-color: #fff;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
	color: #2E2F31;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.cpnt-fotosagrupadas-alt .cpnt-fotosagrupadas-img {
	border-radius: 0;
	background-color: transparent;
}

/* Tamanhos */
.cpnt-fotosagrupadas-tamanho-medio .cpnt-fotosagrupadas-img {
	width: 30px;
	height: 30px;
}
.cpnt-fotosagrupadas-tamanho-medio .cpnt-fotosagrupadas-grupo-mais {
	font-size: 10px;
	width: 15px;
	height: 15px;
	white-space: nowrap;
}

/* Espaços */
/* 1 */
.cpnt-fotosagrupadas-espaco-1 .cpnt-fotosagrupadas-grupo + .cpnt-fotosagrupadas-grupo {
	margin-left: 5px;
}
.cpnt-fotosagrupadas-espaco-1 .cpnt-fotosagrupadas-grupo-mais {
	margin-left: 5px;
}

/* 2 */
.cpnt-fotosagrupadas-espaco-2 .cpnt-fotosagrupadas-grupo + .cpnt-fotosagrupadas-grupo {
	margin-left: -5px;
}
.cpnt-fotosagrupadas-espaco-2 .cpnt-fotosagrupadas-grupo-mais {
	margin-left: -5px;
}
