/* Imagem */
.cpnt-perfilimg {
	position: relative;
}
.cpnt-perfilimg-container {
	border-bottom: 1px solid #111;
}
.cpnt-perfil-sidebar-inner {
	border-top: 1px solid #444;
}
.cpnt-perfilimg-wrap {
	border: 6px solid;
	border-left-color: #FFF;
	border-top-color: #FFF;
	border-right-color: #2E2F31;
	border-bottom-color: #2E2F31;
	border-radius: 50%;
	position: relative;
}
.cpnt-perfilimg-inner {
	position: relative;
	padding-top: 100%;
}
.cpnt-perfilimg-inner-img {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
}

/* Glyph */
.cpnt-perfilimg-glyph:before {
	content: '';
	position: absolute;
	border: 30px solid transparent;
	z-index: 3;
	transform: rotate(-45deg);
	opacity: 0.7;
}

/* baixo */
.cpnt-perfilimg-glyph-1::before {
	right: 15px;
	bottom: 15px;
	border-bottom-color: #2E2F31;
}
.cpnt-perfilimg-glyph-2::before {
	right: 15px;
	bottom: 15px;
	border-bottom-color: #fff;
	border-width: 15px;
}

/* cima */
.cpnt-perfilimg-glyph-3::before {
	left: 15px;
	top: 15px;
	border-top-color: #2E2F31;
}
.cpnt-perfilimg-glyph-4::before {
	left: 15px;
	top: 15px;
	border-top-color: #fff;
	border-width: 15px;
}
