.container {
	overflow: hidden;
	min-height: 100vh;
	background-color: #FDFDFD;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
}
.inner {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 30px;
	background-color: #FFF;
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius);
	box-shadow: 0 3px 5px rgba(0,0,0,0.1);
	text-align: center;
	max-width: 520px;
	margin: auto;
}
.textos {
	font-size: 18px;
	margin-top: 50px;
}
.aviso-titulo {
	font-size: 22px;
	line-height: 1.1;
}
