.cpnt-posts-boxes {
	display: flex;
	flex-wrap: wrap;
}
.cpnt-posts-boxloop {
	padding: 15px;
}
.cpnt-posts-boxloop-wrap {
	border: 1px solid #2E2F31;
	border-radius: 5px;
	overflow: hidden;
	background-color: #FFF;
}
.cpnt-posts-boxloop-wrap-link {
	position: relative;
	top: 0;
	text-decoration: none;
	display: block;
	color: inherit;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
	transition: all 0.3s ease 0s;
}
.cpnt-posts-boxloop-wrap-link:hover {
	top: -7px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	color: #2596e1;
}
.cpnt-posts-titulo-conteudo {
	padding: 15px;
}
.cpnt-posts-titulo {
	font-size: 18px;
	margin: 0;
}
.cpnt-posts-conteudo {
	font-size: 14px;
}
