.cpnt-totalepaginacao {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	background-color: #FFF;
}

@media (max-width: 680px) {
	.cpnt-totalepaginacao {
		flex-direction: column;
	}
	.cpnt-totalepaginacao .cpnt-paginacao {
		margin-top: 15px;
	}
}