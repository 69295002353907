.cpnt-textoicone {
	display: inline-flex;
	align-items: center;
}
.cpnt-textoicone-reverso {
	flex-direction: row-reverse;
}
.cpnt-textoicone-icone {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
.cpnt-textoicone-reverso .cpnt-textoicone-icone {
	margin-right: 0;
	margin-left: 10px;
}
.cpnt-textoicone-icone,
.cpnt-textoicone-texto {
	vertical-align: middle;
}
.cpnt-textoicone-icone-sem-texto {
	margin-right: 0;
}
.cpnt-textoicone-desc {
	font-size: 0.85em;
	color: #757575;
}