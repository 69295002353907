.erro-404-container {
	background-color: #000;
}
.erro-404-wrap {
	padding: 50px;
	background-color: #FFF;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	text-align: center;
}
.erro-404-logo img {
	display: block;
	margin: auto;
	max-width: 150px;
}
