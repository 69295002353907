@import '@scss/imports.scss';

.sidebar {
	background-color: var(--color-primary);
	color: var(--color-white);
	max-height: 100%;
	overflow-y: auto;
	width: 260px;
	z-index: 999;
	display: flex;
	flex-direction: column;
	scrollbar-width: thin;

	bottom: 0;
	left: 0;
	position: fixed;
	top: 0;
	transition: var(--transition);

	&.sidebar-hide {
		left: -260px;
	}

	@media (max-width: 1280px) {
		left: -260px;
		z-index: 9999999;

		&.sidebar-hide {
			left: 0;
		}
	}

	.top {
		background-color: var(--color-primary);
		padding: var(--gap-med);
		display: flex;
		justify-content: center;
		height: var(--topbar-height);
		box-sizing: border-box;

		img {
			max-width: 150px;
		}
	}

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: var(--color-white);
		font-size: var(--fs-default);
		padding: var(--gap-med);
		background-color: var(--color-dark);
		gap: var(--gap-small);
		box-sizing: border-box;
		max-width: 100%;

		.info {
			max-width: 100%;
			display: flex;
			flex-direction: column;
			gap: var(--gap-small);
		}

		.foto {
			border-radius: 50%;
			width: 80px;
			height: 80px;
		}

		.empresa,
		.email {
			font-size: var(--fs-min);
			color: var(--color-text-sidebar);
		}

		.nivel-acesso {
			color: var(--color-1);
			background-color: var(--color-black);
			padding: 5px 20px;
			border-radius: 30px;
			margin: auto;
			font-size: var(--fs-default-alt);
			font-weight: bold;
		}
	}

	.menu {
		padding: var(--gap-item);
		gap: var(--gap-med);
		display: flex;
		flex-direction: column;
		flex: 1;

		&.menu-sem-btn {
			padding-bottom: 75px;

			@media (max-width: 1280px) {
				padding-bottom: var(--gap-large);
			}
		}

		.titulo {
			color: var(--color-text-sidebar);
			font-size: 15px;
			font-weight: 400;
			margin: 0;
			padding: 0;
			text-transform: uppercase;
		}

		.menu-itens {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: var(--gap);
			margin-top: var(--gap);
		}
	}

	.menu-item {
		font-size: var(--fs-subtitulo);
		transition: var(--transition);
		color: var(--color-white);

		.menu-item-texto {
			font-size: var(--fs-default);
		}

		&:hover {
			color: var(--color-1);
		}

		// SCSS global sem gerar nome alearório com module.scss

		// &:global(.active) {
		// 	color: var(--color-1);
		// }

	}

	.footer {
		position: sticky;
		bottom: 0;
		display: flex;
		flex-direction: column;
		gap: var(--gap-med);
		padding: var(--gap-med);
		background-color: var(--color-primary);
		// padding-bottom: 55px; // Apenas com o botão de suporte
		margin-top: auto;

		.btn-footer {
			margin-bottom: 20px;
		}

		&.footer-freelancer {
			padding-bottom: 0;
		}

		@media (max-width: 1280px) {
			padding-bottom: 0;
		}
	}

}

.bg-resp {
	@extend %fill-fixed;
	z-index: 999;
	background-color: var(--color-black-alpha-3);
	transition: var(--transition);
	opacity: 0;
	visibility: hidden;

	@media (max-width: 1280px) {
		&.bg-resp-hide {
			opacity: 1;
			visibility: visible;
		}
	}
}
