.cpnt-contabilizarpg {
	font-size: 15px;
}
.cpnt-contabilizarpg-box {
	display: inline-flex;
	align-items: center;
}
.cpnt-contabilizarpg-sep {
	margin-left: 10px;
	margin-right: 10px;
}

@media (max-width: 780px) {
	.cpnt-contabilizarpg-box {
		flex-direction: column;
	}
	.cpnt-contabilizarpg-tip {
		margin-top: 10px;
	}
	.cpnt-contabilizarpg-sep {
		display: none;
	}
}