/*
** Geral
** ================================================== */
.pg-freela {
	font-family: 'Inter';
	color: #3D455C;
	background-color: #F6F7FB;
	font-size: 16px;
}
.cor-2 {
	color: #282541;
}
.boxbg {
	padding: 20px 20px;
	border-radius: 5px;
	background-color: #FFF;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	white-space: pre-line;
}

/*
** Partes
** ================================================== */
.pt-info {
	flex: 1;
	padding-left: 30px;
}
.pt-contato {
	width: 300px;
	border-radius: 10px;
	background-color: #FFF;
	margin-left: 30px;
	padding: 15px;
	box-sizing: border-box;
	font-size: 14px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.titulo {
	font-size: 38px;
	line-height: 1.2;
	flex-basis: 100%;
}
.pt-info-titulo-box {
	display: flex;
	align-items: center;
}
.titulo-disponibilidade {
	margin-left: 30px;
}
.pt-info-disp-data {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-left: auto;
	flex-wrap: wrap;
	flex-shrink: 1;
	justify-content: flex-end;
}
.pt-info-data-cadastro {
	font-size: 12px;
	margin-left: auto;
}
.subtitulo {
	font-size: 19px;
	color: #3D455C;
}
.container-tags {
	margin-top: 15px;
}
.box-tags {
	margin-top: 8px;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}
.segmentos {
	border-top: 1px solid #CCC;
	margin-top: 10px;
	padding-top: 10px;
}
.pt-video-btn-box {
	display: flex;
	justify-content: center;
}
.pt-video-btn {
	cursor: pointer;
	border-radius: 5px;
	padding: 5px 10px;
	margin: 15px auto 0;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	background-color: #FFF;
	font-size: 14px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.pt-video-btn:before {
	content: '';
	width: 22px;
	height: 22px;
	display: inline-flex;
	margin-right: 10px;
	background: url('./Images/icone-video.png') no-repeat center center;
	background-size: contain;
}
.icones-redes-sociais {
	display: flex;
	gap: 10px;
	margin-top: 10px;
}

/*
** Seção principal
** ================================================== */
.sec-principal-inner {
	padding-top: 30px;
	display: flex;
	align-items: flex-start;
}

/*
** Seção descrição
** ================================================== */
.sec-desc-inner {
	margin-top: 30px;
}

/*
** Certificados
** ================================================== */
.sec-cert {
	margin-top: 30px;
}
.boxes-certificado {
	display: grid;
	margin-top: 15px;
	gap: 15px;
	grid-template-columns: 50% 50%;
}
.box-cert {
	display: flex;
	min-width: 0;
	align-items: center;
}
.cert-data {
	margin-left: auto;
	padding-left: 20px;
	font-weight: bold;
	font-size: 20px;
}

/*
** Avaliações
** ================================================== */
.sec-aval-inner {
	display: flex;
	margin-top: 30px;
	align-items: flex-start;
}
.pt-aval-textos {
	flex: 1;
}
.pt-aval-total {
	width: 300px;
	border-radius: 10px;
	background-color: #FFF;
	margin-right: 30px;
	padding: 15px;
	box-sizing: border-box;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	position: sticky;
	top: 15px;
}
.pt-aval-estrelas-info {
	display: flex;
	align-items: center;
	margin-top: 15px;
}
.pt-aval-subdesc {
	font-weight: bold;
	font-size: 19px;
	margin-left: 10px;
}
.pt-aval-porcentagens {
	padding: 20px 0;
}
.pt-aval-box-avaliacao + .pt-aval-box-avaliacao {
	margin-top: 20px;
}

/*
** Perfil privado
** ================================================== */
.aviso-container {
	display: flex;
	min-height: 70vh;
	margin-bottom: -70px;
}
.aviso-box {
	min-width: 50%;
	max-width: 50%;
}
.aviso-titulo {
	font-size: 44px;
	line-height: 1.1;
}
.aviso-e {
	align-self: center;
	font-size: 22px;
	color: #000;
	padding: 30px 0;
}
.aviso-d {
	position: relative;
	align-self: center;
}
.aviso-img {
	display: block;
	max-width: none;
	max-height: none;
	width: 68vw;
}

/*
** Geral
** ================================================== */
.sep {
	margin: 15px 0;
	height: 1px;
	background-color: #C7C9D1;
}

@media (max-width: 1180px) {
	.sec-principal-inner {
		flex-wrap: wrap;
	}
	.pt-contato {
		margin-left: 0;
		width: 100%;
		margin-top: 30px;
	}
	.pt-info-disp-data {
		margin-top: 10px;
	}
}

@media (max-width: 980px) {
	.aviso-container {
		flex-direction: column;
		text-align: center;
	}
	.aviso-box {
		min-width: auto;
		max-width: 100%;
	}
	.aviso-img {
		width: 100%;
	}
	.aviso-titulo {
		font-size: 30px;
	}
	.aviso-d {
		padding-bottom: 20px;
	}
}

@media (max-width: 880px) {
	.sec-principal-inner {
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.pt-info {
		padding-left: 0;
		padding-top: 5px;
		text-align: center;
	}
	.pt-info-titulo-box {
		flex-direction: column;
		padding-top: 5px;
	}
	.pt-info-disp-data {
		flex-direction: column;
		gap: 5px;
		margin-left: 0;
	}
	.subtitulo {
		padding-top: 5px;
	}
	.titulo-disponibilidade {
		margin-left: 0;
	}
	.box-tags {
		justify-content: center;
	}
	.sec-aval-inner {
		flex-direction: column;
		width: 100%;
	}
	.pt-aval-total {
		width: 100%;
		position: static;
	}
	.pt-aval-textos {
		margin-top: 30px;
	}
}