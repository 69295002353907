.cpnt-topopage {
	background-color: #2E2F31;
	color: #FFF;
	padding: 30px 0;
}
.cpnt-topopage-logo-wrap {
	max-width: 250px;
	min-width: 250px;
	text-align: right;
}
.cpnt-topopage-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cpnt-topopage-logo-wrap a {
	color: #FFF;
}

@media (max-width: 680px) {
	.cpnt-topopage-logo-wrap {
		max-width: 150px;
		min-width: 150px;
	}
}