.container-conteudo {
	padding: 50px 60px 60px;
}
.form {
	display: flex;
	flex-wrap: wrap;
	gap: 35px;
	margin-top: 30px;
}
.box-l {
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex: 1;
	align-self: flex-start;
	max-width: 300px;
}
.box-r {
	flex: 1;
	border-left: 1px solid #EAEAEA;
	padding-left: 35px;
}
.box-c {
	border-top: 1px solid #EAEAEA;
	padding-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
}
.btn-nextprev-box {

}

@media (max-width: 1180px) {
	.inputs-dados {
		flex-direction: column;
	}
	.inputs-dados .input-cnpj {
		width: 100%;
	}
}

@media (max-width: 980px) {
	.form {
		flex-direction: column;
	}
	.box-l {
		max-width: none;
  		width: 100%;
	}
	.box-r {
		border-left: 0;
		padding-left: 0;
	}
}

@media (max-width: 680px) {
	.container-conteudo {
		padding: 30px;
	}
	.box-c {
		flex-direction: column;
	}
}
