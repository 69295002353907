/*
** Geral
** ================================================== */
.tela-inicio-container {
	display: flex;
	box-shadow: 0 0 25px 2px rgba(255, 255, 255, 0.1);
	border-radius: 10px;
}

/*
** Animação BG
** ================================================== */
.tela-inicio-bg-animado-top-left {
	background-color: #050505;
	background-repeat: repeat;
    animation: bgAnimadoTopLeft 120s linear infinite;
	will-change: backrground-position;
}

/*
** Lado Foto
** ================================================== */
.tela-inicio-d {
	flex: 1;
	display: flex;
	align-items: flex-end;
	color: #FFF;
	font-size: 32px;
	padding: 50px 50px 80px;
	background-position: top center;
	background-size: cover;
	border-radius: 0 10px 10px 0;

}

/*
** Lado conteúdo
** ================================================== */
.tela-inicio-e {
	min-width: 480px;
	max-width: 480px;
	box-sizing: border-box;
	padding: 50px 60px 60px;
}
.tela-inicio-e-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.tela-inicio-e-logo {
	padding: 10px;
	max-width: 250px;
}
.tela-inicio-e-titulo {
	font-weight: normal;
	text-transform: uppercase;
	font-size: 20px;
}
.tela-inicio-form {
	width: 100%;
}
.tela-inicio-e a {
	color: #0277BD;
}
.tela-inicio-texto-resp {
	display: none;
	text-align: center;
	font-size: 13px;
	margin: 0 0 -15px;
	padding: 0 10px;
}

@media (max-width: 1180px) {
	.tela-inicio-e {
		min-width: 340px;
		max-width: 340px;
		padding: 30px;
	}
	.tela-inicio-d {
		font-size: 24px;
		padding: 30px;
	}
}

@media (max-width: 880px) {
	.tela-inicio-container {
		flex-direction: column;
	}
	.tela-inicio-e {
		min-width: auto;
		max-width: 100%;
		padding: 30px;
		width: 100%;
		box-sizing: border-box;
	}
	.tela-inicio-d {
		min-height: 350px;
		font-size: 20px;
		display: none;
	}
	.tela-inicio-e-logo {
		max-width: 250px;
	}
	.tela-inicio-texto-resp {
		display: block;
	}
}

@media (max-width: 680px) {
	.tela-inicio-e {
		padding: 30px 15px;
	}
}