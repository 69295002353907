.tela-negprocsel {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.tela-negprocsel-container {
	display: flex;
	flex: 1;
	max-height: 100%;
	min-height: 0;
	padding: 10px;
}
.tela-negprocsel-info-wrap {
	padding: 20px;
	background-color: #2E2F31;
}
.tela-negprocsel-info-box {
	min-width: 400px;
	max-width: 400px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.tela-negprocsel-chat-box {
	margin-left: 20px;
	display: flex;
	max-height: 100%;
	height: 100%;
	width: 100%;
}

/* Lado esquerdo */
.tela-negprocsel-sec + .tela-negprocsel-sec {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid #EEE;
}

/* Hunter */
.tela-negprocsel-info-hunter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Equipe */
.tela-negprocsel-info-equipe {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Habilidades */
.tela-negprocsel-info-dados {
	font-size: 16px;
	max-height: 100%;
	overflow-y: auto;
	flex: 1;
	padding: 20px;
}

.pg-negprocsel-titulo-box {
	display: flex;
	align-items: center;
	min-width: 0;
	max-width: 100%;
}
.pg-negprocsel-titulo-status {
	display: inline-flex;
	align-items: center;
	font-size: 13px;
}

.pg-negprocsel-titulo-sep-box {
	display: flex;
	font-size: 15px;
}
.pg-negprocsel-titulo-sep-box {
	font-size: 14px;
}
.pg-negprocsel-titulo-sep + .pg-negprocsel-titulo-sep {
	margin-left: 10px;
	padding-left: 10px;
	border-left: 1px solid #CCC;
}

@media (max-width: 980px) {
	.pg-negprocsel-titulo-box {
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.pg-negprocsel-btn-voltar {
		position: absolute;
		top: 50%;
		left: 10px;
		translate: 0 -50%;
	}
}

@media (max-width: 680px) {
	.tela-negprocsel-container {
		padding: 0;
	}
}

@media (max-width: 480px) {
	.pg-negprocsel-titulo-sep-box {
		font-size: 12px;
	}
	.pg-negprocsel-titulo-status {
		font-size: 12px;
	}
}