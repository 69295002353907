/*
** Título das colunas
** ================================================== */
th.lista-fechamento-col-checkbox {
	width: 40px;
}
th.lista-fechamento-col-id {
	width: 70px;
}
th.lista-fechamento-col-foto {
	width: 105px;
	max-width: 105px;
}
th.lista-fechamento-col-valor {
	width: 110px;
}
th.lista-fechamento-col-data {
	width: 110px;
}

/*
** Fotos menores
** ================================================== */
.tela-fechamento-info {
	margin-top: 15px;
	border-top: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	padding: 15px 0;
}
