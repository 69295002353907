.step {
	font-size: 14px;
}
.step .titulo {
	font-size: 18px;
	margin: 0;
	padding: 0;
	font-weight: bold;
	display: flex;
	align-items: center;
}
.step .titulo > span {
	margin-right: 20px;
}

.step-visivel {
	display: block;
}
.step-oculto {
	display: none;
}
.step-botoes-container {
	margin-top: 80px;
	padding-bottom: 50px;
}
.step-botoes-container .btn {
	padding-left: 30px;
	padding-right: 30px;
}
.step-botoes {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.perfil-foto-acoes {
	margin: auto;
	display: flex;
	justify-content: center;
}
.perfil-foto-box {
	margin: auto;
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
}
.perfil-foto {
	position: relative;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	border: 2px solid #D5D5D5;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin: auto;
}
.perfil-foto-wrap {
	position: relative;
}

.perfil-video-box {
	width: 100%;
	padding-top: 56.25%;
	border-radius: 3px;
	border: 2px solid #D5D5D5;
	position: relative;
	box-sizing: border-box;
}
.perfil-video {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-size: 60px auto;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #FAFAFA;
	width: 100%;
	height: 100%;
}

.camera-box {
	width: 500px;
	height: 375px;
	margin: auto;
	box-shadow: 0 0 0 2px #D5D5D5 inset;
	background-color: #FAFAFA;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 70px auto;
}
.foto-video-x {
	cursor: pointer;
	position: absolute;
	right: 0px;
	top: 0px;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #000 url('@img/icones/geral/x-branco.png') no-repeat center center;
	background-size: 52% auto;
	transition: background-color 0.3s ease 0s;
}
.foto-video-x:hover {
	background-color: #E74C3C;
}
.video-x {
	right: -30px;
	top: -30px;
}
.video-box-perfil-completo .video-x {
	right: 0px;
	top: -40px;
}

/* Linkedin */
.perfilsteps-img-linkedin {
	display: block;
	width: 100%;
	border: 1px solid #2E2F31;
	border-radius: 5px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.perfilsteps-box-info-pdf {
	position: relative;
	background-color: #FAFAFA;
	border: 1px dashed #999;
	border-radius: 5px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	transition: all 0.3s ease 0s;
}
.perfilsteps-box-info-pdf-hover:hover {
	background-color: #f0f9ff;
	border-color: #0277BD;
	cursor: pointer;
}

.cpnt-perfilsteps-listalinkedin {

}

/* Importação de dados */
.perfilstep-importar-info {
	display: flex;
	position: sticky;
	top: 10px;
	margin-bottom: 25px;
	z-index: 10;
}

.perfilsteps-box-info-pdf-input {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	z-index: 11;
	opacity: 0;
	cursor: pointer;
}
.perfilsteps-box-info-pdf-drag {
	position: absolute;
	left: 2px;
	right: 2px;
	top: 2px;
	bottom: 2px;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 5px;
	z-index: 10;
}

/*
** Novo
** ================================================== */
.step-wrap {
	padding: 30px 0;
}