.inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--gap-med);

	.icone-box {
		max-width: 100px;
	}

	.texto {
		font-size: var(--fs-titulo);
		text-align: center;
	}
}
