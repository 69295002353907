.cpnt-dados {
	font-size: 15px;
}
.cpnt-dadoscontato-secao + .cpnt-dadoscontato-secao {
	margin-top: 30px;
}

/* Titulo */
.cpnt-dadoscontato-titulo {
	font-size: 19px;
	margin: 0 0 15px;
	padding: 0 0 4px;
	color: #2596e1;
	position: relative;
	line-height: 1.1;
}
.cpnt-dadoscontato-titulo:after,
.cpnt-dadoscontato-titulo:before {
	content: '';
	position: absolute;
	top: 100%;
	height: 4px;
	left: 0;
	background-color: #2596e1;
}
.cpnt-dadoscontato-titulo:after {
	width: 50px;
}
.cpnt-dadoscontato-titulo:before {
	width: 100%;
	opacity: 0.4;
}

/* Foto */
.cpnt-dadoscontato-info-box {
	display: flex;
}
.cpnt-dadoscontato-info-texto {
	padding-left: 30px;
}