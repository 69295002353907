.cpnt-chatmsg-mensagem-box {
	border-radius: 3px;
	font-size: 14px;
	box-shadow: 0 2px 1px rgba(0,0,0,0.05);
	position: relative;
	flex: 1;
}

/*
** Left
** ================================================== */
.cpnt-chatmsg-mensagem-box-left {
	border: 1px solid;
}
.cpnt-chatmsg-footer-left {
	border-top: 1px solid;
}
.cpnt-chatmsg-mensagem-box-left::before,
.cpnt-chatmsg-mensagem-box-left::after {
	content: '';
	position: absolute;
	right: 100%;
	top: 15px;
	border: 10px solid transparent;
}
.cpnt-chatmsg-mensagem-box-left:after {
	margin-right: -1px;
}

/*
** Right
** ================================================== */
.cpnt-chatmsg-mensagem-box-right {
	border: 1px solid;
}
.cpnt-chatmsg-footer-right {
	border-top: 1px solid;
}
.cpnt-chatmsg-posicao-right {
	margin-left: auto;
	flex-direction: row-reverse;
}
.cpnt-chatmsg-mensagem-box-right::before,
.cpnt-chatmsg-mensagem-box-right::after {
	content: '';
	position: absolute;
	left: 100%;
	top: 15px;
	border: 10px solid transparent;
}
.cpnt-chatmsg-mensagem-box-right:after {
	margin-left: -1px;
}

/*
** Center
** ================================================== */
.cpnt-chatmsg-mensagem-box-center {
	border: 1px solid;
}
.cpnt-chatmsg-footer-center {
	border-top: 1px solid;
}
.cpnt-chatmsg-posicao-center {
	margin-left: auto;
	margin-right: auto;
}

.cpnt-chatmsg-posicao-center .cpnt-info-icone-wrap-alt {
    max-width: 50px;
}
.cpnt-chatmsg-posicao-center .cpnt-info-texto {
	margin-top: 10px;
	font-size: inherit;
}

/*
** Customizado
** ================================================== */

/* titulo */
.cpnt-chatmsg-custom-titulo {
	margin: 0;
	padding: 10px 15px;
	text-align: center;
	font-size: 18px;
	border-bottom: 1px solid;
}
.cpnt-chatmsg-custom-titulo-resposta {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cpnt-chatmsg-cpnt-disponibilidade {
	font-size: 14px;
	padding: 4px 8px;
	margin-left: 15px;
}
.cpnt-chatmsg-custom-conteudo {
	border-top: 1px solid;
	padding: 10px;
}
.cpnt-chatmsg-botoes {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 10px;
	border-top: 1px solid;
}
.cpnt-chatmsg-botao {
	margin: 5px;
}

/*
** Contrato
** ================================================== */
.cpnt-chatmsg-mensagem-contrato {
	padding: 20px 0 20px 15px;
}
.cpnt-chatmsg-mensagem-infocontrato {
	display: flex;
	align-items: center;
}
.cpnt-chatmsg-mensagem-infocontrato-img {
	max-width: 200px;
}
.cpnt-chatmsg-mensagem-infocontrato-info {
	padding-right: 25px;
	flex: 1;
}
.cpnt-chatmsg-mensagem-infocontrato-btn {
	margin-top: 25px;
}

/*
** Mensagem
** ================================================== */
.cpnt-chatmsg-mensagem {
	padding: 15px;
	white-space: pre-line;
	word-break: break-word;
}
.cpnt-chatmsg {
	display: flex;
	align-items: flex-start;
}

/* Imagem */
.cpnt-chatmsg-mensagem-imagem {
	max-width: 400px;
}

/*
** Footer
** ================================================== */
.cpnt-chatmsg-footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 12px;
	padding: 3px 5px;
}

/*
** Foto
** ================================================== */
.cpnt-chatmsg-user {
	min-width: 70px;
	max-width: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
}
.cpnt-chatmsg-user-left {
	padding-right: 15px;
}
.cpnt-chatmsg-user-right {
	padding-left: 15px;
}

.cpnt-chatmsg-user-name {
	font-size: 12px;
}

/*
** Partes
** ================================================== */
.cpnt-chatmsg-alerta {
	background-color: #FFF7F7;
	padding: 10px;
	border-bottom: 1px solid #DFDFDF;
	text-align: center;
}
.cpnt-chatmsg-alerta-titulo {
	color: #E74C3C;
	margin: 0;
	font-size: 20px;
}

/*
** Preview
** ================================================== */
/* imagem */
.cpnt-chatmsg-portal-preview {
	max-width: 900px;
}
.cpnt-chatmsg-portal-img-preview-loading {
	height: 50px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
	margin: auto;
	bottom: 0;
}

/*
** Cores dos boxes
** ================================================== */

/* Normal - Esquerda */
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box {
	background-color: #F9F9F9;
	border-color: #C9C9C9;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-footer {
	border-color: #C9C9C9;
	background-color: #FEFEFE;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-left:before {
	border-right-color: #C9C9C9;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-left:after {
	border-right-color: #F9F9F9;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #C9C9C9;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #F9F9F9;
}

/* Normal - Direita */
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-right {
	background-color: #F5FBFF;
	border-color: #93c1d3;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-footer-right {
	border-color: #93c1d3;
	background-color: #fafdff;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #93c1d3;
}
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #F5FBFF;
}

/* Custom - PROPOSTA */
.cpnt-chatmsg-tipo-proposta {
	width: 100%;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-custom-titulo {
	background-color: #2E2F31;
	border-color: #2E2F31;
	color: #FFF;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-mensagem-box {
	background-color: #FAFAFA;
	border-color: #2E2F31;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-footer {
	color: #FFF;
	border-color: #2E2F31;
	background-color: #2E2F31;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-botoes {
	border-top-color: #DFDFDF;
	background-color: #FAFAFA;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-custom-conteudo {
	border-top-color: #DFDFDF;
	background-color: #FFF;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-mensagem-box-left:before {
	border-right-color: #2E2F31;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-mensagem-box-left:after {
	border-right-color: #2E2F31;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #2E2F31;
}
.cpnt-chatmsg-tipo-proposta .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #2E2F31;
}

/* Custom - Verde */
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-custom-titulo {
	background-color: #F7FFF7;
	border-color: #229d3e;
	color: #229d3e;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-mensagem-box {
	background-color: #FFF;
	border-color: #229d3e;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-footer {
	color: #229d3e;
	border-color: #229d3e;
	background-color: #F7FFF7;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-botoes {
	border-top-color: #DFDFDF;
	background-color: #f8fff9;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-custom-conteudo {
	border-top-color: #DFDFDF;
	background-color: #FFF;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-mensagem-box-left:before {
	border-right-color: #229d3e;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-mensagem-box-left:after {
	border-right-color: #F7FFF7;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #229d3e;
}
.cpnt-chatmsg-tipo-verde .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #F7FFF7;
}

/* Custom - Vermelho */
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-custom-titulo {
	background-color: #FFF7F7;
	border-color: #E74C3C;
	color: #E74C3C;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-mensagem-box {
	background-color: #FFF;
	border-color: #E74C3C;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-footer {
	color: #E74C3C;
	border-color: #E74C3C;
	background-color: #FFF7F7;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-botoes {
	border-top-color: #DFDFDF;
	background-color: #f8fff9;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-custom-conteudo {
	border-top-color: #DFDFDF;
	background-color: #FFF;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-mensagem-box-left:before {
	border-right-color: #E74C3C;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-mensagem-box-left:after {
	border-right-color: #FFF7F7;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #E74C3C;
}
.cpnt-chatmsg-tipo-vermelho .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #FFF7F7;
}

/* Custom - Amarelo */
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-custom-titulo {
	background-color: #FFFDF7;
	border-color: #eab20e;
	color: #eab20e;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-mensagem-box {
	background-color: #FFF;
	border-color: #eab20e;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-footer {
	color: #eab20e;
	border-color: #eab20e;
	background-color: #FFFDF7;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-botoes {
	border-top-color: #DFDFDF;
	background-color: #f8fff9;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-custom-conteudo {
	border-top-color: #DFDFDF;
	background-color: #FFF;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-mensagem-box-left:before {
	border-right-color: #eab20e;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-mensagem-box-left:after {
	border-right-color: #FFFDF7;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #eab20e;
}
.cpnt-chatmsg-tipo-amarelo .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #FFFDF7;
}

/* Custom - Azul */
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-custom-titulo {
	background-color: #F7FCFF;
	border-color: #0277BD;
	color: #0277BD;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-mensagem-box {
	background-color: #FFF;
	border-color: #0277BD;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-footer {
	color: #0277BD;
	border-color: #0277BD;
	background-color: #F7FCFF;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-botoes {
	border-top-color: #DFDFDF;
	background-color: #f8fff9;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-custom-conteudo {
	border-top-color: #DFDFDF;
	background-color: #FFF;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-mensagem-box-left:before {
	border-right-color: #0277BD;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-mensagem-box-left:after {
	border-right-color: #F7FCFF;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-mensagem-box-right:before {
	border-left-color: #0277BD;
}
.cpnt-chatmsg-tipo-azul .cpnt-chatmsg-mensagem-box-right:after {
	border-left-color: #F7FCFF;
}

/* Custom - Default */
.cpnt-chatmsg-tipo-default .cpnt-chatmsg-custom-titulo {
	border-color: #C9C9C9;
	background-color: #FDFDFD;
}

.cpnt-chatmsg-custom-titulo.cpnt-chatmsg-custom-titulo-black {
	background-color: #2E2F31;
}

/* Custom - Ícone */
.cpnt-chatmsg-mensagem-infoicone {
	display: flex;
}
.cpnt-chatmsg-mensagem-infoicone-info {
	padding-left: 10px;
}

/*
** Tamanhos
** ================================================== */
.cpnt-chatmsg,
.cpnt-chatmsg-tipo-proposta {
	max-width: 80%;
}

@media (max-width: 680px) {
	.cpnt-chatmsg-custom-titulo {
		font-size: 14px;
	}
	.cpnt-chatmsg-mensagem {
		padding: 10px;
	}
	.cpnt-chatmsg-user {
		min-width: 50px;
		max-width: 50px;
	}
	.cpnt-chatmsg,
	.cpnt-chatmsg-tipo-proposta {
		max-width: 100%;
	}
}