.linha-barra-wrap {
	display: flex;
	align-items: center;
}

/*
** Barra 2
** ================================================== */
.barra-wrap {
	flex: 1;
}
.linha-barra-titulo {
	width: 90px;
	font-weight: bold;
	font-size: 15px;
}
.linha-barra-porcentagem {
	width: 45px;
	text-align: right;
	font-weight: bold;
	font-size: 15px;
	margin-left: 10px;
}

/*
** Barra 3
** ================================================== */
.barra-box {
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	border: 1px solid #8E93A4;
}
.barra {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 0;
	transition: all 0.3s ease 0s;
	background-color: #FDA63F;
}
.linha-barra-wrap + .linha-barra-wrap {
	margin-top: 7px;
}