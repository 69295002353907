.cpnt-porcentagemcirculo-inner {
	display: flex;
	flex-flow: row nowrap;
}

.cpnt-porcentagemcirculo-chart-box {
	width: 100%;
	justify-content: space-around ;
}

.cpnt-porcentagemcirculo-chart {
	display: block;
}

.cpnt-porcentagemcirculo-bg {
	fill: none;
	stroke: #DFDFDF;
	stroke-width: 3;
}

.cpnt-porcentagemcirculo-circulo {
	fill: none;
	stroke-width: 3;
	stroke-linecap: round;
	animation: porcentagemCirculoProgresso 1s ease-out forwards;
}

table .cpnt-porcentagemcirculo-chart {
	width: 50px;
}

@keyframes porcentagemCirculoProgresso {
	0% {
		stroke-dasharray: 0 100;
	}
}

.cpnt-porcentagemcirculo-porcentagem {
	fill: #2E2F31;
	text-shadow: 1px 1px #FFF;
	font-family: sans-serif;
	font-size: 0.7em;
	text-anchor: middle;
	font-weight: normal;
}

.cpnt-porcentagemcirculo-alt .cpnt-porcentagemcirculo-porcentagem {
	font-size: 0.5em;
}
