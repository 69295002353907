@import '@scss/imports.scss';

.container {
	background-color: var(--color-white);
	height: 100%;
	box-sizing: border-box;
	position: relative;

	.header {
		padding: var(--gap-item);
		gap: var(--gap-item);
		display: flex;
		flex-direction: column;
	}

	.titulo {
		font-size: var(--fs-titulo);
	}

	.acoes-box {
		display: flex;
		align-items: flex-end;
		gap: var(--gap);
	}

	.wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.conteudo {
		flex: 1 1;
		height: 100%;
		max-height: 100%;
		min-height: 0;
		overflow-y: auto;
		box-sizing: border-box;
		background-color: var(--color-bg);
		border-top: 1px solid var(--color-border);
		padding: var(--gap);
		display: flex;
		flex-direction: column;
		gap: var(--gap);
	}

	.nao-lida {
		border-color: var(--color-1);
	}
}
