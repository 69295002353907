@import '@scss/imports.scss';

// https://react-select.com/styles#inner-components

.container {

	.select-wrapper {
		position: relative;
	}

	.inner {
		@extend %input-box;
		display: inline-flex;
		flex-direction: column;
		gap: var(--gap-min-alt);
	}

	.label {
		font-size: var(--fs-label);
	}

	.req {
		@extend %req;
	}

	input {
		outline: none !important;
	}

	.select-control {
		border: 2px solid var(--color-border);
		border-radius: var(--border-radius);
		box-shadow: none;
		min-height: 44px;

		&:hover,
		&.focused {
			border-color: var(--color-1);
		}
	}

	// Image
	.selected-box {
		display: inline-flex;
		align-items: center;
		gap: var(--gap-item-alt);

		.selected-img {
			position: relative;
			width: 30px;
			height: 30px;
			top: 2px;
			background-size: cover;
			background-position: center center;
			border-radius: var(--border-radius-50);
		}

	}

	[class$="-menu"] {
		z-index: 998 !important;
	}
}
