.numero {
	width: 20px;
	height: 20px;
	padding: 10px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	text-align: center;
	border: 2px solid var(--color-border);
	background-color: #FFF;
	font-weight: bold;
	transition: var(--transition);

	&.click {
		cursor: pointer;
	}

	&:hover,
	&.active {
		border-color: var(--color-1);
	}
}
