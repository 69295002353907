.container {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	gap: var(--gap);
	font-weight: bold;
	font-family: inherit;
	padding: 13px var(--gap);
	background-color: var(--color-black);
	color: var(--color-1);
	font-size: var(--fs-default-alt);
	border-radius: var(--border-radius-min);
	border: 0;
	text-decoration: none;
	cursor: pointer;
	white-space: nowrap;

	&.alt {
		border: 1px solid var(--color-1);
		background-color: var(--color-btn-default);
	}

	/*
	** Tamanhos
	** ================================================== */
	&.m {
		font-size: var(--fs-min);
		padding: 11px var(--gap);
	}

	/*
	** Cores
	** ================================================== */
	&.error {
		// color: var(--color-white);
		background-color: var(--color-red);
	}
}