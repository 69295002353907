.cpnt-inputfake-input {
	position: relative;
	border: 1px solid #2E2F31;
	border-radius: 4px;
	padding: 12px 10px;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
	font-size: 14px;
	background-color: #FFF;
}
.cpnt-inputfake-inativo {
	background: linear-gradient(#FFF, #EAEAEA);
}
.cpnt-inputfake-label {
	position: absolute;
	transform: translateY(-50%);
	padding: 5px;
	z-index: 1;
	font-size: 12px;
	top: 0;
	left: 6px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;
	font-weight: bold;
}

@media (max-width: 1680px) {
	.cpnt-inputfake-input {
		padding: 9px 10px;
	}
}