.cpnt-portal-preview-foto {
	width: 800px;
	max-width: 100%;
}
.cpnt-portal-foto {
	position: relative;
}
.cpnt-porta-foto-expand-ativo img {
	max-height: 95vh;
}
.cpnt-porta-foto-expand {
	cursor: pointer;
	position: absolute;
	right: -7px;
	top: 26px;
	background-color: #3C8FE7;
	border-radius: 50%;
	z-index: 9;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding-top: 1px;
}

.cpnt-porta-foto-expand-ativo-0 img {
	max-height: 95vh;
}
.cpnt-porta-foto-expand-ativo-1 img {
	height: 95vh;
}
.cpnt-porta-foto-expand-ativo-0 img {
}