.cpnt-listacheckbox2-lista {
	max-width: 100%;
	max-height: 450px;
	overflow-y: auto;
}
.cpnt-listacheckbox2-lista-item {
	padding: 7px;
	position: relative;
}
.cpnt-listacheckbox2-lista-item:nth-child(odd) {
	background-color: #FFF;
}
.cpnt-listacheckbox2-lista-item:hover {
	background-color: #e4ebee;
}

.cpnt-listacheckbox2-lista-item label {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.cpnt-listacheckbox2-box .checkbox-box .checkbox-check,
.cpnt-listacheckbox2-box .cpnt-checkbox2-check {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}
.cpnt-listacheckbox2-box .checkbox-box {
	padding-right: 30px;
}
