.vid-wrapper {
	background-color: #000;
}
.vid-inner {
	position: relative;
	padding-top: 56.25%;
}
.vid-player {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100%;
	max-height: 100%;
	min-width: 100%;
	min-height: 100%;
	box-sizing: border-box;

	video {
		position: absolute;
	}
}