@keyframes linhasMenuFadeOutIn {
	0% {
		background-color: var(--color-2);
		left: 0;
	}
	48% {
		left: 100%;
	}
	49% {
		opacity: 0;
		visibility: hidden;
	}
	50% {
		background-color: var(--color-1);
		left: -100%;
	}
	51% {
		opacity: 1;
		visibility: visible;
	}
	52% {
		left: -100%;
	}
	100% {
		background-color: var(--color-2);
		left: 0;
	}
}

/*
** Botão
** ================================================== */
.btn {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	overflow: hidden;
	gap: 6px;

	.linha {
		position: relative;
		width: 37px;
		height: 3px;
		background-color: var(--color-1);
		display: block;
	}

	.linha-3 {
		width: 70%;
	}

	&:hover {
		.linha {
			animation-timing-function: ease-out;
			animation-duration: 0.4s;
			animation-name: linhasMenuFadeOutIn;
		}
		.linha-1 {
			animation-delay: 0s;
		}
		.linha-2 {
			animation-delay: 0.1s;
		}
		.linha-3 {
			animation-delay: 0.2s;
		}
	}
}
