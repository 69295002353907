.container {
	display: inline-flex;
	align-items: center;
	gap: var(--gap-item-alt);
	text-decoration: none;
	cursor: pointer;

	.label {
		font-weight: bold;
	}

	[type="radio"] {
		display: none;
	}

	.radio {
		position: relative;
		display: inline-flex;
		width: 1.2em;
		height: 1.2em;
		vertical-align: middle;
		align-items: center;
		justify-content: center;
		transition: var(--transition);

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			border-radius: 50%;
			box-sizing: border-box;
			transition: var(--transition);

			border: 2px solid var(--color-border);
			background-color: var(--color-white);
		}

		&:after {
			content: '';
			width: 0.8em;
			height: 0.8em;
			position: absolute;
			left: 0.2em;
			top: 0.2em;
			border-radius: 50%;
			transition: var(--transition);
			background-color: var(--color-white);
		}
	}

	&:hover {
		.radio {
			&::before {
				border-color: var(--color-1);
			}
		}
	}

	[type="radio"]:checked ~ .radio {
		&:after {
			background-color: var(--color-1);
		}
	}

}
