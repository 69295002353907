.cpnt-webcam-btns {
	display: flex;
	justify-content: center;
}
.cpnt-webcam-camera-box {
	width: 500px;
	height: 375px;
	margin: auto;
	box-shadow: 0 0 0 2px #D5D5D5 inset;
	background-color: #FAFAFA;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 70px auto;
}
