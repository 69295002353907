/*
** Box Step 1
** ================================================== */
.cpnt-complprocselform-step1-box {
	display: flex;
}
.cpnt-complprocselform-step1-template {
	padding: 0 10px 10px;
}
.cpnt-complprocselform-step1-box2 {
	min-width: 350px;
	max-width: 350px;
}
.cpnt-complprocselform-bar {
	margin-top: -1px;
	margin-bottom: -1px;
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	background-color: #FFF;
	display: flex;
}

/*
** Histórico
** ================================================== */
.cpnt-complprocselform-step1-historico {
	flex: 1 1;
	max-height: 510px;
	overflow-y: auto;
	border: 1px solid #2E2F31;
	border-radius: 5px;
	background-color: #FFF;
}

@media (max-width: 980px) {
	.cpnt-complprocselform-step1-box {
		flex-direction: column;
	}
	.cpnt-complprocselform-step1-box2 {
		min-width: auto;
		max-width: 100%;
		width: 100%;
	}
	.cpnt-complprocselform-step1-historico {
		max-height: none;
	}
}