.portal {
	width: 700px;

	.box {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 30px;
	}
	.dados {
		text-align: center;
	}
	.total {
		font-weight: bold;
		font-size: 36px;
	}
	.nao-avaliado {
		font-size: 20px;
	}
	.descricao {
		grid-column: 1 / -1;
		padding: 20px;
		border-radius: 5px;
		background-color: #FFF;
		box-shadow: 0 1px 1px rgba(0,0,0,0.1);
		white-space: pre-line;
	}

	@media (max-width: 680px) {
		.box {
			grid-template-columns: 1fr;
		}
		.dados {
			order: -1;
		}
	}
}