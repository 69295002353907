.toggle-container {
	display: inline-flex;
}
.toggler-box {
	display: block;
	cursor: pointer;
	position: relative;
	width: 140px;
	height: 34px;
}
.box {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #ffe4e4;
	border: 1px solid #E74C3C;
	border-radius: 50px;
	transition: all 0.3s ease 0s;
}
.label-ativo {
	opacity: 0;
	visibility: hidden;
	padding-right: 40px;
	padding-left: 30px;
}
.label-inativo {
	padding-left: 40px;
	padding-right: 20px;
}

/*
** Check
** ================================================== */
.label {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.input {
	display: none;
}
.check {
	position: absolute;
	left: 4px;
	transform: translateX(0) translateY(-50%);
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-color: #E74C3C;
	transition: all 0.3s ease 0s;
	z-index: 1;
	top: 50%;
}


.input:checked ~ .label-ativo {
	opacity: 1;
	visibility: visible;
}
.input:checked ~ .label-inativo {
	opacity: 0;
	visibility: hidden;
}

/*
** Checked
** ================================================== */
.input:checked ~ .check {
	left: calc(100% - 30px);
}
.input:checked ~ .check {
	background-color: #229d3e;
}
.input:checked ~ .box {
	border-color: #229d3e;
	background-color: #e4ffea;
}

