.pg-listapredefinida-titulo-box {
	background-color: #FFF;
	position: sticky;
	top: 0;
	margin: -15px -15px 10px;
	padding: 15px 15px 17px;
	z-index: 1000;
	border-bottom: 1px solid #DDD;
}
.pg-profissoes-topbar-acoes {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.tbl-profpred-col-area-atuacao {
	width: 150px;
}
.tbl-profpred-col-idiomas {
	width: 150px;
}
.tbl-profpred-col-proprietario {
	width: 100px;
}