.cpnt-video-player-box {
	position: relative;
	border-radius: 3px;
	border: 2px solid #D5D5D5;
	box-sizing: border-box;
	margin: 30px 0;
}
.cpnt-video-player {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	box-sizing: border-box;
	background-color: #2E2F31;
	height: 100%;
}
.cpnt-video-videobox {
	max-width: 600px;
	margin: 20px auto;
}
.cpnt-video-btns {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

@media (max-width: 480px) {
	.cpnt-video-btns {
		flex-direction: column;
		align-items: center;
	}
}