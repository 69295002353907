.cpnt-foto2-foto-box {
	position: relative;
	z-index: 10;
}

.cpnt-foto2-foto-preview-box {
	width: 200px;
}
.cpnt-foto2-img,
.cpnt-foto2-foto-preview {
	position: relative;
}
