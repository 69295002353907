.cpnt-templates-preview {
	width: 950px;
}
.cpnt-template-container {
	max-width: 100%;
	max-height: 450px;
	overflow-y: auto;
}
.cpnt-template-box:nth-child(odd) {
	background-color: #FFF;
}
.cpnt-template-box:hover {
	background-color: #e4ebee;
}
.cpnt-template-box-wrap {
}
.cpnt-template-preview {
	max-width: 100px;
	text-align: center;
	font-size: 14px;
}
.cpnt-template-preview img {
	max-width: 50px;
	margin: auto;
}
.cpnt-template-sublabel {
	font-size: 14px;
	margin-top: 15px;
	color: #999;
}
.cpnt-template-texto {
	position: relative;
	padding: 15px 50px 15px 15px;
}
.cpnt-template-preview {
	max-width: 100px;
	text-align: center;
	font-size: 14px;
	padding: 15px;
}
.cpnt-template-container label {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
.cpnt-template-container .cpnt-radio2 .cpnt-radio2-check {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
}
.cpnt-template-filtro {
	padding-bottom: 20px;
}
.cpnt-template-filtros-box {
	display: inline-flex;
}

@media (max-width: 680px) {
	.cpnt-template-btnpreview {
		display: none;
	}
	.cpnt-template-preview img {
		max-width: 25px;
	}
	.cpnt-template-preview {
		padding: 10px;
	}
	.cpnt-template-texto {
		padding: 10px 30px 10px 0px;
	}
	.cpnt-template-container .cpnt-radio2 .cpnt-radio2-check {
		right: 10px;
	}
}