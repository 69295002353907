.texto-info {
	text-align: center;
	padding: 30px;
}
.box-geral {
	gap: 20px;
	display: flex;
}
.box-info {
	display: flex;
	flex-direction: column;
	padding: 15px;
	flex: 1;
}
.box {
	display: flex;
	flex: 1;
}
.box-inner {
	display: flex;
	flex-direction: column;
	border: 1px solid #DFDFDF;
	border-radius: 30px 30px 5px 5px;
	background-color: #FFF;
	width: 100%;
	box-sizing: border-box;
	max-width: 270px;
  	margin: auto;
}
.box-inner-destaque {
	background-color: #f6fcff;
	border-color: #0277BD;
}
.box-img {
	aspect-ratio: 800 / 1000;
	border-radius: 30px;
	width: calc(100% + 2px);
	box-sizing: border-box;
	margin-left: -1px;
	margin-top: -2px;
}
.titulo {
	margin: 0;
	padding: 0;
	font-size: 16px;
	text-align: center;
}
.desc {
	text-align: center;
	font-size: 14px;
	margin: 10px 0;
}
.box-btn {
	padding-top: 50px;
	margin-top: auto;
	row-gap: 15px;
	display: flex;
	flex-direction: column;
}
.btn {
	text-decoration: none;
	font-size: 14px;
	border: 1px solid #DFDFDF;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #FAFAFA;
	transition: all 0.2s ease 0s;
}
.box-inner-destaque .btn {
	border-color: #000;
	background-color: #000;
	color: #FFF;

}
.footer {
	font-size: 14px;
}
.btn:hover {
	background-color: #0277BD;
	color: #FFF;
	border-color: #0277BD;
}
.box-inner-destaque .btn:hover {
	border-color: #0277BD;
	background-color: #0277BD;
	color: #FFF;
}

@media (max-width: 980px) {
	.box {
		flex-basis: calc(50% - 20px);
	}
	.box-img {
		aspect-ratio: 800 / 600;
	}
}

@media (max-width: 480px) {
	.box {
		flex-basis: 100%;
	}
}
