.container {
	padding: 30px;
}

.portal-sucesso {
	max-width: 1100px;
	background-color: #FDFDFD;
	padding: 50px;
}
.textos {
	font-size: 18px;
}
.aviso-container {
	display: flex;
	flex-wrap: wrap;
}
.aviso-box {
	min-width: 50%;
	max-width: 50%;
}
.aviso-titulo {
	font-size: 28px;
	line-height: 1.1;
}
.aviso-e {
	align-self: center;
	font-size: 22px;
	color: #000;
	padding: 0 0 80px;
}
.aviso-d {
	position: relative;
	align-self: center;
}
.aviso-img {
	display: block;
	max-width: none;
	max-height: none;
	width: 68vw;
}
.img-sucesso {
	min-width: 80px;
	max-width: 80px;
}

/*
** Overlay
** ================================================== */
.aviso-btn-box {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 980px) {
	.aviso-container {
		flex-direction: column;
	}
	.aviso-box {
		min-width: auto;
		max-width: none;
	}
	.aviso-e {
		padding-bottom: 20px;
	}
	.aviso-d {
		max-width: 100%;
		width: 500px;
	}
	.aviso-btn-box {
		margin-top: 20px;
	}
	body .box-texto-img-sucesso {
		margin-top: 0;
	}
}

@media (max-width: 680px) {
	.container {
		padding: 15px;
	}
}

@media (max-width: 580px) {
	.portal-sucesso {
		padding: 20px;
	}
	.aviso-titulo {
		font-size: 22px;
	}
}
