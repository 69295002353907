.cpnt-foto-img {
	position: relative;
	border: 2px solid #FFF;
	width: 50px;
	height: 50px;
	overflow: hidden;
	border-radius: 50%;
}
.cpnt-foto-img-alt {
	border-radius: 5px;
}
