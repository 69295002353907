.bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	background-color: var(--color-black-alpha-3);
}
.container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	height: 100%;
	width: 400px;
	max-width: 90vw;

	.wrapper {
		height: 100%;

		.inner {
			height: 100%;

			.content {
				max-height: 100%;
				min-height: 0;
				height: 100%;

				.children {
					max-height: 100%;
					min-height: 0;
					height: 100%;
				}
			}
		}
	}

	.x {
		cursor: pointer;
		position: absolute;
		right: 10px;
		top: 10px;
		width: 15px;
		height: 15px;
		z-index: 10;
		padding: 5px;

		&:before {
			content: '';
			mask: url('./x-cinza.png') no-repeat center center;
			mask-size: contain;
			background-color: var(--color-1);
			position: absolute;
			left: 2px;
			top: 2px;
			right: 2px;
			bottom: 2px;
		}
	}
}
