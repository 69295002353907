.cpnt-linhaespacotexto-inner {
	display: flex;
}
.cpnt-linhaespacotexto-linhasep {
	flex: 1 1;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 0 5px 3px;
	align-self: flex-end;
	min-width: 20px;
}
.cpnt-linhaespacotexto-item {
	display: flex;
	align-items: center;
}
.cpnt-linhaespacotexto-item + .cpnt-linhaespacotexto-item {
	margin-top: 5px;
}
.cpnt-linhaespacotexto-item-desc {
	margin: 0 10px;
	font-weight: bold;
}

/*
** Alt
** ================================================== */
.cpnt-linhaespacotexto-alt .cpnt-linhaespacotexto-linhasep {
	border-bottom: 0;
}
.cpnt-linhaespacotexto-alt .cpnt-linhaespacotexto-desc {
	font-weight: 600;
}