.portal-termos-uso {
	width: 1000px;
}
.portal-termos-titulo-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.portal-termos-titulo {
	margin: 0;
}
.portal-termos-btn {
	margin-left: 15px;
	margin-right: 15px;
}