.cpnt-btnadd-inner {
	cursor: pointer;
	border-radius: 3px;
	font-size: 15px;
	font-weight: bold;
	transition: all 0.3s ease 0s;
	display: inline-flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	padding: 0;
	color: #0277BD;
}
.cpnt-btnadd-inner-reverse {
	flex-direction: row;
}
.cpnt-btnadd-inner:hover {
	text-decoration: underline;
}
.cpnt-btnadd-icone-box {
	padding-left: 7px;
	position: relative;
	top: 1px;
}
.cpnt-btnadd-titulo {
	white-space: nowrap;
}
.cpnt-btnadd-inner-reverse .cpnt-btnadd-icone-box {
	padding-left: 0;
	padding-right: 7px;
}
.cpnt-btnadd-pos {
	margin-left: 15px;
	position: relative;
	bottom: -4px;
}