.cpnt-timelinevert {
	display: flex;
}

/* Label */
.cpnt-timelinevert-label {
	/* display: flex; */
}
.cpnt-timelinevert-label-wrap {
	overflow: hidden;
}
.cpnt-timelinevert-label-btn {
	position: relative;
	background-color: #333333;
	color: #FFF;
	padding: 5px 5px 5px 10px;
	margin-right: 15px;
	font-size: 14px;
	font-weight: bold;
	z-index: 3;
}
.cpnt-timelinevert-label-btn:after {
	content: '';
	position: absolute;
	right: -5px;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
	background-color: #333333;
	height: 40px;
	width: 40px;
	z-index: -1;
}

/* Linha */
.cpnt-timelinevert-linha {
	position: relative;
	padding: 0 30px;
}
.cpnt-timelinevert-linha:before {
	content: '';
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #333333;
	position: absolute;
	left: 50%;
	top: 5px;
	transform: translateX(-50%);
	z-index: 2;
}
.cpnt-timelinevert-linha:after {
	content: '';
	position: absolute;
	top: 15px;
	width: 4px;
	height: 135%;
	background-color: #999;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
.cpnt-timelinevert-ultimo .cpnt-timelinevert-linha:after {
	height: calc(100% - 17px);
}
.cpnt-timelinevert-primeiro-single .cpnt-timelinevert-linha:after {
	height: 90%;
}

/* Titulo */
.cpnt-timelinevert-titulo {
	font-size: 20px;
	margin: 0;
}

/* Subtitulo */
.cpnt-timelinevert-subtitulo {
	font-weight: bold;
	font-size: 14px;
}

/* Subtitulo 2 */
.cpnt-timelinevert-subtitulo2 {
	color: #717171;
	font-size: 14px;
}

/* Descrição */
.cpnt-timelinevert-descricao {
	white-space: pre-line;
	margin-top: 10px;
	font-size: 14px;
}

/* Descrição */
.cpnt-timelinevert-geral,
.cpnt-timelinevert-ultimo {
	padding-top: 25px;
}