%flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
%inline-flex {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
%flex-btw {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
%input-box {
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: var(--gap-min-alt);
	width: 100%;
}
