.cpnt-estrelas {
	position: relative;
	width: 100%;
	aspect-ratio: 110 / 19.5;
}
.cpnt-estrelas-icone {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	-webkit-mask-repeat: repeat-x;
	mask-repeat: repeat-x;
	-webkit-mask-size: auto 100%;
	mask-size: auto 100%;
	width: 100%;
	height: 100%;
}
.cpnt-estrelas-icone-cinza {
	-webkit-mask-image: url('./estrela-cinza.svg');
	mask-image: url('./estrela-cinza.svg');
	background-color: #CCC;
}
.cpnt-estrelas-icone-amarela {
	-webkit-mask-image: url('./estrela-amarela.svg');
	mask-image: url('./estrela-amarela.svg');
	background-color: #F7B815;
}
.cpnt-estrelas-wrap {
	background-color: #FFF;
	padding: 2px 5px;
	border-radius: 15px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
	border: 1px solid #999;
}
