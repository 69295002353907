.cpnt-tenor-masonry {
	margin: 15px -7px -5px;
}
.cpnt-tenor-masony-item-wrap {
	width: 25%;
}
.cpnt-tenor-masony-item {
	margin: 5px;
	border-radius: 4px;
	overflow: hidden;
	border: 2px solid transparent;
	box-shadow: 0 3px 15px transparent;
	transition: all 0.3s ease 0s;
}
.cpnt-tenor-masony-item:hover {
	border-color: #2E2F31;
	cursor: pointer;
	box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
	transform: scale(1.05);
}
.cpnt-tenor-btn-box {
	padding: 20px;
	display: flex;
	justify-content: flex-end;
}
.cpnt-tenor-portal-preview {
	min-width: 300px;
	max-width: 650px;
}
.cpnt-tenor-portal-img-preview {
	position: relative;
	min-height: 130px;
}
.cpnt-tenor-loading-preview {
	height: 50px;
	margin: 15px;
	position: absolute;
	top: 0;
	font-size: 15px;
	left: 0;
	right: 0;
	z-index: 1;
	margin: auto;
	bottom: 0;
}
.cpnt-tenor-loading-preview .cpnt-loading-img {
	width: 80px;
}
.cpnt-terno-imagem-loading {
	border: 2px solid red;
	box-sizing: border-box;
	min-height: 50px;
}

@media (max-width: 680px) {
	.cpnt-tenor-masony-item-wrap {
		width: 33.3333%;
	}
}

@media (max-width: 480px) {
	.cpnt-tenor-masony-item-wrap {
		width: 50%;
	}
}