.cpnt-inputcolor-input {
	position: relative;
	border: 1px solid #2E2F31;
	border-radius: 4px;
	padding: 9px 7px 8px;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
	font-size: 14px;
	background-color: #FFF;
	cursor: pointer;
}
.cpnt-inputcolor-inativo {
	background: #FFF;
}
.cpnt-inputcolor-label {
	position: absolute;
	transform: translateY(-50%);
	padding: 5px;
	z-index: 0;
	font-size: 12px;
	top: 0;
	left: 6px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;
	font-weight: bold;
}

.cpnt-inpucolor-cor {
	color: transparent;
	border-radius: 3px;
	box-sizing: border-box;
	width: 100%;
	padding: 3px;
	box-shadow: 0 0 1px 1px rgba(0,0,0,0.1);
}
@media (max-width: 1680px) {
	.cpnt-inputcolor-input {
		padding: 9px 10px;
	}
}