@import '@scss/imports.scss';

.container {
	@extend %input-box;

	label {
		font-size: var(--fs-label);
	}
}

// Restate no arquivo global.scss
