.box {
	padding: 50px 50px 100px;
	background-color: #FFF;
	border-radius: 5px;
	border: 1px solid #000;
	margin: 10px;
	display: flex;
	justify-content: center;
}
.box-img {
	max-width: 150px;
	margin: auto;
}
.box-inner {
	max-width: 900px;
}