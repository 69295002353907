.cpnt-projetosinfoqtd-mobile {
	display: none;
}

@media (max-width: 980px) {
	.cpnt-projetosinfoqtd-desk {
		display: none;
	}
	.cpnt-projetosinfoqtd-mobile {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		position: relative;
		top: 3px;
		margin-left: 10px;
	}
}
