/*
** DatePicker
** ================================================== */
.react-datepicker-popper {
	z-index: 99;
}
.react-datepicker-wrapper {
	display: block;
}
.react-datepicker__header__dropdown {
	padding: 10px 10px 7px;
}
.react-datepicker__header__dropdown select {
	border: 1px solid #2E2F31;
	border-radius: 3px;
	padding: 3px 15px 3px 5px;
	appearance: none;
	background: #FFF url('../../../../assets/imagens/icones/down-chevron.png') no-repeat center right 5px;
	background-size: auto 6px;
	margin: 0 5px;
}
.react-datepicker__day--weekend {
	color: #AAA;
}
.react-datepicker__day--today {
	background-color: #CCC;
	border-radius: 3px;
}
