.container {
	display: flex;
	flex-direction: column;
	gap: var(--gap-med);
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius-min);
	background-color: var(--color-white);
	color: var(--color-primary);
	text-decoration: none;
	font-size: var(--fs-default-alt);
	padding: var(--gap);
	transition: var(--transition);
	cursor: pointer;

	&:hover,
	&.nao-lida {
		border-color: var(--color-1);
		background-color: var(--color-2);
	}
}
.titulo {
	font-size: var(--fs-default);
	font-weight: bold;
}
.data {
	font-size: var(--fs-default-alt);
	text-align: right;
}
