.tela-negociarcard {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.tela-negociarcard-container {
	display: flex;
	flex: 1;
	max-height: 100%;
	min-height: 0;
	padding: 10px;
}
.tela-negociarcard-info-wrap {
	padding: 15px 20px;
	background-color: #FBFBFB;
	border-bottom: 1px solid #DDD;
}
.tela-negociarcard-info-box {
	min-width: 400px;
	max-width: 400px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.tela-negociarcard-chat-box {
	margin-left: 10px;
	display: flex;
	max-height: 100%;
	height: 100%;
	width: 100%;
}

/* Lado esquerdo */
.tela-negociarcard-sec + .tela-negociarcard-sec {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid #EEE;
}

/* Hunter */
.tela-negociarcard-info-hunter {

}

/* Equipe */
.tela-negociarcard-info-equipe {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Habilidades */
.tela-negociarcard-info-dados {
	font-size: 16px;
	max-height: 100%;
	overflow-y: auto;
	flex: 1;
	padding: 20px;
}

/*
** Título
** ================================================== */
.pg-negocjob-titulo-box {
	display: flex;
	align-items: center;
	min-width: 0;
	max-width: 100%;
}
.pg-negocjob-titulo-inner {
	min-width: 0;
	max-width: 100%;
}
.pg-negocjob-titulo-sep-box {
	display: flex;
	font-size: 14px;
}
.pg-negocjob-titulo-sep + .pg-negocjob-titulo-sep {
	margin-left: 10px;
	padding-left: 10px;
	border-left: 1px solid #CCC;
}
.pg-negocjob-titulo-status {
	display: inline-flex;
	align-items: center;
	font-size: 13px;
}

@media (max-width: 1580px) {
	.tela-negociarcard-info-box {
		min-width: 300px;
		max-width: 300px;
	}
}

@media (max-width: 980px) {
	.tela-negociarcard-info-box {
		display: none;
	}
	.tela-negociarcard-chat-box {
		margin-left: 0;
	}
}