.cpnt-itemfotodesc {
	display: inline-flex;
	border-radius: 30px;
	align-items: center;
	background-color: #fff;
	border: 1px solid #e4e7e8;
	position: relative;
	max-width: 100%;
	/* overflow: hidden; */
	text-overflow: ellipsis;
	box-sizing: border-box;
}
.cpnt-itemfotodesc-wrap {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
}
.cpnt-itemfotodesc-texto {
	display: flex;
	flex-direction: column;
	line-height: 1;
	font-size: 12px;
	white-space: nowrap;
	padding: 3px 13px 3px 7px;
}
.cpnt-itemfotodesc .x {
	right: -6px;
	top: -6px;
}
.cpnt-itemfotodesc-box {
	display: flex;
	align-items: center;
}

.cpnt-itemfotodesc-alt .cpnt-itemfotodesc-wrap {
	border-radius: 5px;
}
.cpnt-itemfotodesc-alt.cpnt-itemfotodesc {
	border-radius: 5px;
}

/* Min */
.cpnt-itemfotodesc-min .cpnt-itemfotodesc-wrap {
	width: 30px;
	height: 30px;
}
.cpnt-itemfotodesc-min .x {
	top: -4px;
	width: 15px;
	height: 15px;
}


/* Cor 1 */
.cpnt-itemfotodesc-coralt-1 {
	color: #FFF;
	background-color: #404040;
	border-color: #555;
}

/* Cor 2 */
.cpnt-itemfotodesc-coralt-2 {
	color: #FFF;
	background-color: #555;
	border-color: #6c6c6c;
}

/* Cor 3 */
.cpnt-itemfotodesc-coralt-3 {
	color: #2E2F31;
	background-color: #fff;
	border: 1px solid rgb(227, 227, 227);
	box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* Cor 4 */
.cpnt-itemfotodesc-coralt-4 {
	color: #fff;
	background-color: #2E2F31;
	border: 2px solid #5b5b5b;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

/* Cor 5 */
.cpnt-itemfotodesc-coralt-5 {
	color: #fff;
	background-color: #2f2f2f;
	border: 1px solid #717171;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.cpnt-itemfotodesc-coralt-5 .cpnt-itemfotodesc-texto-desc {
	color: #999;
}

/* Cor 6 */
.cpnt-itemfotodesc-coralt-6 {
	color: #fff;
	background-color: #404040;
	border: 0;
}
.cpnt-itemfotodesc-coralt-6 .cpnt-itemfotodesc-texto-desc {
	color: #999;
}
