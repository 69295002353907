.cpnt-devbox {
	position: relative;
	outline: 1px solid red;
	outline-offset: 5px;
	outline-style: dashed;
}
.cpnt-devbox-info-titulo {
	font-size: 12px;
	position: absolute;
	right: -5px;
	top: -5px;
	padding: 2px 10px 7px 12px;
	border-radius: 0 0 0 10px;
	background-color: #EEE;
	color: #F00;
	z-index: 10;
}
.cpnt-devbox-info-texto {
	width: 300px;
}
