.cpnt-chatpropfreela-templates-lista {
	display: flex;
	flex-wrap: wrap;
	margin: 5px -10px -10px;
}

/*
** Parte de baixo
** ================================================== */
.cpnt-chatpropfreela-dados-preview {
	display: flex;
}

/*
** Lado esquerdo
** ================================================== */
.cpnt-chatpropfreela-dados-mensagem-box {
	min-width: 350px;
	max-width: 350px;
}
.cpnt-chatpropfreela-dados-mensagem {
	margin-top: 10px;
}

/*
** Lado direito
** ================================================== */
.cpnt-chatpropfreela-preview-box {
	padding-left: 50px;
	flex: 1;
}
.cpnt-chatpropfreela-preview {
	margin-top: 15px;
}
.cpnt-chatpropfreela-preview .cpnt-chatmsg-mensagem-box {
	flex: 1;
}
.cpnt-chatpropfreela-info-footer {
	margin-top: 20px;
	padding-left: 85px;
}

@media (max-width: 980px) {
	.cpnt-chatpropfreela-dados-preview {
		flex-direction: column;
	}
	.cpnt-chatpropfreela-preview-box {
		padding-left: 0;
		padding-top: 20px;
	}
	.cpnt-chatmsg-tipo-proposta {
		max-width: 100%;
	}
	.cpnt-chatpropfreela-dados-mensagem-box {
		min-width: auto;
		max-width: 100%;
	}
}