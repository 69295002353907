.cpnt-alterarporcentagem {
	position: absolute;
	right: 100%;
	margin-right: 5px;
	top: 50%;
	transform: translateY(-50%);
	background: #FAFAFA;
	border-radius: 5px;
	width: 360px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	z-index: 11;
}

/* Atual */
.cpnt-alterarporcentagem-atual {
	padding: 5px;
}

/* Barra */
.cpnt-alterarporcentagem-box {
	display: flex;
	flex-direction: column;
	padding: 10px;
}
.cpnt-alterarporcentagem-box-progresso {
	min-height: 40px;
	position: relative;
	flex: 1;
	border: 1px solid #FFF;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) inset;
	border-radius: 10px;
	overflow: hidden;
	background-size: 10% 100%;
}
.cpnt-alterarporcentagem-progresso {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 0;
	overflow: hidden;
}

/* Porcentagem */
.cpnt-alterarporcentagem-btn-wrap {
	position: absolute;
	display: flex;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	align-items: center;
	justify-content: space-around;
	font-size: 10px;
	flex-direction: row-reverse;
}

/* Progresso */
.cpnt-alterarporcentagem-btn-porcento {
	transition: all 0.3s ease 0s;
	flex: 1;
	align-self: stretch;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid #DDD;
	text-shadow: 1px 1px #FFF;
}

.cpnt-alterarporcentagem-btn-porcento:hover {
	color: white;
	background-color: green;
	transform: scale(1.5);
	border-color: transparent;
	text-shadow: 1px 1px green;
	cursor: pointer;
}
.cpnt-alterarporcentagem-btn-porcento:hover ~ .cpnt-alterarporcentagem-btn-porcento {
	color: #FFF;
	background-color: rgb(0, 177, 0);
	text-shadow: 1px 1px green;
}

/* Posição */
.cpnt-alterarporcentagem:before,
.cpnt-alterarporcentagem:after {
	content: '';
	border: 10px solid transparent;
}

/* Esquerda seta lado direito */
.cpnt-alterarporcentagem-left:after {
	border-left-color: #FAFAFA;
}
.cpnt-alterarporcentagem-left:before {
	border-left-color: #D8D8D8;
	margin-right: -1px;
}

.cpnt-alterarporcentagem-left:after,
.cpnt-alterarporcentagem-left:before {
	position: absolute;
	top: 50%;
	right: -30px;
	margin-top: -10px;
	transform: translateX(-50%);
}
