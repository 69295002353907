.cpnt-placeholder {
	display: flex;
	align-items: center;
	padding: 2px;
}
.cpnt-placeholder-animation {
	position: relative;
	overflow: hidden;
	background-color: #EBEBEB;
	margin: auto;
}
.cpnt-placeholder-animation:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, transparent, #F5F5F5, transparent);

	animation: animationLoading 1.3s linear 0s;
	animation-iteration-count: infinite;
}

.cpnt-placeholder-linha {
	border-radius: 5px;
}
.cpnt-placeholder-linha-min {
	border-radius: 5px;
}
.cpnt-placeholder-min {
	border-radius: 5px;
}
.cpnt-placeholder-circulo {
	border-radius: 50%;
}
.cpnt-placeholder-quadrado {
	border-radius: 5px;
}
.cpnt-placeholder-retangulo {
	border-radius: 5px;
}
.cpnt-placeholder-retangulo-alt {
	border-radius: 5px;
}
.cpnt-placeholder-custom {
	border-radius: 5px;
}

@keyframes animationLoading {
	0% {
		left: -50%;
	}
	25% {
		left: 50%;
	}
	50% {
		left: 100%;
	}
	51% {
		left: -100%;
	}
	100% {
		left: -50%;
	}
}

@media (max-width: 980px) {
	.cpnt-placeholder {
		margin: auto;
	}
}
