
/*
** Popup de detalhes do projeto
** ================================================== */
.cpnt-detalhesprojeto-info {
	display: flex;
}
.cpnt-detalhesprojeto-info-wrap {
	display: flex;
	flex: 1;
}
.cpnt-detalhesprojeto-info-1 {
	flex: 1;
}
.cpnt-detalhesprojeto-info-2 {
	flex: 1;
	padding-left: 30px;
}
.cpnt-detalhesprojeto-porcentagem-wrap {
	padding-left: 30px;
}
.cpnt-detalhesprojeto-porcentagem {
	min-width: 130px;
	max-width: 130px;
	margin: auto;
}

/* Boxes de fotos */
.cpnt-detalhesprojeto-fotos {
	display: flex;
	width: 100%;
	align-items: flex-start;
	margin-top: 30px;
}
.cpnt-detalhesprojeto-fotos-equipe {
	flex: 1;
}
.cpnt-detalhesprojeto-fotos-freelancers {
	padding-left: 50px;
	flex: 1;
}
.cpnt-detalhesprojeto-fotos-solucoes {
	padding-left: 50px;
	flex: 1;
}

.cpnt-detalhesprojeto-deschtml {
	padding: 15px;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	background-color: #FDFDFD;
	font-size: 16px;
	/* max-height: 600px; */
	/* overflow-y: auto; */
}

@media (max-width: 880px) {
	.cpnt-detalhesprojeto-info {
		flex-direction: column;
	}
	.cpnt-detalhesprojeto-porcentagem-wrap {
		padding-left: 0;
		padding-top: 30px;
	}
}

@media (max-width: 680px) {
	.cpnt-detalhesprojeto-info-wrap {
		flex-direction: column;
	}
	.cpnt-detalhesprojeto-info-2 {
		padding-left: 0;
		padding-top: 20px;
	}
	.cpnt-detalhesprojeto-fotos {
		flex-direction: column;
		align-items: center;
	}

	.cpnt-detalhesprojeto-fotos-freelancers,
	.cpnt-detalhesprojeto-fotos-solucoes,
	.cpnt-detalhesprojeto-fotos-solucoes {
		padding-left: 0;
		padding-top: 15px;
		text-align: center;
	}
}