.inputtext-box {
	position: relative;
}
.inputtext-box input,
.inputtext-box textarea {
	border: 1px solid #2E2F31;
	border-radius: 4px;
	background-color: #FFF;
	padding: 11px 10px;
	width: 100%;
	box-sizing: border-box;
	font-family: inherit;
	/* filter: grayscale(100%) brightness(110%); */
	outline: none;
}
.inputtext-box label,
.inputtext-box .label {
	position: absolute;
	pointer-events: none;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	font-weight: bold;
	transition: all 0.3s ease 0s;
	background-color: transparent;
	padding: 5px;
	z-index: 1;
}
.inputtext-box input:focus ~ label,
.inputtext-box input:not([value=""]) ~ label,
.inputtext-box-ativo label,

.inputtext-box textarea:focus ~ label,
.inputtext-box textarea:not([value=""]) ~ label,
.inputtext-box-ativo label,
.inputtext-box .label.ativo {
	font-size: 12px;
	top: 0;
	left: 6px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;;
}

.inputtext-box-invalido.inputtext-box input,
.inputtext-box-invalido.inputtext-box textarea {
	border-color: #cf2c2c;
	color: #cf2c2c;
	filter: none;
}
.inputtext-box-invalido.inputtext-box label,
.inputtext-box-invalido.inputtext-box textarea {
	color: #cf2c2c;
}

.inputtext-dropdown {
	pointer-events: none;
	position: absolute;
	right: 15px;
	width: 16px;
	height: 9px;
	background: url('../../assets/imagens/icones/down-chevron.png') no-repeat center center;
	background-size: contain;
	top: 50%;
	transform: translateY(-50%);
}
.inputtext-box-dropdown input {
	padding-right: 40px;
}

@media (max-width: 1680px) {
	.inputtext-box input,
	.inputtext-box textarea {
		padding: 8px 8px;
	}
}