/*
** Box
** ================================================== */
.box-default {
	background-color: #FFF;
	border-radius: 5px;
	box-shadow: 0 3px 7px rgba(0,0,0,0.05);
	border: 1px solid #DDD;
}
.box-default-alt {
	background-color: #FFF;
	border-radius: 10px;
	box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}
.box-default-2 {
	background-color: #F9F9FB;
	border: 1px solid #DFDFDF;
	border-radius: 3px;
}
.btn-link {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	color: #0277BD;
}
.box-default-3 {
	padding: 15px;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	background-color: #FDFDFD;
	font-size: 16px;
}
.box-alt {
	background-color: #FFF;
	border-radius: 3px;
	border: 1px solid #D8D8D8;
	padding: 15px;
}
.box-alt-titulo {
	padding: 10px 15px;
	border-bottom: 1px solid #D7D7D7;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -15px;
	margin-bottom: 10px;
	background-color: #F7F9FA;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.box-error {
	border-color: #FD8484;
	background-color: #FFF4F4;
}

/*
** Circulo info
** ================================================== */
.circle,
.q,
.q-alt,
.i {
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	padding: 2px;
	background-color: #2E2F31;
	color: #FFF;
	font-weight: bold;
	width: 12px;
	height: 12px;
	text-shadow: none;
}
.circle-alt {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	width: 20px;
	height: 20px;
	padding: 2px;
	border-radius: 50%;
	background-color: #2596e1;
	color: #FFF;
	align-self: flex-end;
	min-width: 0;
	max-width: 20px;
}
.circle-alt-abs {
	position: absolute;
	right: -15px;
	top: -11px;
}
.circle-alt-pos {
	margin-left: -9px;
	margin-bottom: 24px;
	position: relative;
	z-index: 1;
}

.q,
.i {
	min-width: 16px;
	max-width: 16px;
	min-height: 16px;
	max-height: 16px;
	box-sizing: border-box;
	margin-left: 5px;
}
.q-alt,
.i {
	min-width: 20px;
	max-width: 20px;
	min-height: 20px;
	max-height: 20px;
	font-size: 14px;
	box-sizing: border-box;
}
.q:before,
.q-alt:before {
	content: '?';
}
.i:before {
	content: 'i';
}
.q-white {
	color: #2E2F31;
	background-color: #FFF;
}

/*
** Imagem de usuário
** ================================================== */
.lista-userpic {
	width: 40px;
	height: 40px;
	display: inline-flex;
	border-radius: 50%;
	border: 2px solid #DFDFDF;
	background-color: #FFF;
}

.foto-userpic {
	position: relative;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	border: 2px solid #DFDFDF;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin: auto;
}
.foto-userpic-m {
	width: 150px;
	height: 150px;
}
.foto-userpic-s {
	width: 100px;
	height: 100px;
}
.foto-wrap-alt {
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid #FFF;
	background-color: #FFF;
}
.foto-wrap {
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid #FFF;
	background-color: rgba(185, 192, 196, 0.2);
}
.foto-wrap-2 {
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid #DFDFDF;
	background-color: rgba(185, 192, 196, 0.2);
}
.foto-wrap-square {
	border-radius: 3px;
	overflow: hidden;
	border: 2px solid #FFF;
	background-color: #FFF;
}

/*
** Quadros
** ================================================== */
.quadro-1 {
	width: 170px;
	height: 170px;
	border: 1px solid #2E2F31;
	border-radius: 5px;
	background-color: #FFF;
}

/*
** Hover exibir itens
** ================================================== */
.box-hover-itens {
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease 0s;
}
.box-hover-show-itens:hover .box-hover-itens {
	opacity: 1;
	visibility: visible;
}

/*
** Botão
** ================================================== */
.btn-min,
.btn-min-alt {
	border: 1px solid #DFDFDF;
	padding: 6px 10px;
	border-radius: 3px;
	font-weight: normal;
	background-color: #FAFAFA;
	font-size: 13px;
	color: #777;
	text-align: center;
	display: inline-flex;
	transition: all 0.3s ease 0s;
}
.btn-min {
	cursor: pointer;
}
.btn-min:hover {
	color: #0277BD;
}

.btn-min-hover:hover {
	color: #0277BD;
	border-color: #0277BD;
	background-color: #fbffff;
}

.btn-min-2 {
	display: inline-flex;
	align-items: center;
	text-align: center;
	cursor: pointer;
	font-size: 14px;
	padding: 5px 10px;
	background-color: #FFF;
	border: 1px solid #D7D7D7;
	border-radius: 3px;
	transition: all 0.3s ease 0s;
}
.btn-min-2:hover {
	color: #0277BD;
	border-color: #0277BD;
}

/*
** Separador
** ================================================== */
.sep-t {
	border-top: 1px solid #E9E9E9;
}
.sep-b {
	border-bottom: 1px solid #E9E9E9;
}
.sep-l {
	border-left: 1px solid #E9E9E9;
}
.sep-linha {
	padding-top: 5px;
	margin-top: 5px;
	border-top: 1px solid #EEE;
}
.sep-linha-alt {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid #EEE;
}
.sep-texto:before {
	content: '|';
	color: inherit;
	padding-left: 3px;
	padding-right: 3px;
}


/*
** Info box
** ================================================== */
.info-box:not(.sep) + .info-box {
	margin-top: 30px;
}
.info-box.sep + .info-box.sep {
	margin-top: 15px;
	border-top: 1px solid #DFDFDF;
	padding-top: 15px;
}

/*
** Mensagens
** ================================================== */
.msg {
	border: 1px solid #DFDFDF;
	padding: 10px;
	border-radius: 3px;
	font-weight: normal;
	background-color: #FFF;
	font-size: 14px;
	color: #777;
	text-align: center;
	box-sizing: border-box;
	width: 100%;
}
.texto {
	border: 1px solid #D8D8D8;
	padding: 15px;
	border-radius: 3px;
	background-color: #FFF;
	font-size: 15px;
	box-sizing: border-box;
	width: 100%;
}
.texto > *:first-child {
	margin-top: 0;
	padding-top: 0;
}
.texto > *:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

/* alt */
.msg-alt {
	padding: 10px;
	border-radius: 3px;
	font-size: 14px;
	border: 1px solid;
}
.msg-alt-sucesso {
	color: #FFF;
	background-color: #229d3e;
}
.msg-alt-info {
	color: #2596e1;
	background-color: #f3faff;
}

/* alt 2 */
.msg-alt2 {
	border-left: 4px solid;
	padding-left: 15px;
	padding: 10px 10px 10px 15px;
	border-radius: 5px;
}
.msg-alt2-info {
	border-left-color: #2596e1;
	background-color: #f0f9ff;
}
.msg-alt2-default {
	border-left-color: #d9d9d9;
	background-color: #f9f9f9;
}

.msg-alt3 {
	padding: 3px 5px;
	border-radius: 3px;
	border: 1px solid;
	font-size: 13px;
}
.msg-alt3-info {
	background-color: #f0f9ff;
	border-color: #C3E8FF;
}

/*
** Labels
** ================================================== */
.tag {
	font-size: 12px;
	padding: 3px 6px;
	border-radius: 2px;
	color: #fff;
	background-color: #333333;
	margin: 3px;
	box-shadow: 0 2px 2px rgba(0,0,0,0.1);
}
.tag-2 {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	border: 1px solid #6F6F6F;
	padding: 3px 7px;
	border-radius: 3px;
	font-weight: normal;
	background-color: #FAFAFA;
	font-size: 12px;
	color: #555;
	margin: 3px;
}
.tag-3 {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	border: 1px solid #282541;
	padding: 3px 15px;
	border-radius: 15px;
	font-weight: normal;
	font-size: 14px;
	color: #282541;
}
.tag-4 {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	padding: 4px 10px;
	border-radius: 4px;
	font-weight: normal;
	font-size: 14px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.item-hover {
	cursor: pointer;
	padding: 7px 10px;
	border-radius: 3px;
	background-color: transparent;
	transition: all 0.1s ease 0s;
}
.item-hover:hover {
	background-color: #e4ebee;
}

/*
** Subtitulo box
** ================================================== */
.subtitulo-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	background-color: #FFF;
}

.input-wrap {
	padding: 10px;
}

/*
** Circulo com info
** ================================================== */
.btn-circulo {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	margin-right: 5px;
}
.btn-circulo-wrap {
	min-width: 46px;
	max-width: 46px;
	max-height: 46px;
	min-height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #DFDFDF;
	border-radius: 50%;
}

/*
** Template
** ================================================== */
.preview-template {
	width: 900px;
}

/*
** Bottombar
** ================================================== */
.bottombar {
	z-index: 800;
	position: sticky;
	bottom: 0;
	background-color: #FFF;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	margin-bottom: -1px;
	padding: 10px;
	margin-top: -1px;
}

.over-conteudo {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.7);
}

.citacao {
	position: relative;
	font-style: italic;
}
.citacao:before,
.citacao:after {
	content: '';
	display: inline-flex;
	width: 14px;
	height: 14px;
}
.citacao:before {
	margin-right: 5px;
	background: url('../imagens/svg/icones/solid/quote-left.svg') no-repeat;
	background-size: 100% 100%;
}
.citacao:after {
	margin-left: 5px;
	background: url('../imagens/svg/icones/solid/quote-right.svg') no-repeat;
	background-size: 100% 100%;
}

/*
** Fix
** ================================================== */
.pos {
	margin-left: 15px;
	position: relative;
	bottom: -2px;
	font-size: 15px;
}

/*
** Listas
** ================================================== */
.lista-check {
	list-style: none;
	padding-left: 10px;
	line-height: 1.8;
}
.lista-check li:before {
	content: '';
	display: inline-flex;
	background: url('../imagens/svg/icones/padrao/check-green.svg') no-repeat center center;
	background-size: contain;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	margin-right: 4px;
}
.lista-check li + li {
	margin-top: 2px;
}

/* número */
.lista-numero {
	list-style: none;
	padding-left: 10px;
	counter-reset: countNumeros;
}
.lista-numero li {
	display: inline-flex;
	align-items: center;
	line-height: 1.3;
}
.lista-numero li:before {
	content: counter(countNumeros);
	counter-increment: countNumeros;
	display: inline-flex;
	min-width: 50px;
	max-width: 50px;
	min-height: 50px;
	max-height: 50px;
	vertical-align: middle;
	margin-right: 20px;
	background-color: #DFDFDF;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}
.lista-numero li + li {
	margin-top: 30px;
}

.lista-paragrafo {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
}
.lista-paragrafo li {
	text-indent: 30px;
}

/*
** Iframe
** ================================================== */
.video-box {
	width: 100%;
	padding-top: 56.25%;
	position: relative;
	box-sizing: border-box;
}
.video-box-inner {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}