.jodit-container:not(.jodit_inline) {
	border: 1px solid #000;
}
.jodit-toolbar__box:not(:empty) {
	border-bottom: 1px solid #000;
}
.jodit-ui-separator {
	border-right: 1px solid #000;
}
.jodit .jodit-workplace .jodit-wysiwyg,
.jodit .jodit-workplace .jodit-wysiwyg_iframe,
.jodit-container .jodit-workplace .jodit-wysiwyg,
.jodit-container .jodit-workplace .jodit-wysiwyg_iframe {
	background-color: #FFF;
	font-size: 16px;
}
.jodit-toolbar-collection_mode_vertical .jodit-toolbar-button__text:not(:empty),
.jodit-toolbar-editor-collection_mode_vertical .jodit-toolbar-button__text:not(:empty) {
	white-space: nowrap;
}
.jodit-status-bar a.jodit-status-bar-link {
	display: none;
}

.jodit .jodit-workplace .jodit-wysiwyg :first-child,
.jodit .jodit-workplace .jodit-wysiwyg_iframe :first-child,
.jodit-container .jodit-workplace .jodit-wysiwyg :first-child,
.jodit-container .jodit-workplace .jodit-wysiwyg_iframe :first-child {
	margin-top: 0;
	padding-top: 0;
}
.jodit .jodit-workplace .jodit-wysiwyg :last-child
.jodit .jodit-workplace .jodit-wysiwyg_iframe :last-child
.jodit-container .jodit-workplace .jodit-wysiwyg :last-child
.jodit-container .jodit-workplace .jodit-wysiwyg_iframe :last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}
.jodit__preview-box {
	font-size: 17px;
	padding: 20px !important;
	min-width: auto !important;
}
.jodit__preview-box :first-child {
	margin-top: 0;
	padding-top: 0;
}
.jodit__preview-box :last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}
.jodit-dialog__panel {
	max-height: 90%;
	max-width: 90%;
}
