.cpnt-profsel-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cpnt-profsel-freelas-wrapper {
	margin: 0 -5px;
	flex: 1;
}
.cpnt-profsel-freelas {
	display: flex;
}
.cpnt-profsel-lista-itens {
	display: flex;
	width: 100%;
	flex-direction: column;
}
.cpnt-profsel-freela-box {
	max-width: 50px;
}

/*
** Container de lista
** ================================================== */
.cpnt-profsel-sticky {
	position: sticky;
	top: -1px;
	z-index: 800;
}
.cpnt-profsel {
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	background-color: #FFF;
	max-width: 100%;
	box-sizing: border-box;
	overflow-x: auto;
	scrollbar-width: thin;
	margin-bottom: -1px;
}
.cpnt-profsel-acoes {
	min-width: 300px;
	max-width: 300px;
	display: flex;
	align-items: center;
}
.cpnt-profsel-btn-opcoes {
	padding-left: 10px;
}
.cpnt-profsel-lista {
	max-width: 400px;
}
.cpnt-profsel-imgdesc-x.x {
	z-index: 11;
	opacity: 0;
	visibility: hidden;
}

.cpnt-profsel-imgdesc:hover .x {
	opacity: 1;
	visibility: visible;
}

.cpnt-profsel-btn-local:hover ~ .cpnt-profsel-itens-local .foto-wrap-2 {
	border-color: #0277BD;
}
.cpnt-profsel-btn-salvo:hover ~ .cpnt-profsel-itens-salvo .foto-wrap-2 {
	border-color: #0277BD;
}
.cpnt-profsel-btn-todos:hover ~ .cpnt-itemprofsel .foto-wrap-2 {
	border-color: #0277BD;
}