.pg-cademp-wrapper {
	display: flex;
}
.pg-cademp-box-side {
	padding: 25px;
}

/*
** Box de botões
** ================================================== */
.cademp-btn-box {
	padding-top: 30px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.cademp-btn-esq {
	width: 200px;
}
.cademp-btn-dir {
	width: 200px;
	display: flex;
	justify-content: flex-end;
}
.cademp-info-centro {
	text-align: center;
}