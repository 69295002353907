%input-reset {
	box-sizing: border-box;
	width: 100%;
	border: 2px solid var(--color-border);
	padding: var(--gap-item-alt);
	border-radius: var(--border-radius);
	outline: none;
	transition: var(--transition);
	font-family: inherit;
}

%input {
	@extend %input-reset;
	display: block;

	&:hover,
	&:focus {
		border-color: var(--color-1);
	}
}

%req {
	&:before {
		content: '*';
		color: var(--color-red);
		margin-left: 1px;
	}
}
