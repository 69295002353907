.cpnt-selidiomas-container {
	max-height: 450px;
	overflow-y: auto;
}
.cpnt-selidiomas-box {
	position: relative;
}
.cpnt-selidiomas-box:nth-child(odd) {
	background-color: #FFF;
}
.cpnt-selidiomas-box:hover {
	background-color: #e4ebee;
}
.cpnt-selidiomas-box .cpnt-selidiomas-check {
	padding: 10px 0 0 10px;
}

.cpnt-selidiomas-box .checkbox-box .checkbox-check {
	position: absolute;
	right: 10px;
	top: 10px;
}
.cpnt-selidiomas-box .checkbox-box {
	padding-right: 30px;
}

.cpnt-selidiomas-sem-niveis label {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.cpnt-selidiomas-sem-niveis .cpnt-selidiomas-sem-niveis-inner {
	padding: 5px;
	position: relative;
}
.cpnt-selidiomas-sem-niveis .cpnt-checkbox2-check {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

@media (max-width: 780px) {
	body .cpnt-selidiomas-container .cpnt-selidiomas-nivel-container {
		flex-direction: column;
		align-items: flex-start;
	}
	body .cpnt-selidiomas-container .cpnt-selidiomas-nivel-opts {
		flex-direction: column;
		margin-top: 5px;
	}
	body .cpnt-selidiomas-nivel-opts > div {
		margin-left: 0;
		margin-top: 10px;
	}
}