.pg-convideumfreelancer-box {
	padding: 50px 15px;
	overflow: hidden;
}
.pg-convide-box {
	font-size: 16px;
	display: flex;
}
.pg-convide-l {
	padding-right: 50px;
}
.pg-convide-r {
	max-width: 350px;
	min-width: 350px;
}
.pg-convide-icones-box {
	display: flex;
	flex-wrap: wrap;
	font-size: 15px;
}
.pg-convide-icone {
	text-align: center;
	min-width: 14.2857%;
	max-width: 14.2857%;
	padding: 5px;
	box-sizing: border-box;
}
.pg-convide-icone img {
	display: block;
	width: 100%;
	max-width: 118px;
	margin: auto;
}

@media (max-width: 980px) {
	.pg-convideumfreelancer-box {
		padding: 15px 10px 50px;
	}
	.pg-convide-icone {
		min-width: 25%;
		max-width: 25%;
	}
	.pg-convide-box {
		flex-wrap: wrap;
	}
}

@media (max-width: 980px) {
	.pg-convide-icone {
		min-width: 33.3333%;
		max-width: 33.3333%;
	}
}

@media (max-width: 680px) {
	.pg-convide-icone {
		min-width: 50%;
		max-width: 50%;
	}
}
