.cpnt-iconechat-box {
	position: relative;
	bottom: -5px;
	left: -4px;
}
.cpnt-iconechat-count {
	position: absolute;
	right: -10px;
	top: -8px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	font-size: 12px;
	padding: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cpnt-iconechat-sem-msg {
	color: #FFF;
	background-color: #bcc1c4;
}
.cpnt-iconechat-com-msg {
	color: #FFF;
	background-color: #0277BD
}
