.cptn-formtemplate-msgpreview-box {
	display: flex;
}
.cptn-formtemplate-msgpreview-box-1 {
	max-width: 400px;
	min-width: 400px;
}
.cptn-formtemplate-msgpreview-box-2 {
	padding-left: 30px;
	flex: 1;
}