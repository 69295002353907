.cpnt-itemusuario-userpic-box {
	display: flex;
}
.cpnt-itemusuario-foto-box {
	width: 90px;
	height: 90px;
	position: relative;
	border-radius: 50%;
	cursor: pointer;
}
.cpnt-itemusuario {
	margin: -5px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px;
}
.cpnt-itemusuario-item {
	padding: 5px 5px 5px 7px;
	box-sizing: border-box;
	margin: 5px;
	background-color: #FFF;
	border: 1px solid #D7D7D7;
	border-radius: 3px;
}
.cpnt-itemusuario-item:hover {
	background-color: #FFF;
}
.cpnt-itemusuario-info {
	padding-left: 15px;
}
.cpnt-itemusuario-dados {
	margin-top: 3px;
}
.cpnt-itemusuario-edit {
	min-width: 20px;
	max-width: 20px;
	padding: 10px;
	cursor: pointer;
}
.cpnt-itemusuario-status {
	margin-right: 10px;
	padding-left: 10px;
}

/* Níveis de acesso */
.cpnt-itemusuario-nivel-administrador {
	color: #E74C3C;
}
.cpnt-itemusuario-nivel-hunter {
	color: #229d3e;
}