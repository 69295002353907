.cpnt-notificacoes-lista-item + .cpnt-notificacoes-lista-item {
	/* border-top: 1px solid #b4b8b9; */
}
.cpnt-notificacoes-lista-item {
	position: relative;
	top: 0;
	display: flex;
	font-size: 13px;
	padding: 15px;
	border: 1px solid #b4b8b9;
	background-color: #FFF;
	border-radius: 5px;
	margin: 10px;
	cursor: pointer;
	box-shadow: 0;
	transition: all 0.2s ease 0s;
}
.cpnt-notificacoes-lista-item:hover {
	top: -3px;
	background-color: #FFF;
	box-shadow: 0 3px 5px rgba(0,0,0,0.1);
}

.cpnt-notificacoes-lista-img {
	max-width: 55px;
	flex: 1;
	padding-right: 15px;
}
.cpnt-notificacoes-lista-info {
	flex: 1;
}
.cpnt-notificacoes-fixo {
	border-bottom: 1px solid #b4b8b9;
	padding: 15px;
}
.cpnt-notificacoes-lista-item-naolida {
	background-color: #f8fcff;
	border-color: #2596e1;
}

.cpnt-notificacoes-bottom {
	border-top: 1px solid #b4b8b9;
	padding: 5px 10px;
}
