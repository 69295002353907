.cpnt-itemprofsel-wrap {
	display: flex;
	flex-direction: column;
	max-width: 230px;
	min-width: 230px;
}
.cpnt-itemprofsel-wrap-img-box {
	margin: -16px -16px 16px;
	border-radius: 2px 2px 0 0;
	overflow: hidden;
}
.cpnt-itemprofsel {
	margin: 5px;
}
