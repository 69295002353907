.container {
	padding: 30px;

	.inner {
		max-width: 800px;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 30px;
		padding: 30px;
	}

	.box {
		border: 1px solid var(--color-border);
		border-radius: 3px;
		padding: 30px;
	}

	.btn {
		margin-top: 15px;
	}
}

