.pg-indique-profissional-container {
	display: flex;
	width: 100%;
	height: 100%;
	border: 0;
	min-width: 100%;
	max-width: 100%;
	flex-direction: column;
}
.pg-ind-topo {
	background-color: #FFF;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}
.pg-indique-base {
	max-width: 960px;
	margin: auto;
}
.pg-indique-base-content {
	padding: 6px 27px;
}

/*
** Conteudo
** ================================================== */

/*
** Topo
** ================================================== */
.pg-ind-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
}
.pg-ind-topo-logo img {
	display: block;
	width: 245px;
}

/*
** Principal e Formulário
** ================================================== */
.pg-ind-main {
	background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./Images/banner-orcamento-v1.jpg');
	background-size: cover;
	background-position: center center, center top;
	padding: 50px 0;
}
.pg-ind-main-box {
	display: flex;
}
.pg-ind-main-l {
	color: #FFF;
	padding-right: 50px;
}
.pg-ind-main-r {
	background-color: #FFF;
	border-radius: 10px;
	max-width: 350px;
	min-width: 350px;
	padding: 15px;
	box-sizing: border-box;
}
.pg-ind-main-b {
	color: #FFF;
	text-align: center;
	max-width: 550px;
	margin: auto;
	padding-top: 50px;
}
.pg-ind-main-orc {
	margin-top: 50px;
	padding-right: 80px;
	display: flex;
	align-items: center;
	font-weight: bold;
}
.pg-ind-main-orc:after {
	content: '';
	height: 20px;
	width: 60px;
	background: url('./Images/glyph.png') repeat-x;
	background-size: auto 100%;
	display: inline-flex;
	margin-left: 10px;
}

/*
** Depoimentos
** ================================================== */
.pg-ind-users {
	background-color: #F2F2F2;
	padding-top: 30px;
}
.pg-ind-users-inner {
	display: flex;
	font-size: 15px;
}
.cpntpg-induser-box {
	padding: 30px;
}

/*
** Passos depois
** ================================================== */
.pg-ind-passos {
	background: #F2F2F2 url('./Images/bg-glyph.webp') no-repeat center center;
	background-size: cover;
	padding: 80px 0 50px;
}
.pg-ind-passos-titulo {
	max-width: 600px;
	font-size: 38px;
	margin: auto;
	font-weight: bold;
	text-align: center;
}
.pg-ind-passos-icon-txt {
	position: relative;
	font-size: 15px;
	padding-top: 100px;
}
.pg-ind-passos-icon-txt-1:before,
.pg-ind-passos-icon-txt-2:before,
.pg-ind-passos-icon-txt-3:before {
	content: '';
	position: absolute;
	top: 30px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 6px solid #000;
	left: 50%;
	transform: translateX(-50%);
}
.pg-ind-passos-icon-txt-2:before {
	border: 0;
	width: 32px;
	height: 32px;
	background: url('./Images/seta-dir.webp') no-repeat center center;
	background-size: cover;
	top: 36px;
}
.pg-ind-passos-icon-txt-3:before {
	background-color: #000;
}
.pg-ind-passos-icon-txt-2::after {
	content: '';
	position: absolute;
	top: 50px;
	height: 4px;
	background-color: #000;
	width: 568px;
	left: 50%;
	transform: translateX(-50%);
}
.pg-ind-passos-icons-box {
	display: flex;
	padding-top: 40px;
}
.pg-ind-passos-icon-box img {
	display: block;
	width: 100%;
}
.pg-ind-passos-icon-box {
	max-width: 72px;
	border-radius: 50%;
	margin: auto;
	box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}
.pg-ind-passos-icons {
	text-align: center;
	padding: 30px;
	min-width: 33.3333%;
	max-width: 33.3333%;
	box-sizing: border-box;
}
.pg-ind-passos-icons-btn {
	text-align: center;
	padding-top: 40px;
}

/*
** Footer
** ================================================== */
.pg-ind-footer-bottombar {
	background-color: #CCC;
	padding: 15px 0;
}
.pg-ind-footer-bottombar-inner {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}
.pg-ind-footer-bottombar-inner a {
	text-decoration: none;
	color: inherit;
}
.pg-ind-footer-bottombar-inner a:hover {
	text-decoration: underline;
}
.pg-ind-footer-bottombar-inner a + a {
	margin-left: 20px;
}

/*
** Responsivo
** ================================================== */

@media (max-width: 980px) {
	.pg-ind-main-box {
		flex-direction: column;
		align-items: center;
	}
	.pg-ind-main-orc {
		display: none;
	}
	.pg-ind-main-r {
		margin-top: 25px;
	}
	.pg-ind-users-inner {
		flex-direction: column;
		align-items: center;
	}
	.cpntpg-induser-imgbox {
		margin: auto;
		text-align: center;
	}
	.cpntpg-induser-box {
		max-width: 300px;
	}
	.pg-ind-passos-icons-box {
		flex-direction: column;
	}
	.pg-ind-passos-icons-box {
		align-items: center;
	}
	.pg-ind-passos-icon-txt-1::before,
	.pg-ind-passos-icon-txt-2::before,
	.pg-ind-passos-icon-txt-2::after,
	.pg-ind-passos-icon-txt-3::before {
		content: none;
	}
	.pg-ind-passos-icon-txt {
		padding-top: 20px;
	}
	.pg-ind-passos {
		padding: 40px 0 30px;
	}
	.pg-ind-footer-bottombar-inner {
		flex-direction: column;
		align-items: center;
	}
	.pg-ind-footer-bottombar-r {
		margin-top: 15px;
	}
	.pg-ind-passos-icons {
		min-width: auto;
		max-width: 300px;
	}
	.pg-ind-main-l {
		padding-right: 0;
	}
	.pg-ind-main {
		background-position: center center, left center;
	}
}

@media (max-width: 680px) {
	.pg-ind-topo-logo img {
		width: 150px;
	}
	.pg-ind-menu {
		padding: 5px 0;
	}
	.pg-indique-profissional-container {
		font-size: 14px;
	}
	.pg-ind-passos-titulo {
		font-size: 26px;
	}
	.pg-ind-main-r {
		min-width: 300px;
	}
}