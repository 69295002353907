.cpnt-xloading-box {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.cpnt-xloading-x {
	cursor: pointer;
	min-width: 25px;
	max-width: 25px;
	min-height: 25px;
	max-height: 25px;
	border-radius: 50%;
	background: #000 url('./x.png') no-repeat center center;
	background-size: 52% auto;
	transition: background-color 0.3s ease 0s;
	border: 2px solid #FFF;
}
.cpnt-xloading-x:hover {
	background-color: #E74C3C;
}
.cpnt-xloading-abs {
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 999;
}
.cpnt-xloading-abs-alt {
	position: absolute;
	right: -15px;
	top: -15px;
	z-index: 999;
}