/*
** Geral
** ================================================== */
.cpnt-perfil-portal-container {
	background-color: #FFF;
	border-radius: 5px;
	width: 1000px;
	box-shadow: 0 5px 25px rgba(0,0,0,0.3);
	overflow: hidden;
}
.cpnt-perfil {
	font-size: 15px;
}
.cpnt-perfil-wrap {
	display: flex;
}

/*
** Sidebar
** ================================================== */
.cpnt-perfil-sidebar {
	position: relative;
	color: #FFF;
	background-color: #333;
	min-width: 400px;
	max-width: 400px;
}
.cpnt-perfil-sidebar-inner {
	padding: 40px;
}

.cpnt-perfil-sidebar .cpnt-linhaitem-desc {
	color: #CCC;
}
.cpnt-perfil-info-inativo {
	background-color: #222222;
	border-radius: 5px;
	padding: 10px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}

/* Habilidades */
.cpnt-perfil-habilidade-titulo {
	color: #CCC;
	margin-top: 15px;
	font-weight: bold;
	margin-bottom: 15px;
}
.cpnt-perfil-tag-sidebar {
	font-size: 12px;
	padding: 3px 6px;
	border-radius: 2px;
	color: #FFF;
	background-color: #555;
	margin: 3px;
	box-shadow: 0 2px 2px rgba(0,0,0,0.2);
}

/* Links */
.cpnt-perfil-link-sidebar {
	color: #FFF;
	text-decoration: none;
	transition: all 0.3s ease 0s;
	border-bottom: 2px solid transparent;
}
.cpnt-perfil-link-sidebar:hover {
	border-bottom-color: rgba(255, 255, 255, 0.7);
}

/* Redes sociais */
.cpnt-perfil-rede-social-wrap {
	margin: 3px;
	position: relative;
}
.cpnt-perfil-rede-social-wrap::before {
	content: '';
	position: absolute;
	left: 2px;
	right: 2px;
	top: 2px;
	bottom: 2px;
	background-color: #FFF;
	border-radius: 5px;
}

/*
** Seções
** ================================================== */
.cpnt-perfil-secao + .cpnt-perfil-secao {
	margin-top: 20px;
}

/* Titulo */
.cpnt-perfil-titulo {
	font-size: 19px;
	margin: 0 0 15px;
	padding: 0 0 4px;
	color: #2596e1;
	position: relative;
	line-height: 1.1;
}
.cpnt-perfil-titulo:after,
.cpnt-perfil-titulo:before {
	content: '';
	position: absolute;
	top: 100%;
	height: 4px;
	left: 0;
	background-color: #2596e1;
}
.cpnt-perfil-titulo:after {
	width: 50px;
}
.cpnt-perfil-titulo:before {
	width: 100%;
	opacity: 0.4;
}

/* Conteudo */
.cpnt-perfil-conteudo {
	flex: 1;
	box-sizing: border-box;
	padding: 30px 30px 40px;
}

/* Específicos */

/* Nome */
.cpnt-perfil-info {
	color: #fff;
	background: rgba(51, 51, 51, 0.7);
	border-radius: 3px;
	padding: 15px 20px;
	text-shadow: 1px 1px #2E2F31;
	backdrop-filter: blur(3px);
	box-shadow: 0 1px 2px rgba(0,0,0,0.3);
}
.cpnt-perfil-descricao {
	white-space: pre-line;
}

/* Info nome */
.cpnt-perfil-infobox-info {
	padding-top: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 13px;
}
.cpnt-perfil-sep {
	margin-left: 10px;
	margin-right: 10px;
}
.cpnt-perfil-sep:before {
	content: '•';
}

/* Estrelas */
.cpnt-perfil-estrelas {
	margin-top: 5px;
	display: flex;
	justify-content: center;
}

/* Video */
.cpnt-perfil-video-texto-box {
	display: flex;
}
.cpnt-perfil-video-preview-box {
	min-width: 150px;
	max-width: 150px;
	height: 90px;
	overflow: hidden;
	position: relative;
	border-radius: 3px;
	border: 1px solid #2E2F31;
}
.cpnt-perfil-video-preview {
	transform: scale(1.3);
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.cpnt-perfil-video-texto {
	padding-left: 15px;
}

.cpnt-perfil-video-icone-play {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	/* backdrop-filter: blur(3px); */
	transition: all 0.3s ease 0s;
}
.cpnt-perfil-video-icone-play:hover {
	transform: scale(1.2);
}
.cpnt-perfil-video-icone-play .cpnt-icone-wrap {
	filter: drop-shadow(0 0 3px #FFF);
}
.cpnt-perfil-video-player-wrap {
	padding-top: 56.45%;
}
.cpnt-perfil-video-player {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	box-sizing: border-box;
	margin: auto;
	background-color: #2E2F31;
}
.cpnt-perfil-video-player-container .cpnt-portal-close {
	right: -30px;
	top: -20px;
	background-color: #FFF;
	border-radius: 50%;
}
.cpnt-perfil-video-player-container .cpnt-portal-close:before {
	left: 5px;
	top: 5px;
	right: 5px;
	bottom: 5px;
}

/* Perfil */
.cpnt-perfil-descricao-perfil {
	margin-top: 20px;
	background-color: #FBFEFF;
	padding: 10px;
	border: 1px solid #2596E1;
	border-radius: 3px;
	white-space: pre-line;
}
.cpnt-perfil-tag-conteudo {
	font-size: 12px;
	padding: 3px 6px;
	border-radius: 2px;
	color: #fff;
	background-color: #333333;
	margin: 3px;
	box-shadow: 0 2px 2px rgba(0,0,0,0.1);
}

/* Glyph */
.cpnt-perfildesc-glyph:before {
	content: '';
	position: absolute;
	border: 30px solid transparent;
	z-index: 3;
	transform: rotate(-45deg);
	opacity: 0.7;
}

.cpnt-perfildesc-glyph-1::before {
	right: -23px;
	bottom: -23px;
	border-top-color: #CCC;
	border-width: 20px;
}
.cpnt-perfildesc-glyph-2::before {
	right: -20px;
	bottom: -20px;
	border-top-color: #2E2F31;
	border-width: 15px;
}
.cpnt-perfildesc-glyph-3::before {
	left: -23px;
	top: -23px;
	border-bottom-color: #CCC;
	border-width: 20px;
}
.cpnt-perfildesc-glyph-4::before {
	left: -20px;
	top: -20px;
	border-bottom-color: #2E2F31;
	border-width: 15px;
}

/* Footer */
.cnpt-perfil-footer {
	position: relative;
	padding: 10px;
	display: flex;
	justify-content: center;
	background-color: #222222;
}
.cnpt-perfil-footer img {
	max-width: 100px;
	min-width: 100px;
	display: block;
}
