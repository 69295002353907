.cpnt-perfilfreelancer-img-container {
	background: #222 url('./bg.png');
    animation: bgAnimadoTopLeft 120s linear infinite;
	padding: 30px 0 20px;
}
.cpnt-perfilfreelancer-img {
	margin: 0 100px 0;
}
.cpnt-perfilfreelancer-nome {
	text-align: center;
	font-size: 22px;
}
.cpnt-perfilfreelancer-infobox {
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 14px;
}
.cpnt-perfilfreelancer-infobox-estado {
	max-width: 135px;
}

/* Senioridade */
.cpnt-perfil-freelancer-senioridade {
	position: absolute;
	left: 0;
	top: 0;
	padding: 2px 15px 5px 15px;
	color: #FFF;
	font-weight: bold;
	font-size: 14px;
	background-color: rgba(51, 51, 51, 0.7);
	border-radius: 5px 0 15px 0;
	opacity: 0.9;
	text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}

/* PDF */
.cpnt-perfil-btn-pdf,
.cpnt-perfil-btn-acoes {
	position: absolute;
	right: 25px;
	text-decoration: none;
	top: 5px;
	z-index: 9;
	padding: 4px 5px 2px;
	font-size: 12px;
}
.cpnt-perfil-btn-acoes {
	font-size: 13px;
}
.cpnt-perfil-btn-download-pdf {
	text-decoration: none;
	display: block;
	transition: all 0.3s ease 0s;
	border-radius: 10px;
	background-color: transparent;
	padding: 30px;
	border: 1px solid transparent;
}
.cpnt-perfil-btn-download-pdf:hover {
	background-color: #FAFAFA;
	border-color: #2596e1;
}
