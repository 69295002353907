.portal {
	max-width: 700px;
}

.portal-info-alterado {
	max-width: 700px;
	padding: 40px;
}

.boxes-nivel {
	display: grid;
	gap: var(--gap-large);
	margin: var(--gap-big) 0;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	.box-nivel {
		padding: 30px;
		border-radius: var(--border-radius);
		text-align: center;
		border: 1px solid;
		cursor: pointer;
		opacity: 0.5;
		transition: var(--transition);

		&.box-nivel-lite {
			background-color: var(--color-2-role-9);
			border-color: var(--color-1-role-9);

			&.active {
				box-shadow: 0 0 0 5px var(--color-2-role-9);
			}
		}

		&.box-nivel-premium {
			background-color: var(--color-2-role-3);
			border-color: var(--color-1-role-3);

			&.active {
				box-shadow: 0 0 0 5px var(--color-2-role-3);
			}
		}

		&:hover,
		&.active {
			opacity: 1;
		}
	}
}
