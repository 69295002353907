.cpnt-btnexpandnmin-btn-wrap {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
}
.cpnt-btnexpandnmin-btn {
	width: 32px;
	height: 32px;
	border: 0;
	padding: 0;
	cursor: pointer;
	background-color: transparent;
}
