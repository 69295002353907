.cliente-foto-box {
	border-radius: 2px;
}
.clientes-logotipo-box .foto-video-x {
	right: -15px;
	top: -15px;
}

/* Colunas TH */
.lista-clientes-col-id {
	width: 60px;
	text-align: center;
}
table .lista-clientes-col-info {
	width: 80px;
	padding: 0;
}
table .lista-clientes-col-tax {
	width: 60px;
}
table .lista-clientes-col-projetos {
	padding: 0;
	width: 80px;
}
table .lista-clientes-col-anexos {
	padding: 0;
	width: 80px;
}
.lista-clientes-col-projetos {
	width: 100px;
}
table .lista-clientes-col-status {
	padding: 0;
	width: 80px;
}
table .lista-clientes-col-acoes {
	padding: 0;
	width: 80px;
}


.cliente-site-preview-btn-link {
	position: absolute;
	right: -7px;
	top: 22px;
	background-color: #3C8FE7;
	border-radius: 50%;
	z-index: 9;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cliente-site-preview-resize {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 9;
	padding: 5px;
	pointer-events: none;
}
.cliente-site-preview-resize::before {
	content: '';
	position: absolute;
	border: 17px solid transparent;
	border-left-color: #3C8FE7;
	transform: rotate(45deg);
	right: -17px;
	bottom: -17px;
}
