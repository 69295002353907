.bg {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
.bg-back {
	z-index: -1;
}

.portal {
	z-index: 9999;
	font-size: 17px;

	.container {
		padding: 20px 25px;
		box-sizing: border-box;
		max-width: 100%;
	}
	.default-alt {
		background-color: var(--color-white);
		box-shadow: 0 5px 15px var(--color-black-alpha-2);
		border-radius: 3px;
		/* overflow: hidden; */
	}

	.wrap-box {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		background-color: var(--color-black-alpha-3);
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
	}

	.wrap {
		margin: auto;
		min-width: 0;
		padding: 15px;
	}
	.inner {
		position: relative;
	}

	.close {
		cursor: pointer;
		position: absolute;
		right: 20px;
		top: 20px;
		width: 15px;
		height: 15px;
		z-index: 10;
		padding: 5px;
	}
	.close:before {
		content: '';
		mask: url('x.png') no-repeat center center;
		mask-size: contain;
		background-color: var(--color-text-info);
		position: absolute;
		left: 2px;
		top: 2px;
		right: 2px;
		bottom: 2px;
	}

	.top {
		margin: 0;
		padding: 18px 60px 18px 25px;
		background-color: var(--color-white);
		border-bottom: 1px solid var(--color-border);
		border-radius: 10px 10px 0 0;
	}
	.titulo {
		font-size: 21px;
		margin: 0;
		padding: 0;
	}
	.conteudo {
		/* background-color: #FCFCFC; */
		background-color: var(--color-bg);
		box-shadow: 0 5px 15px var(--color-black-alpha-2);
		border-radius: 3px;
		box-sizing: border-box;
		max-width: 100%;
	}

	.conteudo {
		max-width: 100%;
	}

	.site {
		background-color: var(--color-white);
		box-shadow: 0 5px 15px var(--color-black-alpha-2);
		border-radius: 3px;
		box-sizing: border-box;
		max-width: calc(100vw - 100px);
		width: calc(100vw - 100px);
		height: calc(100vh - 100px);
		resize: both;
		overflow: auto;
		position: relative;
	}
	.site iframe {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
	}

	.preview-foto {
		width: 800px;
		max-width: 100%;
	}

	@media (max-width: 680px) {
		.default {
			padding: 20px;
		}
		.top {
			padding: 15px 60px 15px 20px;
		}
		.titulo {
			font-size: 18px;
		}
	}
}
