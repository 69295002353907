.cpnt-chatlistaitem {
	cursor: pointer;
}
.cpnt-chatlistaitem + .cpnt-chatlistaitem {
	border-top: 1px solid #EEE;
}
.cpnt-chatlistaitem:last-child {
	border-bottom: 1px solid #EEE;
}
.cpnt-chatlistaitem-box {
	display: flex;
	align-items: center;
	max-width: 100%;
	min-width: 0;
	box-sizing: border-box;
	padding: 10px 10px 10px 5px;
	border-left: 5px solid transparent;
}
.cpnt-chatlistaitem-foto {
	position: relative;
	min-width: 55px;
	max-width: 55px;
}
.cpnt-chatlistaitem-info {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: 2px solid #FFF;
}
.cpnt-chatlistaitem-info-ativo {
	background-color: #07BC4C;
}
.cpnt-chatlistaitem-info-inativo {
	background-color: #BABABA;
}
.cpnt-chatlistaitem-msg-status {
	display: flex;
	align-items: flex-end;
}
.cpnt-chatlistaitem-status {
	margin-right: 5px;
}
.cpnt-chatlistaitem-nomemsg {
	flex: 1;
	max-width: 100%;
	min-width: 0;
	padding-left: 10px;
}
.cpnt-chatlistaitem-msg-status {
	color: #757575;
	font-size: 14px;
}
.cpnt-chatlistaitem-dataqtd {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding-left: 10px;
	align-self: stretch;
	justify-content: space-around;
}
.cpnt-chatlistaitem-nome {
	font-size: 18px;
}
.cpnt-chatlistaitem-digitando {
	color: #07BC4C;
	font-weight: bold;
}

/* Col 3 */
.cpnt-chatlistaitem-data {
	font-size: 12px;
}
.cpnt-chatlistaitem-data {
	font-size: 12px;
	align-self: flex-start;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-end;
}
.cpnt-chatlistaitem-data-tempo {
	color: #999;
}
.cpnt-chatlistaitem-qtd {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	width: 15px;
	height: 15px;
	padding: 2px;
	border-radius: 50%;
	background-color: #2596e1;
	color: #FFF;
	align-self: flex-end;
	margin-top: 3px;
}

.cpnt-chatlistaitem:hover {
	background-color: #F9F9FB;
}

/*
** Mensagens não lidas
** ================================================== */
.cpnt-chatlistaitem-msg-nova {
	background-color: #F5FBFF;
}
.cpnt-chatlistaitem-msg-nova .cpnt-chatlistaitem-nome {
	color: #0277BD;
}

/*
** Mensagem ativa
** ================================================== */
.cpnt-chatlistaitem-ativo .cpnt-chatlistaitem-box {
	background-color: #EEE;
	border-left-color: #2596e1;
}
