/*
** Geral
** ================================================== */
.link {
	text-decoration: none;
	color: #0277BD;
	font-weight: bold;
	cursor: pointer;
}
.a {
	color: #0277BD;
	text-decoration: none;
	cursor: pointer;
}
.a:hover {
	text-decoration: underline;
}
.hover-underline:hover {
	text-decoration: underline;
}
.base-lp {
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
.base-m {
	max-width: 980px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}
.base-s {
	max-width: 560px;
	margin-left: auto;
	margin-right: auto;
}
.ff-1 {
    font-family: 'Muli', sans-serif;
}
.ff-2 {
    font-family: sans-serif;
}

/*
** Conteúdo
** ================================================== */
.desc {
	color: #6c7c82;
	font-size: 14px;
	font-weight: normal;
}
.form-bottom,
.form-bottom-alt {
	display: flex;
	justify-content: flex-end;
	margin: 25px -25px -20px;
	padding: 15px 20px;
	background-color: #FFF;
	border-top: 1px solid #DFDFDF;
	border-radius: 0 0 10px 10px;
	gap: var(--gap);
}
.form-bottom-alt {
	justify-content: space-between;
}

.conteudo {
}
.conteudo-box {
	box-sizing: border-box;
}
.conteudo-container {
	padding-left: 260px;
	transition: padding 0.3s ease 0s;
	flex: 1;
	background-color: #F7F8FA;
}
.conteudo-container-hide {
	padding-left: 0;
}
.input-wrap {
	padding: 10px;
}
.req {
	color: #cf2c2c;
	margin-left: 3px;
}
.input-box-alt {
	padding: 15px;
	box-sizing: border-box;
}
.input-box {
	padding: 10px 5px 5px;
	box-sizing: border-box;
}
.inputs-box {
	margin: -10px -5px -5px;
	display: flex;
	flex-wrap: wrap;
}
.input-box-btnlimpar {
	display: flex;
}
.input-box-alt-padding-top {
	padding-top: 5px;
	box-sizing: border-box;
}
.conteudo-sem-tag {
	white-space: pre-line;
}

.container-center {
	min-height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	max-width: 100%;
	margin: auto;
}
.container-center-wrap {
	margin: auto;
	flex: 1;
}
.container-center-inner {
	padding: 20px;
}

.container-alt {
	min-height: 100vh;
}

/* 100% */
.container-100 {
	background: #FAFAFA;
	height: 100vh;
	display: flex;
}
.container-100 .conteudo-container {
	display: flex;
	flex-direction: column;
	min-width: 0;
}
.container-100 .conteudo-container-box-wrap {
	flex: 1 1;
	display: flex;
	box-sizing: border-box;
	height: 100%;
	min-height: 0;
	max-height: 100%;
}

.label {
	position: absolute;
	transform: translateY(-50%);
	padding: 5px;
	z-index: 1;
	font-size: 12px;
	top: 0;
	left: 6px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;
	font-weight: bold;
}
.input {
	position: relative;
	border: 1px solid #2E2F31;
	border-radius: 4px;
	padding: 12px 10px;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
	font-size: 14px;
	background-color: #FFF;
}
.input-inativo {
	background: linear-gradient(#FFF, #F4F4F4);
}

.wrap-input {
	border: 1px solid #D5D5D5;
	box-sizing: border-box;
	border-radius: 3px;
	margin: 5px;
	padding: 5px;
	background-color: #FAFAFA;
}
.x {
	position: absolute;
	right: -7px;
	top: -7px;
}
.x,
.x-alt,
.x-min {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #E74C3C;
	border-radius: 50%;
	cursor: pointer;
	z-index: 10;
	width: 25px;
	height: 25px;
}
.x-alt {
	position: relative;
}
.x:before,
.x-alt:before,
.x-min:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: url('../imagens/x-branco.png') no-repeat center center;
	background-size: 50% 50%;
}
.x-small {
	width: 20px;
	height: 20px;
}
.x-min {
	width: 16px;
	height: 16px;
	right: -5px;
	top: -3px;
}
.transition {
	transition: all 0.2s ease 0s;
}
.info-wrap {
	display: inline-flex;
	border-radius: 50%;
	background-color: #6c7c82;
	width: 22px;
	height: 22px;
	justify-content: center;
	align-items: center;
	color: #FFF;
	font-weight: bold;
}

/*
** Scrollbar
** ================================================== */
.scroll {
	scrollbar-width: thin;
	scrollbar-color: dark;
}

.sidebar::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
	width: 9px;
	height: 9px;
}
.sidebar::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
	background: #232324;
}
.sidebar::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb {
	background-color: #36363a;
	border-radius: 20px;
	border: 2px solid #232324;
}

.scroll-alt {
	scrollbar-color: light;
}
.scroll-alt::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
.scroll-alt::-webkit-scrollbar-track {
	background: #EFEFEF;
}
.scroll-alt::-webkit-scrollbar-thumb {
	background-color: #DDD;
	border-radius: 2px;
	border: 0;
}


/*
** Footer
** ================================================== */
.footer {
	text-align: center;
	padding: 10px;
	background-color: #FFF;
	border-top: 1px solid #DFDFDF;
	box-shadow: 0 -2px 3px rgba(213, 213, 213, 0.3);
	font-size: 13px;
}

/*
** Outros
** ================================================== */
.img-shadow-radius {
	border-radius: 3px;
	overflow: hidden;
	box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

/*
** Conteúdo
** ================================================== */
/* Titulo */
.titulo {
	margin: 0;
	padding: 0;
}
.titulo-g {
	font-size: 44px;
	line-height: 1.1;
}
.titulo-box {
	background-color: #FFF;
	border-bottom: 1px solid #DFDFDF;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	gap: var(--gap);
}
.filtros-box {
	padding: 10px;
}
.total-e-paginacao {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	background-color: #FFF;
}
.texto-simples {
	white-space: pre-line;
	word-break: break-word;
}

/*
** Old
** ================================================== */
.titulo-wrap {
	display: flex;
	width: 100%;
	align-items: center;
}
.titulo-pagina {
	margin: 0;
	padding: 0;
}
.info-dir {
	margin-left: auto;
	padding-left: 20px;
}

/*
** Cores
** ================================================== */
.color-3 {
	color: #AAA;
}
.color-4 {
	color: #0277BD;
}
.color-5 {
	color: #f0f9ff;
}
.color-6 {
	color: #ccc;
}
.color-7 {
	color: #FAFAFA;
}

.bg-2 {
	background-color: #757575;
}
.bg-3 {
	background-color: #AAA;
}
.bg-4 {
	background-color: #0277BD;
}
.bg-5 {
	background-color: #f0f9ff;
}
.bg-6 {
	background-color: #ccc;
}
.bg-7 {
	background-color: #FAFAFA;
}

.border-color-2 {
	border-color: #757575;
}
.border-color-3 {
	border-color: #AAA;
}

.bg-7-opct {
	background-color: rgba(250, 250, 250, 0.96);
}

/*
** Aspect Ratio
** ================================================== */
.ratio-1,
.ratio-1-1 {
	aspect-ratio: 1 / 1;
	width: 100%;
	box-sizing: border-box;
}
.ratio-4-3 {
	aspect-ratio: 4 / 3;
	width: 100%;
	box-sizing: border-box;
}
.ratio-16-9 {
	aspect-ratio: 16 / 9;
	width: 100%;
	box-sizing: border-box;
}
.ratio-banner {
	aspect-ratio: 1920 / 615;
	width: 100%;
	box-sizing: border-box;
}
.ratio-banner-mobile {
	aspect-ratio: 4 / 3;
	width: 100%;
	box-sizing: border-box;
}


/*
** Frameworcss
** ================================================== */
.border-2 {
	border: 2px solid #DDD;
}

@media (max-width: 1580px) {
	.conteudo-container {
		padding-left: 230px;
	}
	.conteudo-container-hide {
		padding-left: 0;
	}
}

@media (max-width: 1280px) {
	.conteudo-container {
		padding-left: 0;
	}
}

@media (max-width: 980px) {
	.filtros {
		display: none;
	}
	.filtros-show {
		display: block;
	}
	.titulo-box-res {
		flex-direction: column;
		position: relative;
	}
	.titulo-box-res .cpnt-btnadd-wrap {
		margin-top: 10px;
	}
}

@media (max-width: 680px) {
	.form-bottom, .form-bottom-alt {
		margin: 15px -20px -20px;
		padding: 15px 15px;
	}
	.titulo {
		font-size: 18px;
	}
	body .btn-res-l-680 {
		margin-left: 0;
		margin-top: 10px;
	}
	.form-bottom-res-680 {
		flex-direction: column;
		align-items: center;
	}
	body .margin-l-unset-680 {
		margin-left: unset;
	}
	body .margin-t-10-680 {
		margin-top: 10px;
	}
}

@media (max-width: 480px) {
	body .btn-res-l-480 {
		margin-left: 0;
		margin-top: 10px;
	}
}