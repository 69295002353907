.cpnt-portal {
	z-index: 9999;
	font-size: 17px;
}
.cpnt-portal,
.cpnt-portal-bg {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
.cpnt-portal-wrap-box {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	background: rgba(77, 80, 88, 0.8);
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
}
.cpnt-portal-bg {
	z-index: -1;
}

.cpnt-portal-wrap {
	margin: auto;
	min-width: 0;
	padding: 15px;
}
.cpnt-portal-inner {
	position: relative;
}

.cpnt-portal-close {
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 15px;
	height: 15px;
	z-index: 10;
	padding: 5px;
}
.cpnt-portal-close:before {
	content: '';
	mask: url('../../assets/imagens/x-cinza.png') no-repeat center center;
	mask-size: contain;
	background-color: #6A6A6A;
	position: absolute;
	left: 2px;
	top: 2px;
	right: 2px;
	bottom: 2px;
}

.cpnt-portal-top {
	margin: 0;
	padding: 18px 60px 18px 25px;
	background-color: #FFF;
	border-bottom: 1px solid #DFDFDF;
	border-radius: 10px 10px 0 0;
}
.cpnt-portal-titulo {
	font-size: 21px;
	margin: 0;
	padding: 0;
}
.cpnt-portal-conteudo {
	/* background-color: #FCFCFC; */
	background-color: #F7F8FA;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	border-radius: 3px;
	box-sizing: border-box;
	max-width: 100%;
}
.cpnt-portal-default {
	padding: 20px 25px;
	box-sizing: border-box;
	max-width: 100%;
}
.cpnt-portal-default-alt {
	background-color: #FFF;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	border-radius: 3px;
	/* overflow: hidden; */
}
.portal-conteudo {
	width: 960px;
	max-width: 960px;
}

.cpnt-portal-site {
	background-color: #FFF;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	border-radius: 3px;
	box-sizing: border-box;
	max-width: calc(100vw - 100px);
	width: calc(100vw - 100px);
	height: calc(100vh - 100px);
	resize: both;
	overflow: auto;
	position: relative;
}
.cpnt-portal-site iframe {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
}

.cpnt-portal-preview-foto {
	width: 800px;
	max-width: 100%;
}

@media (max-width: 680px) {
	.cpnt-portal-default {
		padding: 20px;
	}
	.cpnt-portal-top {
		padding: 15px 60px 15px 20px;
	}
	.cpnt-portal-titulo {
		font-size: 18px;
	}
}