.cpnt-select2 {
	position: relative;
}

/* Sobrescrever Componente */
.cpnt-select2 [class$="-control"] {
	border-color: #000 !important;
	min-height: 44px !important;
	transition: border 0.1s ease 0s, box-shadow 0.1s ease 0s;
	box-shadow: 0 0 3px transparent;
}
.cpnt-select2 [class$="-control"]:hover {
	border-color: #2596e1 !important;
	box-shadow: 0 0 5px rgba(37, 150, 225, 0.5) !important;
}
.cpnt-select2 [class$="-indicatorContainer"] {
	color: #000 !important;
}
.cpnt-select2 [class$="-indicatorSeparator"] {
	background-color: #000 !important;
}
.cpnt-select2 [class$="-menu"] {
	margin-top: 2px !important;
}
.cpnt-select2 [class$="-option"] {
	font-size: 16px !important;
}
.cpnt-select2 [class$="-singleValue"],
.cpnt-select2 [class$="-placeholder"],
.cpnt-select2 [class$="-Input"] {
	font-size: 14px !important;
}
.cpnt-select2 [class$="-menu"] {
	z-index: 998 !important;
}

/* Multiple */
.cpnt-select2 [class$="-multiValue"] {
	background-color: #f4f4f4 !important;
	border-radius: 2px !important;
	font-size: 15px !important;
	border: 1px solid #CCC !important;
	position: relative;
	z-index: 1;
	font-family: inherit !important;
}
.cpnt-select2 [class$="-ValueContainer"] {
	/* padding: 6px; */
}
.inputtext2-req {
	color: #cf2c2c;
	margin-left: 3px;
}

/* Option Imagem */
.cpnt-select2-wrap {
	display: flex;
}
.cpnt-select2-icone {
	width: 100%;
	height: 100%;
}
.cpnt-select2-icone-wrap {
	width: 22px;
	margin-right: 15px;
	height: 22px;
	display: flex;
}
.cpnt-select2 [class$="-MenuList"] {
	box-sizing: border-box;
	max-width: 100%;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

/* .cpnt-select2 [class$="-option"]:nth-child(even),
.cpnt-select2.cpnt-select2-lista-alt [class$="-MenuList"] [class$="-option"]:nth-child(even),
.cpnt-select2.cpnt-select2-lista-alt [class$="-menu"] [style^="position: absolute; left: 0px;"]:nth-child(even) {
	background-color: #FAFAFA;
} */

.cpnt-select2-customlabel {
	position: absolute;
	left: 10px;
	top: -8px;
	z-index: 10;
	/* background-color: #FFF; */
	font-size: 12px;
	font-weight: bold;
	padding: 0 2px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;
}

/*
** Min
** ================================================== */
.cpnt-select2-min [class$="-control"] {
	min-height: 38px !important;
	height: 38px !important;
}

@media (max-width: 1680px) {
	.cpnt-select2 [class$="-control"] {
		min-height: 38px !important;
	}
}
