.cpnt-tag-box {
	display: inline-flex;
	align-items: center;
	border-radius: 2px;
	background-color: #16191C;
	padding: 3px 7px;
	font-size: 13px;
	color: #FFF;
}
.cpnt-tag-x {
	margin-left: 10px;
}
