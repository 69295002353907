.pg-cadastro-tooltip {
	font-size: 14px;
	width: 350px;
	position: absolute;
}
.pg-cadastro-tooltip {
	position: absolute;
	margin-top: -20px;
}

@media (max-width: 680px) {
	body .cpnt-portal .cadastro-btn-termos {
		position: static;
	}
	.pg-cadastro-termos {
		font-size: 14px;
	}
}
