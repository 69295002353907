@import '@scss/imports.scss';

.container {
	@extend %inline-flex;

	cursor: pointer;
	gap: var(--gap);
	padding: var(--gap-min) var(--gap);
	border-radius: var(--border-radius);
	font-size: var(--fs-default-alt);

	.qtd {
		@extend %inline-flex;

		text-align: center;
		font-size: var(--fs-min);
		border-radius: 50%;
		color: var(--color-1-text);
		background-color: var(--color-1);
		width: 20px;
		height: 20px;
		letter-spacing: -1px;

		@media (max-width: 680px) {
			position: absolute;
			right: -6px;
  			top: 0;
		}
	}
}
