.topbar-sidebar {
	padding-left: 260px;
}
.topbar-inner {
	text-align: center;
	padding: 15px;
	flex: 1;
}
.topbar-inner a {
	text-decoration: none;
	color: inherit;
}
.topbar-wrapper {
	display: flex;
	align-items: center;
}
.topbar-wrapper-x {
}
.topbar-x {
	background: #E74C3C url('./x-branco.png') no-repeat center center;
	background-size: 55% 55%;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

@media (max-width: 1580px) {
	.topbar-sidebar {
		padding-left: 230px;
	}
}

@media (max-width: 1280px) {
	.topbar-sidebar {
		padding-left: 0;
	}
}
