.cpnt-alterarstatusprojeto {
	position: absolute;
	right: 100%;
	margin-right: -5px;
	top: 50%;
	transform: translateY(-50%);
	background: #FAFAFA;
	border-radius: 5px;
	width: 360px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	z-index: 11;
}

/* Conteudo */
.cpnt-alterarstatusprojeto-box {
	padding: 20px 15px 15px;
}
.cpnt-alterarstatusprojeto-box-progresso {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* Posição */
.cpnt-alterarstatusprojeto:before,
.cpnt-alterarstatusprojeto:after {
	content: '';
	border: 10px solid transparent;
}

/* Esquerda seta lado direito */
.cpnt-alterarstatusprojeto-left:after {
	border-left-color: #FAFAFA;
}
.cpnt-alterarstatusprojeto-left:before {
	border-left-color: #D8D8D8;
	margin-right: -1px;
}

.cpnt-alterarstatusprojeto-left:after,
.cpnt-alterarstatusprojeto-left:before {
	position: absolute;
	top: 50%;
	right: -30px;
	margin-top: -10px;
	transform: translateX(-50%);
}
