.container-conteudo {
	padding-left: 260px; // Sidebar
	transition: var(--transition);
}
.container-conteudo-hide {
	padding-left: 0;
}
@media (max-width: 1280px) {
	.container-conteudo {
		padding-left: 0;
	}
}
