.topo {
	background-color: #FFF;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.topo-inner {
	display: flex;
	padding: 20px 0;
	align-items: center;
}
.topo-logo-box {
	max-width: 184px;
}
.topo-menu-box {
	margin-left: auto;
}