@import '@scss/imports.scss';

.container {
	@extend %input-box;

	input,
	textarea {
		@extend %input;
	}

	label {
		font-size: var(--fs-label);
	}

	.req {
		@extend %req;
	}
}
