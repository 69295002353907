/* Reset
** ----------------------------------------------- */
body b,
body strong,
body .bold,
body .strong {
	font-weight: bolder;
}
body small,
body .small {
	font-size: 80%;
}
body big,
body .big {
	font-size: 120%;
}
body sub,
body sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
body sub {
	bottom: -0.25em;
}
body sup {
	top: -0.5em;
}
body audio,
body video {
	display: inline-flex;
}
body audio:not([controls]) {
	display: none;
	height: 0;
}
body img {
	box-sizing: border-box;
	max-width: 100%;
	max-height: 100%;
	height: auto;
}
body svg:not(:root) {
	overflow: hidden;
}
body textarea {
	resize: vertical;
	height: 180px;
}
body canvas {
	display: inline-block;
}

/* Base
** ----------------------------------------------- */
body .base {
	max-width: 1220px;
	margin: auto;
}
body .base-content {
	padding: 0 10px;
}

/* Position
** ----------------------------------------------- */
body .relative {
	position: relative;
}
body .absolute {
	position: absolute;
}
body .fixed {
	position: fixed;
}
body .static {
	position: static;
}
body .sticky {
	position: sticky;
}

/* Table Class
** ----------------------------------------------- */
body .table {
	display: table;
}
body .table-fixed {
	table-layout: fixed;
}
body .td {
	display: table-cell;
}
body .tr {
	display: table-row;
}
body .bottom {
	vertical-align: bottom;
}
body .top {
	vertical-align: top;
}
body .middle {
	vertical-align: middle;
}

/* Table
** ----------------------------------------------- */
body caption {
	text-align: inherit;
}
body table {
	border-collapse: collapse;
	table-layout: fixed;
}
body th {
	font-weight: initial;
}

/* Width PX
** ----------------------------------------------- */
body .w-inherit {
	width: inherit;
}
body .w-0 {
	width: 0;
}
body .w-1 {
	width: 1px;
}
body .w-2 {
	width: 2px;
}
body .w-3 {
	width: 3px;
}
body .w-4 {
	width: 4px;
}
body .w-5 {
	width: 5px;
}
body .w-10 {
	width: 10px;
}
body .w-15 {
	width: 15px;
}
body .w-20 {
	width: 20px;
}
body .w-25 {
	width: 25px;
}
body .w-30 {
	width: 30px;
}
body .w-40 {
	width: 40px;
}
body .w-50 {
	width: 50px;
}
body .w-60 {
	width: 60px;
}
body .w-70 {
	width: 70px;
}
body .w-80 {
	width: 80px;
}
body .w-90 {
	width: 90px;
}
body .w-100 {
	width: 100px;
}
body .w-110 {
	width: 110px;
}
body .w-120 {
	width: 120px;
}
body .w-130 {
	width: 130px;
}
body .w-140 {
	width: 140px;
}
body .w-150 {
	width: 150px;
}
body .w-160 {
	width: 160px;
}
body .w-170 {
	width: 170px;
}
body .w-180 {
	width: 180px;
}
body .w-190 {
	width: 190px;
}
body .w-200 {
	width: 200px;
}
body .w-210 {
	width: 210px;
}
body .w-220 {
	width: 220px;
}
body .w-230 {
	width: 230px;
}
body .w-240 {
	width: 240px;
}
body .w-250 {
	width: 250px;
}
body .w-260 {
	width: 260px;
}
body .w-270 {
	width: 270px;
}
body .w-280 {
	width: 280px;
}
body .w-290 {
	width: 290px;
}
body .w-300 {
	width: 300px;
}
body .w-350 {
	width: 350px;
}
body .w-400 {
	width: 400px;
}
body .w-450 {
	width: 450px;
}
body .w-500 {
	width: 500px;
}
body .w-600 {
	width: 600px;
}
body .w-700 {
	width: 700px;
}
body .w-750 {
	width: 750px;
}
body .w-800 {
	width: 800px;
}
body .w-850 {
	width: 850px;
}
body .w-900 {
	width: 900px;
}
body .w-950 {
	width: 950px;
}
body .w-1000 {
	width: 1000px;
}
body .w-1100 {
	width: 1100px;
}
body .w-1200 {
	width: 1200px;
}
body .w-auto {
	width: auto;
}

/* Width Porcentagem
** ----------------------------------------------- */
body .w-5p {
	width: 5%;
}
body .w-8p {
	width: 8.333333%;
}
body .w-10p {
	width: 10%;
}
body .w-12-5p {
	width: 12.5%;
}
body .w-15p {
	width: 15%;
}
body .w-16p {
	width: 16.666667%;
}
body .w-20p {
	width: 20%;
}
body .w-25p {
	width: 25%;
}
body .w-30p {
	width: 30%;
}
body .w-33p {
	width: 33.333333%;
}
body .w-35p {
	width: 35%;
}
body .w-37p {
	width: 37.5%;
}
body .w-40p {
	width: 40%;
}
body .w-41p {
	width: 41.666667%;
}
body .w-45p {
	width: 45%;
}
body .w-50p {
	width: 50%;
}
body .w-55p {
	width: 55%;
}
body .w-58p {
	width: 58.333333%;
}
body .w-60p {
	width: 60%;
}
body .w-65p {
	width: 65%;
}
body .w-66p {
	width: 66.666667%;
}
body .w-70p {
	width: 70%;
}
body .w-75p {
	width: 75%;
}
body .w-80p {
	width: 80%;
}
body .w-83p {
	width: 83.333333%;
}
body .w-85p {
	width: 85%;
}
body .w-90p {
	width: 90%;
}
body .w-91p {
	width: 91.666667%;
}
body .w-95p {
	width: 95%;
}
body .w-100p {
	width: 100%;
}
body .w-200p {
	width: 200%;
}

/* Width VW
** ----------------------------------------------- */
body .w-80vw {
	width: 80vw;
}
body .w-90vw {
	width: 90vw;
}
body .w-100vw {
	width: 100vw;
}

/* Min Width
** ----------------------------------------------- */
body .min-w-0 {
	min-width: 0;
}
body .min-w-100 {
	min-width: 100px;
}
body .min-w-150 {
	min-width: 150px;
}
body .min-w-200 {
	min-width: 200px;
}
body .min-w-250 {
	min-width: 250px;
}
body .min-w-300 {
	min-width: 300px;
}
body .min-w-350 {
	min-width: 350px;
}
body .min-w-400 {
	min-width: 400px;
}
body .min-w-450 {
	min-width: 450px;
}
body .min-w-500 {
	min-width: 500px;
}
body .min-w-100p {
	min-width: 100%;
}

/* Min Width Percentage
** ----------------------------------------------- */
body .min-w-80p {
	min-width: 80%;
}
body .min-w-90p {
	min-width: 90%;
}
body .min-w-100p {
	min-width: 100%;
}

/* Min Width VW
** ----------------------------------------------- */
body .min-w-80vw {
	min-width: 80vw;
}
body .min-w-90vw {
	min-width: 90vw;
}
body .min-w-100vw {
	min-width: 100vw;
}

/* Max Width
** ----------------------------------------------- */
body .max-w-5 {
	max-width: 5px;
}
body .max-w-10 {
	max-width: 10px;
}
body .max-w-15 {
	max-width: 15px;
}
body .max-w-20 {
	max-width: 20px;
}
body .max-w-25 {
	max-width: 25px;
}
body .max-w-30 {
	max-width: 30px;
}
body .max-w-40 {
	max-width: 40px;
}
body .max-w-50 {
	max-width: 50px;
}
body .max-w-60 {
	max-width: 60px;
}
body .max-w-70 {
	max-width: 70px;
}
body .max-w-80 {
	max-width: 80px;
}
body .max-w-90 {
	max-width: 90px;
}
body .max-w-100 {
	max-width: 100px;
}
body .max-w-150 {
	max-width: 150px;
}
body .max-w-200 {
	max-width: 200px;
}
body .max-w-250 {
	max-width: 250px;
}
body .max-w-300 {
	max-width: 300px;
}
body .max-w-350 {
	max-width: 350px;
}
body .max-w-400 {
	max-width: 400px;
}
body .max-w-450 {
	max-width: 450px;
}
body .max-w-500 {
	max-width: 500px;
}
body .max-w-600 {
	max-width: 600px;
}
body .max-w-700 {
	max-width: 700px;
}
body .max-w-800 {
	max-width: 800px;
}
body .max-w-900 {
	max-width: 900px;
}
body .max-w-1000 {
	max-width: 1000px;
}

/* Max Width
** ----------------------------------------------- */
body .max-w-100p {
	max-width: 100%;
}
body .max-w-90p {
	max-width: 90%;
}
body .max-w-80p {
	max-width: 80%;
}
body .max-w-70p {
	max-width: 70%;
}
body .max-w-60p {
	max-width: 60%;
}
body .max-w-50p {
	max-width: 50%;
}

/* Max Width VW
** ----------------------------------------------- */
body .max-w-80vw {
	max-width: 80vw;
}
body .max-w-90vw {
	max-width: 90vw;
}
body .max-w-100vw {
	max-width: 100vw;
}

/*
** Boxes
** ----------------------------------------------- */
body .box {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
}
body .box > [class*="box-"] {
	box-sizing: border-box;
}
body .box-1 {
	min-width: 100%;
	max-width: 100%;
}
body .box-2 {
	min-width: 50%;
	max-width: 50%;
}
body .box-3 {
	min-width: 33.3333%;
	max-width: 33.3333%;
}
body .box-4 {
	min-width: 25%;
	max-width: 25%;
}
body .box-5 {
	min-width: 20%;
	max-width: 20%;
}

/* Height PX
** ----------------------------------------------- */
body .h-inherit {
	height: inherit;
}
body .h-0 {
	height: 0;
}
body .h-1 {
	height: 1px;
}
body .h-2 {
	height: 2px;
}
body .h-3 {
	height: 3px;
}
body .h-4 {
	height: 4px;
}
body .h-5 {
	height: 5px;
}
body .h-10 {
	height: 10px;
}
body .h-15 {
	height: 15px;
}
body .h-20 {
	height: 20px;
}
body .h-25 {
	height: 25px;
}
body .h-30 {
	height: 30px;
}
body .h-40 {
	height: 40px;
}
body .h-50 {
	height: 50px;
}
body .h-60 {
	height: 60px;
}
body .h-70 {
	height: 70px;
}
body .h-80 {
	height: 80px;
}
body .h-90 {
	height: 90px;
}
body .h-100 {
	height: 100px;
}
body .h-130 {
	height: 130px;
}
body .h-150 {
	height: 150px;
}
body .h-160 {
	height: 160px;
}
body .h-200 {
	height: 200px;
}
body .h-250 {
	height: 250px;
}
body .h-300 {
	height: 300px;
}
body .h-350 {
	height: 350px;
}
body .h-400 {
	height: 400px;
}
body .h-450 {
	height: 450px;
}
body .h-500 {
	height: 500px;
}
body .h-600 {
	height: 600px;
}
body .h-700 {
	height: 700px;
}
body .h-800 {
	height: 800px;
}
body .h-900 {
	height: 900px;
}
body .h-1000 {
	height: 1000px;
}
body .h-auto {
	height: auto;
}

/* Height Percentage
** ----------------------------------------------- */
body .h-80p {
	height: 80%;
}
body .h-90p {
	height: 90%;
}
body .h-100p {
	height: 100%;
}

/* Height VH
** ----------------------------------------------- */
body .h-80vh {
	height: 80vh;
}
body .h-90vh {
	height: 90vh;
}
body .h-100vh {
	height: 100vh;
}

/* Max Height PX
** ----------------------------------------------- */
body .max-h-50 {
	max-height: 50px;
}
body .max-h-100 {
	max-height: 100px;
}
body .max-h-150 {
	max-height: 150px;
}
body .max-h-200 {
	max-height: 200px;
}
body .max-h-250 {
	max-height: 250px;
}
body .max-h-300 {
	max-height: 300px;
}
body .max-h-350 {
	max-height: 350px;
}
body .max-h-400 {
	max-height: 400px;
}
body .max-h-450 {
	max-height: 450px;
}
body .max-h-500 {
	max-height: 500px;
}

/* Max Height Percent
** ----------------------------------------------- */
body .max-h-100p {
	max-height: 100%;
}
body .max-h-90p {
	max-height: 90%;
}
body .max-h-80p {
	max-height: 80%;
}
body .max-h-70p {
	max-height: 70%;
}
body .max-h-60p {
	max-height: 60%;
}
body .max-h-50p {
	max-height: 50%;
}

/* Max Height VH
** ----------------------------------------------- */
body .max-h-80vh {
	max-height: 80vh;
}
body .max-h-90vh {
	max-height: 90vh;
}
body .max-h-100vh {
	max-height: 100vh;
}

/* Top
** ----------------------------------------------- */
body .top-inherit {
	top: inherit;
}
body .top-0 {
	top: 0px;
}
body .top-1 {
	top: 1px;
}
body .top-2 {
	top: 2px;
}
body .top-3 {
	top: 3px;
}
body .top-4 {
	top: 4px;
}
body .top-5 {
	top: 5px;
}
body .top-10 {
	top: 10px;
}
body .top-15 {
	top: 15px;
}
body .top-20 {
	top: 20px;
}
body .top-25 {
	top: 25px;
}
body .top-30 {
	top: 30px;
}
body .top-auto {
	top: auto;
}

/* Bottom
** ----------------------------------------------- */
body .bottom-inherit {
	bottom: inherit;
}
body .bottom-0 {
	bottom: 0;
}
body .bottom-1 {
	bottom: 1px;
}
body .bottom-2 {
	bottom: 2px;
}
body .bottom-3 {
	bottom: 3px;
}
body .bottom-4 {
	bottom: 4px;
}
body .bottom-5 {
	bottom: 5px;
}
body .bottom-10 {
	bottom: 10px;
}
body .bottom-15 {
	bottom: 15px;
}
body .bottom-20 {
	bottom: 20px;
}
body .bottom-25 {
	bottom: 25px;
}
body .bottom-30 {
	bottom: 30px;
}
body .bottom-auto {
	bottom: auto;
}

/* Left
** ----------------------------------------------- */
body .left-inherit {
	left: inherit;
}
body .left-0 {
	left: 0px;
}
body .left-1 {
	left: 1px;
}
body .left-2 {
	left: 2px;
}
body .left-3 {
	left: 3px;
}
body .left-4 {
	left: 4px;
}
body .left-5 {
	left: 5px;
}
body .left-10 {
	left: 10px;
}
body .left-15 {
	left: 15px;
}
body .left-20 {
	left: 20px;
}
body .left-25 {
	left: 25px;
}
body .left-30 {
	left: 30px;
}
body .left-auto {
	left: auto;
}

/* Right
** ----------------------------------------------- */
body .right-inherit {
	right: inherit;
}
body .right-0 {
	right: 0px;
}
body .right-1 {
	right: 1px;
}
body .right-2 {
	right: 2px;
}
body .right-3 {
	right: 3px;
}
body .right-4 {
	right: 4px;
}
body .right-5 {
	right: 5px;
}
body .right-10 {
	right: 10px;
}
body .right-15 {
	right: 15px;
}
body .right-20 {
	right: 20px;
}
body .right-25 {
	right: 25px;
}
body .right-30 {
	right: 30px;
}
body .right-auto {
	right: auto;
}

/* Top
** ----------------------------------------------- */
body .top--1 {
	top: -1px;
}
body .top--2 {
	top: -2px;
}
body .top--3 {
	top: -3px;
}
body .top--4 {
	top: -4px;
}
body .top--5 {
	top: -5px;
}
body .top--6 {
	top: -6px;
}
body .top--7 {
	top: -7px;
}
body .top--8 {
	top: -8px;
}
body .top--9 {
	top: -9px;
}
body .top--10 {
	top: -10px;
}
body .top--15 {
	top: -15px;
}
body .top--20 {
	top: -20px;
}
body .top--25 {
	top: -25px;
}
body .top--30 {
	top: -30px;
}

/* Bottom
** ----------------------------------------------- */
body .bottom--1 {
	bottom: -1px;
}
body .bottom--2 {
	bottom: -2px;
}
body .bottom--3 {
	bottom: -3px;
}
body .bottom--4 {
	bottom: -4px;
}
body .bottom--5 {
	bottom: -5px;
}
body .bottom--6 {
	bottom: -6px;
}
body .bottom--7 {
	bottom: -7px;
}
body .bottom--8 {
	bottom: -8px;
}
body .bottom--9 {
	bottom: -9px;
}
body .bottom--10 {
	bottom: -10px;
}
body .bottom--15 {
	bottom: -15px;
}
body .bottom--20 {
	bottom: -20px;
}
body .bottom--25 {
	bottom: -25px;
}
body .bottom--30 {
	bottom: -30px;
}

/* Left
** ----------------------------------------------- */
body .left--1 {
	left: -1px;
}
body .left--2 {
	left: -2px;
}
body .left--3 {
	left: -3px;
}
body .left--4 {
	left: -4px;
}
body .left--5 {
	left: -5px;
}
body .left--6 {
	left: -6px;
}
body .left--7 {
	left: -7px;
}
body .left--8 {
	left: -8px;
}
body .left--9 {
	left: -9px;
}
body .left--10 {
	left: -10px;
}
body .left--15 {
	left: -15px;
}
body .left--20 {
	left: -20px;
}
body .left--25 {
	left: -25px;
}
body .left--30 {
	left: -30px;
}

/* Right
** ----------------------------------------------- */
body .right--1 {
	right: -1px;
}
body .right--2 {
	right: -2px;
}
body .right--3 {
	right: -3px;
}
body .right--4 {
	right: -4px;
}
body .right--5 {
	right: -5px;
}
body .right--6 {
	right: -6px;
}
body .right--7 {
	right: -7px;
}
body .right--8 {
	right: -8px;
}
body .right--9 {
	right: -9px;
}
body .right--10 {
	right: -10px;
}
body .right--15 {
	right: -15px;
}
body .right--20 {
	right: -20px;
}
body .right--25 {
	right: -25px;
}
body .right--30 {
	right: -30px;
}


/* All
** ----------------------------------------------- */
body .all--1 {
	left: -1px;
	right: -1px;
	top: -1px;
	bottom: -1px;
}
body .all--2 {
	left: -2px;
	right: -2px;
	top: -2px;
	bottom: -2px;
}
body .all--3 {
	left: -3px;
	right: -3px;
	top: -3px;
	bottom: -3px;
}
body .all--4 {
	left: -4px;
	right: -4px;
	top: -4px;
	bottom: -4px;
}
body .all--5 {
	left: -5px;
	right: -5px;
	top: -5px;
	bottom: -5px;
}


/* Opacity
** ----------------------------------------------- */
body .opct {
	opacity: 1;
}
body .opct-0 {
	opacity: 0;
}
body .opct-1 {
	opacity: 0.1;
}
body .opct-2 {
	opacity: 0.2;
}
body .opct-3 {
	opacity: 0.3;
}
body .opct-4 {
	opacity: 0.4;
}
body .opct-5 {
	opacity: 0.5;
}
body .opct-6 {
	opacity: 0.6;
}
body .opct-7 {
	opacity: 0.7;
}
body .opct-8 {
	opacity: 0.8;
}
body .opct-9 {
	opacity: 0.9;
}

/* Visibility
** ----------------------------------------------- */
body .visibility-0 {
	visibility: hidden;
}
body .visibility-1 {
	visibility: visible;
}

/* Font
** ----------------------------------------------- */
body .ff-sans-serif {
	font-family: sans-serif;
}
body .ff-default {
	font-family: Arial, Helvetica, sans-serif;
}
body .ff-monospace {
	font-family: monospace;
}

/* Font Size EM
** ----------------------------------------------- */
body .fs-x1 {
	font-size: 1em;
}
body .fs-x15 {
	font-size: 1.5em;
}
body .fs-x2 {
	font-size: 2em;
}
body .fs-x25 {
	font-size: 2.5em;
}
body .fs-x3 {
	font-size: 3em;
}
body .fs-x4 {
	font-size: 4em;
}
body .fs-x5 {
	font-size: 5em;
}
body .fs-x6 {
	font-size: 6em;
}
body .fs-x7 {
	font-size: 7em;
}
body .fs-x8 {
	font-size: 8em;
}
body .fs-x9 {
	font-size: 9em;
}
body .fs-x10 {
	font-size: 10em;
}
body .fs-x095 {
	font-size: 0.95em;
}
body .fs-x09 {
	font-size: 0.9em;
}
body .fs-x085 {
	font-size: 0.85em;
}
body .fs-x08 {
	font-size: 0.8em;
}

/* Font Size PX
** ----------------------------------------------- */
body .fs-8 {
	font-size: 8px;
}
body .fs-9 {
	font-size: 9px;
}
body .fs-10 {
	font-size: 10px;
}
body .fs-11 {
	font-size: 11px;
}
body .fs-12 {
	font-size: 12px;
}
body .fs-13 {
	font-size: 13px;
}
body .fs-14 {
	font-size: 14px;
}
body .fs-15 {
	font-size: 15px;
}
body .fs-16 {
	font-size: 16px;
}
body .fs-17 {
	font-size: 17px;
}
body .fs-18 {
	font-size: 18px;
}
body .fs-19 {
	font-size: 19px;
}
body .fs-20 {
	font-size: 20px;
}
body .fs-21 {
	font-size: 21px;
}
body .fs-22 {
	font-size: 22px;
}
body .fs-23 {
	font-size: 23px;
}
body .fs-24 {
	font-size: 24px;
}
body .fs-25 {
	font-size: 25px;
}
body .fs-26 {
	font-size: 26px;
}

/* Text Format
** ----------------------------------------------- */
body .text-one-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
body .pre {
	white-space: pre;
}
body .pre-wrap {
	white-space: pre-wrap;
}
body .word-break {
	word-wrap: break-word;
}
body .no-wrap {
	white-space: nowrap;
}
body .no-wrap-line {
	white-space: pre-line;
}
body .fw-normal {
	font-weight: normal;
}
body .italic {
	font-style: italic;
}
body .uppercase {
	text-transform: uppercase;
}
body .lowercase {
	text-transform: lowercase;
}
body .capitalize {
	text-transform: capitalize;
}
body .no-case {
	text-transform: initial;
}
body .color-inherit {
	color: inherit;
}
body .no-decoration,
body a.no-decoration,
body .decoration-none,
body .no-underline,
body .no-underline a,
body .decoration-none a,
body a.decoration-none,
body a.no-underline {
	text-decoration: none;
}
body .underline {
	border-bottom: 1px solid;
}
body .underline-dotted {
	border-bottom: 1px dotted;
}
body .list-style-none {
	list-style: none;
}
body .text-shadow {
	text-shadow: 1px 1px rgba(0,0,0,0.3);
}
body .text-shadow-white {
	text-shadow: 1px 1px rgba(255,255,255,0.3);
}

/* Line Height
** ----------------------------------------------- */
body .lh-1 {
	line-height: 1;
}
body .lh-1-1 {
	line-height: 1.1;
}
body .lh-1-2 {
	line-height: 1.2;
}
body .lh-1-3 {
	line-height: 1.3;
}
body .lh-1-4 {
	line-height: 1.4;
}
body .lh-1-5 {
	line-height: 1.5;
}
body .lh-1-6 {
	line-height: 1.6;
}
body .lh-1-7 {
	line-height: 1.7;
}
body .lh-1-8 {
	line-height: 1.8;
}
body .lh-1-9 {
	line-height: 1.9;
}
body .lh-2 {
	line-height: 2;
}
body .lh-3 {
	line-height: 3;
}
body .lh-4 {
	line-height: 4;
}
body .lh-5 {
	line-height: 5;
}
body .lh-10 {
	line-height: 10px;
}
body .lh-15 {
	line-height: 15px;
}
body .lh-20 {
	line-height: 20px;
}
body .lh-25 {
	line-height: 25px;
}
body .lh-30 {
	line-height: 30px;
}

/* Letter Spacing
** ----------------------------------------------- */
body .ls-1 {
    letter-spacing: 1px;
}
body .ls-2 {
    letter-spacing: 2px;
}
body .ls-3 {
    letter-spacing: 3px;
}

/* Align
** ----------------------------------------------- */
body .align-justify {
	text-align: justify;
}
body .align-left {
	text-align: left;
}
body .align-right {
	text-align: right;
}
body .align-center {
	text-align: center;
}
body .center {
	display: table;
	margin-left: auto;
	margin-right: auto;
}
body .center-h-abs {
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
}
body .center-v-abs {
	position: absolute;
	transform: translateY(-51%);
	top: 50%;
}

/* Float
** ----------------------------------------------- */
body .float-left {
	float: left;
}
body .float-right {
	float: right;
}

/* Padding
** ----------------------------------------------- */
body .padding-0 {
	padding: 0;
}
body .padding-1 {
	padding: 1px;
}
body .padding-2 {
	padding: 2px;
}
body .padding-3 {
	padding: 3px;
}
body .padding-4 {
	padding: 4px;
}
body .padding-5 {
	padding: 5px;
}
body .padding-6 {
	padding: 6px;
}
body .padding-7 {
	padding: 7px;
}
body .padding-8 {
	padding: 8px;
}
body .padding-9 {
	padding: 9px;
}
body .padding-10 {
	padding: 10px;
}
body .padding-15 {
	padding: 15px;
}
body .padding-20 {
	padding: 20px;
}
body .padding-25 {
	padding: 25px;
}
body .padding-30 {
	padding: 30px;
}
body .padding-40 {
	padding: 40px;
}
body .padding-50 {
	padding: 50px;
}
body .padding-100 {
	padding: 100px;
}
body .padding-150 {
	padding: 150px;
}

/* Padding Horizontal
** ----------------------------------------------- */
body .padding-h-1 {
	padding-left: 1px;
	padding-right: 1px;
}
body .padding-h-2 {
	padding-left: 2px;
	padding-right: 2px;
}
body .padding-h-3 {
	padding-left: 3px;
	padding-right: 3px;
}
body .padding-h-4 {
	padding-left: 4px;
	padding-right: 4px;
}
body .padding-h-5 {
	padding-left: 5px;
	padding-right: 5px;
}
body .padding-h-6 {
	padding-left: 6px;
	padding-right: 6px;
}
body .padding-h-7 {
	padding-left: 7px;
	padding-right: 7px;
}
body .padding-h-8 {
	padding-left: 8px;
	padding-right: 8px;
}
body .padding-h-9 {
	padding-left: 9px;
	padding-right: 9px;
}
body .padding-h-10 {
	padding-left: 10px;
	padding-right: 10px;
}
body .padding-h-15 {
	padding-left: 15px;
	padding-right: 15px;
}
body .padding-h-20 {
	padding-left: 20px;
	padding-right: 20px;
}
body .padding-h-25 {
	padding-left: 25px;
	padding-right: 25px;
}
body .padding-h-30 {
	padding-left: 30px;
	padding-right: 30px;
}

/* Padding Vertical
** ----------------------------------------------- */
body .padding-v-0 {
	padding-top: 0;
	padding-bottom: 0;
}
body .padding-v-1 {
	padding-top: 1px;
	padding-bottom: 1px;
}
body .padding-v-2 {
	padding-top: 2px;
	padding-bottom: 2px;
}
body .padding-v-3 {
	padding-top: 3px;
	padding-bottom: 3px;
}
body .padding-v-4 {
	padding-top: 4px;
	padding-bottom: 4px;
}
body .padding-v-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}
body .padding-v-6 {
	padding-top: 6px;
	padding-bottom: 6px;
}
body .padding-v-7 {
	padding-top: 7px;
	padding-bottom: 7px;
}
body .padding-v-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}
body .padding-v-9 {
	padding-top: 9px;
	padding-bottom: 9px;
}
body .padding-v-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}
body .padding-v-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}
body .padding-v-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}
body .padding-v-25 {
	padding-top: 25px;
	padding-bottom: 25px;
}
body .padding-v-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}
body .padding-v-40 {
	padding-top: 40px;
	padding-bottom: 40px;
}
body .padding-v-50 {
	padding-top: 50px;
	padding-bottom: 50px;
}
body .padding-v-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}
body .padding-v-150 {
	padding-top: 150px;
	padding-bottom: 150px;
}

/* Padding Left
** ----------------------------------------------- */
body .padding-l-0 {
	padding-left: 0;
}
body .padding-l-1 {
	padding-left: 1px;
}
body .padding-l-2 {
	padding-left: 2px;
}
body .padding-l-3 {
	padding-left: 3px;
}
body .padding-l-4 {
	padding-left: 4px;
}
body .padding-l-5 {
	padding-left: 5px;
}
body .padding-l-6 {
	padding-left: 6px;
}
body .padding-l-7 {
	padding-left: 7px;
}
body .padding-l-8 {
	padding-left: 8px;
}
body .padding-l-9 {
	padding-left: 9px;
}
body .padding-l-10 {
	padding-left: 10px;
}
body .padding-l-15 {
	padding-left: 15px;
}
body .padding-l-20 {
	padding-left: 20px;
}
body .padding-l-25 {
	padding-left: 25px;
}
body .padding-l-30 {
	padding-left: 30px;
}
body .padding-l-40 {
	padding-left: 40px;
}
body .padding-l-50 {
	padding-left: 50px;
}
body .padding-l-100 {
	padding-left: 100px;
}

/* Padding Right
** ----------------------------------------------- */
body .padding-r-0 {
	padding-right: 0;
}
body .padding-r-1 {
	padding-right: 1px;
}
body .padding-r-2 {
	padding-right: 2px;
}
body .padding-r-3 {
	padding-right: 3px;
}
body .padding-r-4 {
	padding-right: 4px;
}
body .padding-r-5 {
	padding-right: 5px;
}
body .padding-r-6 {
	padding-right: 6px;
}
body .padding-r-7 {
	padding-right: 7px;
}
body .padding-r-8 {
	padding-right: 8px;
}
body .padding-r-9 {
	padding-right: 9px;
}
body .padding-r-10 {
	padding-right: 10px;
}
body .padding-r-15 {
	padding-right: 15px;
}
body .padding-r-20 {
	padding-right: 20px;
}
body .padding-r-25 {
	padding-right: 25px;
}
body .padding-r-30 {
	padding-right: 30px;
}

/* Padding Top
** ----------------------------------------------- */
body .padding-t-0 {
	padding-top: 0;
}
body .padding-t-1 {
	padding-top: 1px;
}
body .padding-t-2 {
	padding-top: 2px;
}
body .padding-t-3 {
	padding-top: 3px;
}
body .padding-t-4 {
	padding-top: 4px;
}
body .padding-t-5 {
	padding-top: 5px;
}
body .padding-t-6 {
	padding-top: 6px;
}
body .padding-t-7 {
	padding-top: 7px;
}
body .padding-t-8 {
	padding-top: 8px;
}
body .padding-t-9 {
	padding-top: 9px;
}
body .padding-t-10 {
	padding-top: 10px;
}
body .padding-t-15 {
	padding-top: 15px;
}
body .padding-t-20 {
	padding-top: 20px;
}
body .padding-t-25 {
	padding-top: 25px;
}
body .padding-t-30 {
	padding-top: 30px;
}
body .padding-t-40 {
	padding-top: 40px;
}
body .padding-t-50 {
	padding-top: 50px;
}
body .padding-t-100 {
	padding-top: 100px;
}
body .padding-t-150 {
	padding-top: 150px;
}

/* Padding Bottom
** ----------------------------------------------- */
body .padding-b-0 {
	padding-bottom: 0
}
body .padding-b-1 {
	padding-bottom: 1px;
}
body .padding-b-2 {
	padding-bottom: 2px;
}
body .padding-b-3 {
	padding-bottom: 3px;
}
body .padding-b-4 {
	padding-bottom: 4px;
}
body .padding-b-5 {
	padding-bottom: 5px;
}
body .padding-b-6 {
	padding-bottom: 6px;
}
body .padding-b-7 {
	padding-bottom: 7px;
}
body .padding-b-8 {
	padding-bottom: 8px;
}
body .padding-b-9 {
	padding-bottom: 9px;
}
body .padding-b-10 {
	padding-bottom: 10px;
}
body .padding-b-15 {
	padding-bottom: 15px;
}
body .padding-b-20 {
	padding-bottom: 20px;
}
body .padding-b-25 {
	padding-bottom: 25px;
}
body .padding-b-30 {
	padding-bottom: 30px;
}
body .padding-b-40 {
	padding-bottom: 40px;
}
body .padding-b-50 {
	padding-bottom: 50px;
}
body .padding-b-100 {
	padding-bottom: 100px;
}
body .padding-b-150 {
	padding-bottom: 150px;
}

/*
** Wrap
** ----------------------------------------------- */
body .wrap {
	margin: -5px;
}
body .wrap-1 {
	margin: -1px;
}
body .wrap-2 {
	margin: -2px;
}
body .wrap-3 {
	margin: -3px;
}
body .wrap-4 {
	margin: -4px;
}
body .wrap-5 {
	margin: -5px;
}
body .wrap-10 {
	margin: -10px;
}
body .wrap-15 {
	margin: -15px;
}
body .wrap-20 {
	margin: -20px;
}

body .wrap-h-5 {
	margin-left: -5px;
	margin-right: -5px;
}
body .wrap-h-10 {
	margin-left: -10px;
	margin-right: -10px;
}
body .wrap-h-15 {
	margin-left: -15px;
	margin-right: -15px;
}
body .wrap-h-20 {
	margin-left: -20px;
	margin-right: -20px;
}

body .wrap-v {
	margin-top: -15px;
	margin-bottom: -15px;
}

/* Margin
** ----------------------------------------------- */
body .margin-0 {
	margin: 0;
}
body .margin-1 {
	margin: 1px;
}
body .margin-2 {
	margin: 2px;
}
body .margin-3 {
	margin: 3px;
}
body .margin-4 {
	margin: 4px;
}
body .margin-5 {
	margin: 5px;
}
body .margin-6 {
	margin: 6px;
}
body .margin-7 {
	margin: 7px;
}
body .margin-8 {
	margin: 8px;
}
body .margin-9 {
	margin: 9px;
}
body .margin-10 {
	margin: 10px;
}
body .margin-15 {
	margin: 15px;
}
body .margin-20 {
	margin: 20px;
}
body .margin-25 {
	margin: 25px;
}
body .margin-30 {
	margin: 30px;
}
body .margin-40 {
	margin: 40px;
}
body .margin-50 {
	margin: 50px;
}
body .margin-100 {
	margin: 100px;
}
body .margin-150 {
	margin: 150px;
}

/* Margin Horizontal
** ----------------------------------------------- */
body .margin-h-0 {
	margin-left: 0px;
	margin-right: 0px;
}
body .margin-h-1 {
	margin-left: 1px;
	margin-right: 1px;
}
body .margin-h-2 {
	margin-left: 2px;
	margin-right: 2px;
}
body .margin-h-3 {
	margin-left: 3px;
	margin-right: 3px;
}
body .margin-h-4 {
	margin-left: 4px;
	margin-right: 4px;
}
body .margin-h-5 {
	margin-left: 5px;
	margin-right: 5px;
}
body .margin-h-6 {
	margin-left: 6px;
	margin-right: 6px;
}
body .margin-h-7 {
	margin-left: 7px;
	margin-right: 7px;
}
body .margin-h-8 {
	margin-left: 8px;
	margin-right: 8px;
}
body .margin-h-9 {
	margin-left: 9px;
	margin-right: 9px;
}
body .margin-h-10 {
	margin-left: 10px;
	margin-right: 10px;
}
body .margin-h-15 {
	margin-left: 15px;
	margin-right: 15px;
}
body .margin-h-20 {
	margin-left: 20px;
	margin-right: 20px;
}
body .margin-h-25 {
	margin-left: 25px;
	margin-right: 25px;
}
body .margin-h-30 {
	margin-left: 30px;
	margin-right: 30px;
}

/* Margin Vertical
** ----------------------------------------------- */
body .margin-v-0 {
	margin-top: 0;
	margin-bottom: 0;
}
body .margin-v-1 {
	margin-top: 1px;
	margin-bottom: 1px;
}
body .margin-v-2 {
	margin-top: 2px;
	margin-bottom: 2px;
}
body .margin-v-3 {
	margin-top: 3px;
	margin-bottom: 3px;
}
body .margin-v-4 {
	margin-top: 4px;
	margin-bottom: 4px;
}
body .margin-v-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}
body .margin-v-6 {
	margin-top: 6px;
	margin-bottom: 6px;
}
body .margin-v-7 {
	margin-top: 7px;
	margin-bottom: 7px;
}
body .margin-v-8 {
	margin-top: 8px;
	margin-bottom: 8px;
}
body .margin-v-9 {
	margin-top: 9px;
	margin-bottom: 9px;
}
body .margin-v-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}
body .margin-v-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}
body .margin-v-20 {
	margin-top: 20px;
	margin-bottom: 20px;
}
body .margin-v-25 {
	margin-top: 25px;
	margin-bottom: 25px;
}
body .margin-v-30 {
	margin-top: 30px;
	margin-bottom: 30px;
}
body .margin-v-40 {
	margin-top: 40px;
	margin-bottom: 40px;
}
body .margin-v-50 {
	margin-top: 50px;
	margin-bottom: 50px;
}
body .margin-v-100 {
	margin-top: 100px;
	margin-bottom: 100px;
}
body .margin-v-150 {
	margin-top: 150px;
	margin-bottom: 150px;
}

/* Negative Margins Horizontal
** ----------------------------------------------- */
body .margin-h--1 {
	margin-left: -1px;
	margin-right: -1px;
}
body .margin-h--2 {
	margin-left: -2px;
	margin-right: -2px;
}
body .margin-h--3 {
	margin-left: -3px;
	margin-right: -3px;
}
body .margin-h--4 {
	margin-left: -4px;
	margin-right: -4px;
}
body .margin-h--5 {
	margin-left: -5px;
	margin-right: -5px;
}
body .margin-h--6 {
	margin-left: -6px;
	margin-right: -6px;
}
body .margin-h--7 {
	margin-left: -7px;
	margin-right: -7px;
}
body .margin-h--8 {
	margin-left: -8px;
	margin-right: -8px;
}
body .margin-h--9 {
	margin-left: -9px;
	margin-right: -9px;
}
body .margin-h--10 {
	margin-left: -10px;
	margin-right: -10px;
}
body .margin-h--15 {
	margin-left: -15px;
	margin-right: -15px;
}
body .margin-h--20 {
	margin-left: -20px;
	margin-right: -20px;
}
body .margin-h--25 {
	margin-left: -25px;
	margin-right: -25px;
}
body .margin-h--30 {
	margin-left: -30px;
	margin-right: -30px;
}

/* Negative Margins Vertical
** ----------------------------------------------- */
body .margin-v--1 {
	margin-top: -1px;
	margin-bottom: -1px;
}
body .margin-v--2 {
	margin-top: -2px;
	margin-bottom: -2px;
}
body .margin-v--3 {
	margin-top: -3px;
	margin-bottom: -3px;
}
body .margin-v--4 {
	margin-top: -4px;
	margin-bottom: -4px;
}
body .margin-v--5 {
	margin-top: -5px;
	margin-bottom: -5px;
}
body .margin-v--6 {
	margin-top: -6px;
	margin-bottom: -6px;
}
body .margin-v--7 {
	margin-top: -7px;
	margin-bottom: -7px;
}
body .margin-v--8 {
	margin-top: -8px;
	margin-bottom: -8px;
}
body .margin-v--9 {
	margin-top: -9px;
	margin-bottom: -9px;
}
body .margin-v--10 {
	margin-top: -10px;
	margin-bottom: -10px;
}
body .margin-v--15 {
	margin-top: -15px;
	margin-bottom: -15px;
}
body .margin-v--20 {
	margin-top: -20px;
	margin-bottom: -20px;
}
body .margin-v--25 {
	margin-top: -25px;
	margin-bottom: -25px;
}
body .margin-v--30 {
	margin-top: -30px;
	margin-bottom: -30px;
}


/* Negative Margins Gerais
** ----------------------------------------------- */
body .margin--1 {
	margin: -1px;
}
body .margin--2 {
	margin: -2px;
}
body .margin--3 {
	margin: -3px;
}
body .margin--4 {
	margin: -4px;
}
body .margin--5 {
	margin: -5px;
}


/* Margin Left
** ----------------------------------------------- */
body .margin-l-0 {
	margin-left: 0;
}
body .margin-l-1 {
	margin-left: 1px;
}
body .margin-l-2 {
	margin-left: 2px;
}
body .margin-l-3 {
	margin-left: 3px;
}
body .margin-l-4 {
	margin-left: 4px;
}
body .margin-l-5 {
	margin-left: 5px;
}
body .margin-l-6 {
	margin-left: 6px;
}
body .margin-l-7 {
	margin-left: 7px;
}
body .margin-l-8 {
	margin-left: 8px;
}
body .margin-l-9 {
	margin-left: 9px;
}
body .margin-l-10 {
	margin-left: 10px;
}
body .margin-l-15 {
	margin-left: 15px;
}
body .margin-l-20 {
	margin-left: 20px;
}
body .margin-l-25 {
	margin-left: 25px;
}
body .margin-l-30 {
	margin-left: 30px;
}
body .margin-l-40 {
	margin-left: 40px;
}
body .margin-l-50 {
	margin-left: 50px;
}
body .margin-l-100 {
	margin-left: 50px;
}

/* Margin Right
** ----------------------------------------------- */
body .margin-r-0 {
	margin-right: 0;
}
body .margin-r-1 {
	margin-right: 1px;
}
body .margin-r-2 {
	margin-right: 2px;
}
body .margin-r-3 {
	margin-right: 3px;
}
body .margin-r-4 {
	margin-right: 4px;
}
body .margin-r-5 {
	margin-right: 5px;
}
body .margin-r-6 {
	margin-right: 6px;
}
body .margin-r-7 {
	margin-right: 7px;
}
body .margin-r-8 {
	margin-right: 8px;
}
body .margin-r-9 {
	margin-right: 9px;
}
body .margin-r-10 {
	margin-right: 10px;
}
body .margin-r-15 {
	margin-right: 15px;
}
body .margin-r-20 {
	margin-right: 20px;
}
body .margin-r-25 {
	margin-right: 25px;
}
body .margin-r-30 {
	margin-right: 30px;
}
body .margin-r-40 {
	margin-right: 40px;
}
body .margin-r-50 {
	margin-right: 50px;
}
body .margin-r-100 {
	margin-right: 100px;
}

/* Margin Top
** ----------------------------------------------- */
body .margin-t-0 {
	margin-top: 0;
}
body .margin-t-1 {
	margin-top: 1px;
}
body .margin-t-2 {
	margin-top: 2px;
}
body .margin-t-3 {
	margin-top: 3px;
}
body .margin-t-4 {
	margin-top: 4px;
}
body .margin-t-5 {
	margin-top: 5px;
}
body .margin-t-6 {
	margin-top: 6px;
}
body .margin-t-7 {
	margin-top: 7px;
}
body .margin-t-8 {
	margin-top: 8px;
}
body .margin-t-9 {
	margin-top: 9px;
}
body .margin-t-10 {
	margin-top: 10px;
}
body .margin-t-15 {
	margin-top: 15px;
}
body .margin-t-20 {
	margin-top: 20px;
}
body .margin-t-25 {
	margin-top: 25px;
}
body .margin-t-30 {
	margin-top: 30px;
}
body .margin-t-40 {
	margin-top: 40px;
}
body .margin-t-50 {
	margin-top: 50px;
}
body .margin-t-100 {
	margin-top: 100px;
}
body .margin-t-150 {
	margin-top: 150px;
}

/* Margin Bottom
** ----------------------------------------------- */
body .margin-b-0 {
	margin-bottom: 0;
}
body .margin-b-1 {
	margin-bottom: 1px;
}
body .margin-b-2 {
	margin-bottom: 2px;
}
body .margin-b-3 {
	margin-bottom: 3px;
}
body .margin-b-4 {
	margin-bottom: 4px;
}
body .margin-b-5 {
	margin-bottom: 5px;
}
body .margin-b-6 {
	margin-bottom: 6px;
}
body .margin-b-7 {
	margin-bottom: 7px;
}
body .margin-b-8 {
	margin-bottom: 8px;
}
body .margin-b-9 {
	margin-bottom: 9px;
}
body .margin-b-10 {
	margin-bottom: 10px;
}
body .margin-b-15 {
	margin-bottom: 15px;
}
body .margin-b-20 {
	margin-bottom: 20px;
}
body .margin-b-25 {
	margin-bottom: 25px;
}
body .margin-b-30 {
	margin-bottom: 30px;
}
body .margin-b-40 {
	margin-bottom: 40px;
}
body .margin-b-50 {
	margin-bottom: 50px;
}
body .margin-b-100 {
	margin-bottom: 100px;
}
body .margin-b-150 {
	margin-bottom: 150px;
}

/* Margin Auto
** ----------------------------------------------- */
body .margin-auto {
	margin: auto;
}
body .margin-h-auto {
	margin-left: auto;
	margin-right: auto;
}
body .margin-v-auto {
	margin-top: auto;
	margin-bottom: auto;
}
body .margin-l-auto {
	margin-left: auto;
}
body .margin-r-auto {
	margin-right: auto;
}
body .margin-t-auto {
	margin-top: auto;
}
body .margin-b-auto {
	margin-bottom: auto;
}

/* Negative Margin Left
** ----------------------------------------------- */
body .margin-l--1 {
	margin-left: -1px;
}
body .margin-l--2 {
	margin-left: -2px;
}
body .margin-l--3 {
	margin-left: -3px;
}
body .margin-l--4 {
	margin-left: -4px;
}
body .margin-l--5 {
	margin-left: -5px;
}
body .margin-l--6 {
	margin-left: -6px;
}
body .margin-l--7 {
	margin-left: -7px;
}
body .margin-l--8 {
	margin-left: -8px;
}
body .margin-l--9 {
	margin-left: -9px;
}
body .margin-l--10 {
	margin-left: -10px;
}
body .margin-l--15 {
	margin-left: -15px;
}
body .margin-l--20 {
	margin-left: -20px;
}
body .margin-l--25 {
	margin-left: -25px;
}
body .margin-l--30 {
	margin-left: -30px;
}

/* Negative Margin Right
** ----------------------------------------------- */
body .margin-r--1 {
	margin-right: -1px;
}
body .margin-r--2 {
	margin-right: -2px;
}
body .margin-r--3 {
	margin-right: -3px;
}
body .margin-r--4 {
	margin-right: -4px;
}
body .margin-r--5 {
	margin-right: -5px;
}
body .margin-r--6 {
	margin-right: -6px;
}
body .margin-r--7 {
	margin-right: -7px;
}
body .margin-r--8 {
	margin-right: -8px;
}
body .margin-r--9 {
	margin-right: -9px;
}
body .margin-r--10 {
	margin-right: -10px;
}
body .margin-r--15 {
	margin-right: -15px;
}
body .margin-r--20 {
	margin-right: -20px;
}
body .margin-r--25 {
	margin-right: -25px;
}
body .margin-r--30 {
	margin-right: -30px;
}

/* Negative Margin Top
** ----------------------------------------------- */
body .margin-t--1 {
	margin-top: -1px;
}
body .margin-t--2 {
	margin-top: -2px;
}
body .margin-t--3 {
	margin-top: -3px;
}
body .margin-t--4 {
	margin-top: -4px;
}
body .margin-t--5 {
	margin-top: -5px;
}
body .margin-t--6 {
	margin-top: -6px;
}
body .margin-t--7 {
	margin-top: -7px;
}
body .margin-t--8 {
	margin-top: -8px;
}
body .margin-t--9 {
	margin-top: -9px;
}
body .margin-t--10 {
	margin-top: -10px;
}
body .margin-t--15 {
	margin-top: -15px;
}
body .margin-t--20 {
	margin-top: -20px;
}
body .margin-t--25 {
	margin-top: -25px;
}
body .margin-t--30 {
	margin-top: -30px;
}

/* Negative Margin Bottom
** ----------------------------------------------- */
body .margin-b--1 {
	margin-bottom: -1px;
}
body .margin-b--2 {
	margin-bottom: -2px;
}
body .margin-b--3 {
	margin-bottom: -3px;
}
body .margin-b--4 {
	margin-bottom: -4px;
}
body .margin-b--5 {
	margin-bottom: -5px;
}
body .margin-b--6 {
	margin-bottom: -6px;
}
body .margin-b--7 {
	margin-bottom: -7px;
}
body .margin-b--8 {
	margin-bottom: -8px;
}
body .margin-b--9 {
	margin-bottom: -9px;
}
body .margin-b--10 {
	margin-bottom: -10px;
}
body .margin-b--15 {
	margin-bottom: -15px;
}
body .margin-b--20 {
	margin-bottom: -20px;
}
body .margin-b--25 {
	margin-bottom: -25px;
}
body .margin-b--30 {
	margin-bottom: -30px;
}

/* Tags Class
** ----------------------------------------------- */
body .overflow {
	overflow: hidden;
}
body .overflow-x {
	overflow-x: hidden;
}
body .overflow-y {
	overflow-y: hidden;
}
body .overflow-x-auto {
	overflow-x: auto;
}
body .overflow-y-auto {
	overflow-y: auto;
}
body .scroll-xy-auto {
	overflow: auto
}
body .scroll-visible {
	overflow: visible;
}

/* Box mode
** ----------------------------------------------- */
body .border-box {
	box-sizing: border-box;
}
body .content-box {
	box-sizing: content-box;
}

/* Flex
** ----------------------------------------------- */
body .flex-1 {
	flex: 1;
	min-width: 0;
}
body .flex-2 {
	flex: 2;
	min-width: 0;
}
body .flex-grow {
	flex-grow: 1;
}
body .flex-shrink {
	flex-shrink: 1;
}
body .flex-center {
	justify-content: center;
	align-content: center;
	align-items: center;
}
body .flex-center-v {
	align-items: center
}
body .flex-center-h {
	justify-content: center
}
body .flex-top {
	align-items: flex-start;
}
body .flex-bottom {
	align-items: flex-end;
}
body .flex-end {
	justify-content: flex-end;
}
body .flex-start {
	justify-content: start
}

body .flex-top-self {
	align-self: flex-start;
}
body .flex-bottom-self {
	align-self: flex-end;
}
body .flex-end-self {
	justify-self: flex-end;
}
body .flex-start-self {
	justify-self: start
}

body .flex-wrap {
	flex-wrap: wrap;
}
body .flex-nowrap {
	flex-wrap: nowrap;
}
body .flex-column {
	flex-direction: column;
}
body .flex-column-reverse {
	flex-direction: column-reverse;
}
body .flex-row {
	flex-direction: row;
}
body .flex-row-reverse {
	flex-direction: row-reverse;
}
body .flex-around {
	justify-content: space-around;
}
body .flex-between {
	justify-content: space-between;
}
body .flex-self-center {
	align-self: center;
}
body .flex-stretch {
	align-self: stretch;
}

body .order-1 {
    order: 1;
}
body .order-2 {
    order: 2;
}
body .order-3 {
    order: 3;
}
body .order-4 {
    order: 4;
}
body .order-5 {
    order: 5;
}

body .gap-5 {
	gap: 5px;
}
body .gap-10 {
	gap: 10px;
}
body .gap-15 {
	gap: 15px;
}
body .gap-20 {
	gap: 20px;
}
body .gap-25 {
	gap: 25px;
}
body .gap-30 {
	gap: 30px;
}

/* Display
** ----------------------------------------------- */
body .block {
	display: block;
}
body .inline {
	display: inline;
}
body .inline-block {
	display: inline-block;
}
body .flex {
	display: flex;
}
body .inline-flex {
	display: inline-flex;
}
body .none {
	display: none;
}

/* Border
** ----------------------------------------------- */
body .border {
	border: 1px solid #DDD;
}
body .border-solid {
	border-style: solid;
}
body .border-color {
	border-color: #DDD;
}
body .border-0 {
	border-width: 0;
}
body .border-1 {
	border-width: 1px;
}
body .border-2 {
	border-width: 2px;
}
body .border-3 {
	border-width: 3px;
}
body .border-4 {
	border-width: 4px;
}
body .border-5 {
	border-width: 5px;
}

body .border-h {
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
}
body .border-v {
	border-top: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
}

body .border-t {
	border-top: 1px solid #DDD;
}
body .border-b {
	border-bottom: 1px solid #DDD;
}
body .border-l {
	border-left: 1px solid #DDD;
}
body .border-r {
	border-right: 1px solid #DDD;
}

body .border-t-transparent {
	border-top: transparent;
}
body .border-b-transparent {
	border-bottom: transparent;
}
body .border-l-transparent {
	border-left: transparent;
}
body .border-r-transparent {
	border-right: transparent;
}

body .border-t-0 {
	border-top: 0;
}
body .border-b-0 {
	border-bottom: 0;
}
body .border-l-0 {
	border-left: 0;
}
body .border-r-0 {
	border-right: 0;
}

/* Border style
** ----------------------------------------------- */
body .border-dashed {
	border-style: dashed;
}
body .border-solid {
	border-style: solid;
}
body .border-double {
	border-style: double;
}

/* Border Radius
** ----------------------------------------------- */
body .border-radius {
	border-radius: 2px;
}
body .border-radius-l {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
body .border-radius-r {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}
body .border-radius-t {
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}
body .border-radius-b {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}
body .border-radius-tl {
	border-top-left-radius: 2px;
}
body .border-radius-tr {
	border-top-right-radius: 2px;
}
body .border-radius-bl {
	border-bottom-left-radius: 2px;
}
body .border-radius-br {
	border-bottom-right-radius: 2px;
}
body .border-radius-1 {
	border-radius: 1px;
}
body .border-radius-2 {
	border-radius: 2px;
}
body .border-radius-3 {
	border-radius: 3px;
}
body .border-radius-4 {
	border-radius: 4px;
}
body .border-radius-5 {
	border-radius: 5px;
}
body .border-radius-50 {
	border-radius: 50%;
}

/* z-index
** ----------------------------------------------- */
body .box-shadow {
	box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}
body .box-shadow-alt {
	box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}
body .box-shadow-inset {
	box-shadow: 0 0 2px rgba(0,0,0,0.2) inset, 0 0 1px #FFF;
}

/* z-index
** ----------------------------------------------- */
body .z-index--1 {
	z-index: -1;
}
body .z-index-0 {
	z-index: 0;
}
body .z-index-1 {
	z-index: 1;
}
body .z-index-2 {
	z-index: 2;
}
body .z-index-3 {
	z-index: 3;
}
body .z-index-4 {
	z-index: 4;
}
body .z-index-5 {
	z-index: 5;
}
body .z-index-6 {
	z-index: 6;
}
body .z-index-7 {
	z-index: 7;
}
body .z-index-8 {
	z-index: 8;
}
body .z-index-9 {
	z-index: 9;
}
body .z-index-10 {
	z-index: 10;
}
body .z-index-15 {
	z-index: 15;
}
body .z-index-20 {
	z-index: 20;
}
body .z-index-25 {
	z-index: 25;
}
body .z-index-30 {
	z-index: 30;
}
body .z-index-100 {
	z-index: 100;
}
body .z-index-200 {
	z-index: 200;
}
body .z-index-300 {
	z-index: 300;
}
body .z-index-400 {
	z-index: 400;
}
body .z-index-500 {
	z-index: 500;
}
body .z-index-600 {
	z-index: 600;
}
body .z-index-700 {
	z-index: 700;
}
body .z-index-800 {
	z-index: 800;
}
body .z-index-900 {
	z-index: 900;
}
body .z-index {
	z-index: 999;
}
body .z-index-auto {
	z-index: auto;
}
body .z-index-1000 {
	z-index: 1000;
}
body .z-index-10000 {
	z-index: 10000;
}
body .z-index-9999 {
	z-index: 9999;
}

/* Background
** ----------------------------------------------- */
body .bg-cover {
	background-size: cover;
}
body .bg-center {
	background-position: center;
}
body .bg-transparent {
	background-color: transparent;
}
body .bg-contain {
	background-size: contain;
}
body .bg-no-repeat {
	background-repeat: no-repeat;
}
body .bg-parallax {
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
}

/* Colors, borders and Backgrounds
** ----------------------------------------------- */
body .bg-odd:nth-child(odd) {
	background-color: #EEF2F5;
}
body .bg-odd-children > *:nth-child(odd) {
	background-color: #EEF2F5;
}

body .bg-green {
	background-color: #229d3e;
}
body .bg-green-light {
	background-color: #f9fffa;
}
body .bg-gradient-green {
	background: linear-gradient(#4ccf4c, #339933);
}
body .color-green {
	color: #229d3e;
}
body .border-green {
	border-color: #229d3e;
}

body .color-green-alt {
	color: #0fc400;
}
body .border-green-alt {
	border-color: #0fc400;
}

body .bg-green-hover:hover {
	background-color: #229d3e;
}
body .bg-green-light-hover:hover {
	background-color: #f9fffa;
}
body .bg-gradient-green-hover:hover {
	background: linear-gradient(#4ccf4c, #339933);
}
body .color-green-hover:hover {
	color: #229d3e;
}
body .border-green-hover:hover {
	border-color: #229d3e;
}

body .bg-yellow {
	background-color: #cf8d00;
}
body .bg-yellow-light {
	background-color: #fffff9;
}
body .bg-gradient-yellow {
	background: linear-gradient(#F9D200, #D5AF2A);
}
body .color-yellow {
	color: #cf8d00;
}
body .border-yellow {
	border-color: #cf8d00;
}

body .bg-yellow-hover:hover {
	background-color: #cf8d00;
}
body .bg-yellow-light-hover:hover {
	background-color: #fffff9;
}
body .bg-gradient-yellow-hover:hover {
	background: linear-gradient(#F9D200, #D5AF2A);
}
body .color-yellow-hover:hover {
	color: #cf8d00;
}
body .border-yellow-hover:hover {
	border-color: #cf8d00;
}

body .bg-orange {
	background-color: #F26B00;
}
body .bg-orange-light {
	background-color: #FFFAF4;
}
body .bg-gradient-orange {
	background: linear-gradient(#F98300, #D5802A);
}
body .color-orange {
	color: #F25F00;
}
body .border-orange {
	border-color: #F28500;
}

body .bg-orange-hover:hover {
	background-color: #F26B00;
}
body .bg-orange-light-hover:hover {
	background-color: #FFFAF4;
}
body .bg-gradient-orange-hover:hover {
	background: linear-gradient(#F98300, #D5802A);
}
body .color-orange-hover:hover {
	color: #F25F00;
}
body .border-orange-hover:hover {
	border-color: #F28500;
}

body .bg-red {
	background-color: #cf0000;
}
body .bg-red-light {
	background-color: #fff7f7;
}
body .bg-gradient-red {
	background: linear-gradient(#E63232, #a42424);
}
body .color-red {
	color: #cf0000;
}
body .border-red {
	border-color: #e60000;
}

body .bg-red-hover:hover {
	background-color: #cf0000;
}
body .bg-red-light-hover:hover {
	background-color: #fff7f7;
}
body .bg-gradient-red-hover:hover {
	background: linear-gradient(#E63232, #a42424);
}
body .color-red-hover:hover {
	color: #cf0000;
}
body .border-red-hover:hover {
	border-color: #e60000;
}

body .bg-blue {
	background-color: #249AD1;
}
body .bg-blue-light {
	background-color: #f9feff;
}
body .bg-blue-alt {
	background-color: #2596e1;
}
body .bg-gradient-blue {
	background: linear-gradient(#00C4F9, #2A9FD5);
}
body .color-blue {
	color: #249AD1;
}
body .color-blue-alt {
	color: #0277BD;
}
body .border-blue {
	border-color: #2A9FD5;
}

body .bg-blue-hover:hover {
	background-color: #249AD1;
}
body .bg-blue-light-hover:hover {
	background-color: #f9feff;
}
body .bg-gradient-blue-hover:hover {
	background: linear-gradient(#00C4F9, #2A9FD5);
}
body .color-blue-hover:hover {
	color: #249AD1;
}
body .border-blue-hover:hover {
	border-color: #2A9FD5;
}

body .bg-black {
	background-color: #2E2F31;
}
body .bg-black-light {
	background-color: #232323;
}
body .bg-gradient-black {
	background: linear-gradient(#232323, #010101);
}
body .color-black {
	color: #2E2F31;
}
body .border-black {
	border-color: #2E2F31;
}

body .bg-black-hover:hover {
	background-color: #2E2F31;
}
body .bg-black-light-hover:hover {
	background-color: #232323;
}
body .bg-gradient-black-hover:hover {
	background: linear-gradient(#232323, #010101);
}
body .color-black-hover:hover {
	color: #2E2F31;
}
body .border-black-hover:hover {
	border-color: #2E2F31;
}

body .bg-white {
	background-color: #FFF;
}
body .bg-white-light {
	background-color: #EAEAEA;
}
body .bg-gradient-white {
	/*background: linear-gradient(#FFF, #F9F9F9);*/
	background: linear-gradient(#FFF, #F1F5F8);
}
body .color-white {
	color: #FFF;
}
body .border-white {
	border-color: #FFF;
}

body .bg-white-hover:hover {
	background-color: #FFF;
}
body .bg-white-light-hover:hover {
	background-color: #EAEAEA;
}
body .bg-gradient-white-hover:hover {
	background: linear-gradient(#FFF, #F9F9F9);
}
body .color-white-hover:hover {
	color: #FFF;
}

body .bg-transparent {
	background-color: transparent;
}
body .color-transparent {
	color: transparent;
}
body .border-transparent {
	border-color: transparent;
}

body .bg-transparent-hover:hover {
	background-color: transparent;
}
body .color-transparent-hover:hover {
	color: transparent;
}
body .border-transparent-hover:hover {
	border-color: transparent;
}

body .color-default {
	color: #333;
}

body .bg {
	background-size: cover;
	background-position: center center;
}
body .bg-alt {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

/* Cursor
** ----------------------------------------------- */
body .cursor-pointer {
	cursor: pointer;
}
body .cursor-default {
	cursor: default;
}
body .cursor-not-allowed {
	cursor: not-allowed;
}
body .cursor-grab {
	cursor: grab;
}
body .cursor-resize-v {
	cursor: ns-resize;
}
body .events-none {
	pointer-events: none;
}

body .bg-mask {
	-webkit-mask-size: cover;
	-webkit-mask-position: center center;
	mask-size: cover;
	mask-position: center center;
}
body .bg-mask-alt {
	-webkit-mask-size: contain;
	-webkit-mask-position: center center;
	-webkit-mask-repeat: no-repeat;
	mask-size: contain;
	mask-position: center center;
	mask-repeat: no-repeat;
}

/* Background Color
** ----------------------------------------------- */
body .bg-white-1 {
	background-color: rgba(255,255,255,0.1);
}
body .bg-white-2 {
	background-color: rgba(255,255,255,0.2);
}
body .bg-white-3 {
	background-color: rgba(255,255,255,0.3);
}
body .bg-white-4 {
	background-color: rgba(255,255,255,0.4);
}
body .bg-white-5 {
	background-color: rgba(255,255,255,0.5);
}
body .bg-white-6 {
	background-color: rgba(255,255,255,0.6);
}
body .bg-white-7 {
	background-color: rgba(255,255,255,0.7);
}
body .bg-white-8 {
	background-color: rgba(255,255,255,0.8);
}
body .bg-white-9 {
	background-color: rgba(255,255,255,0.9);
}


/* Black */
body .bg-black-1 {
	background-color: rgba(0,0,0,0.1);
}
body .bg-black-2 {
	background-color: rgba(0,0,0,0.2);
}
body .bg-black-3 {
	background-color: rgba(0,0,0,0.3);
}
body .bg-black-4 {
	background-color: rgba(0,0,0,0.4);
}
body .bg-black-5 {
	background-color: rgba(0,0,0,0.5);
}
body .bg-black-6 {
	background-color: rgba(0,0,0,0.6);
}
body .bg-black-7 {
	background-color: rgba(0,0,0,0.7);
}
body .bg-black-8 {
	background-color: rgba(0,0,0,0.8);
}
body .bg-black-9 {
	background-color: rgba(0,0,0,0.9);
}

/*
** Fill
** ----------------------------------------------- */
body .fill,
body .fill-center,
body .fill-bg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
body .fill-bg {
	z-index: -1;
}
body .fill-center {
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*
** Textos
** ================================================== */
body .box-txt > *:first-child {
	margin-top: 0;
	padding-top: 0;
}
body .box-txt > *:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

/*
** Width e Height
** ================================================== */
body .wh-20 {
	min-width: 20px;
	max-width: 20px;
	min-height: 20px;
	max-height: 20px;
}
body .wh-25 {
	min-width: 25px;
	max-width: 25px;
	min-height: 25px;
	max-height: 25px;
}
body .wh-30 {
	min-width: 30px;
	max-width: 30px;
	min-height: 30px;
	max-height: 30px;
}
body .wh-40 {
	min-width: 40px;
	max-width: 40px;
	min-height: 40px;
	max-height: 40px;
}
body .wh-160 {
	min-width: 160px;
	max-width: 160px;
	min-height: 160px;
	max-height: 160px;
}

body .scale-1-7 {
	transform: scale(1.7);
}

body .box-shadow-none {
	box-shadow: none;
}

@media (max-width: 1680px) {
	body .box-2-1680 {
		min-width: 50%;
		max-width: 50%;
	}
}

@media (max-width: 1180px) {
	body .box-2-1180 {
		min-width: 50%;
		max-width: 50%;
	}
}

@media (max-width: 980px) {
	body .box-2-980 {
		min-width: 50%;
		max-width: 50%;
	}
}

@media (max-width: 980px) {
	body .box-3-980 {
		min-width: 33.3333%;
		max-width: 33.3333%;
	}
	body .box-1-980 {
		min-width: 100%;
		max-width: 100%;
	}
	body .block-980 {
		display: block;
	}
	body .none-980 {
		display: none;
	}
	body .flex-column-980 {
		flex-direction: column;
	}
	body .margin-t-15-980 {
		margin-top: 15px;
	}
	body .relative-980 {
		position: relative;
	}

	body .flex-center-980 {
		justify-content: center;
		align-content: center;
		align-items: center;
	}
	body .flex-center-v-980 {
		align-items: center
	}
	body .flex-center-h-980 {
		justify-content: center
	}
	body .margin-l-auto-980 {
		margin-left: auto;
	}
	body .margin-l-0-980 {
		margin-left: 0;
	}
}

@media (max-width: 780px) {
	body .box-1-780 {
		min-width: 100%;
		max-width: 100%;
	}
	body .box-2-780 {
		min-width: 50%;
		max-width: 50%;
	}
	body .flex-column-780 {
		flex-direction: column;
	}
	body .flex-column-780-center {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 15px;
	}
	body .margin-t-15-780 {
		margin-top: 15px;
	}
}

@media (max-width: 680px) {
	body .flex-column-680 {
		flex-direction: column;
	}
	body .margin-h-auto-680 {
		margin-left: auto;
		margin-right: auto;
	}
	body .align-center-680 {
		text-align: center;
	}
	body .padding-t-15-680 {
		padding-top: 15px;
	}
	body .padding-l-0-680 {
		padding-left: 0;
	}
	body .box-1-680 {
		min-width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 580px) {
	body .box-1-580 {
		min-width: 100%;
		max-width: 100%;
	}
	body .flex-column-580 {
		flex-direction: column;
	}
}
