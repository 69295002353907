.cpnt-imgtitulodesc-foto-box {
	position: relative;
	z-index: 10;
	margin-right: 10px;
}
.cpnt-imgtitulodesc-box {
	display: inline-flex;
	align-items: center;
}
.cpnt-imgtitulodesc-info-box {
	text-align: left;
}
.cpnt-imgtitulodesc-foto-preview-box {
	width: 200px;
}
.cpnt-itemfotodesc-img,
.cpnt-imgtitulodesc-foto-preview {
	position: relative;
}

@media (max-width: 980px) {
	.cpnt-imgtitulodesc-res-sem-img .cpnt-imgtitulodesc-info-box {
		text-align: center;
	}
}

@media (max-width: 680px) {
	.cpnt-imgtitulodesc-resp {
		flex-direction: column;
	}
	.cpnt-imgtitulodesc-resp .cpnt-imgtitulodesc-info-box {
		text-align: center;
	}
	.cpnt-imgtitulodesc-foto-box {
		margin-right: 0;
		margin-bottom: 10px;
	}
}