.menu {
	display: flex;
	align-items: center;
}
.menu-lista {
	margin-left: auto;
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
}
.menu-container-inner {
	display: flex;
	align-items: center;
}
.menu-lista a {
	color: #000;
	display: block;
	font-size: 14px;
	text-transform: uppercase;
	padding: 10px;
	text-decoration: none;
	transition: all 0.2s ease 0s;
}
.menu-lista a:before {
	content: attr(title);
	font-weight: bold;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	display: block;
}
.menu-lista a:hover {
	font-weight: bold;
}
.menu-lista li {
	text-align: center;
}
.menu-lista li + li {
	margin-left: 5px;
}

/*
** Botão
** ================================================== */
.menu-btn-box {
	margin-left: 10px;
}
.menu-btn {
	background-color: #000;
	padding: 6px 7px;
	text-decoration: none;
	font-size: 14px;
	color: #FFF;
	border-radius: 5px;
	display: block;
	font-weight: bold;
	text-transform: uppercase;
}

/*
** Responsivo
** ================================================== */
.btn-menu-box {
	display: none;
}
.menu-container-logo {
	display: none;
}
.btn-menu {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 4px;
	border-radius: 2px;
	/* background-color: #F6F7FB; */
	width: 40px;
	cursor: pointer;
}
.btn-menu-linha {
	width: 100%;
	height: 3px;
	background-color: #000;
	border-radius: 2px;
	margin: 3px 0;
}

@media (max-width: 1080px) {
	/*
	** Menu
	** ================================================== */
	.menu-container {
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease 0s;
		z-index: 999;
	}
	.btn-menu-box,
	.menu-container-logo {
		display: block;
	}
	.menu-container-inner {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		max-width: 350px;
		width: 90%;
		padding: 30px;
		background-color: #FFF;
		box-shadow: 3px 0 16px rgba(0,0,0,0.3);
		z-index: 10;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		left: -350px;
		transition: all 0.3s ease 0s;
		box-sizing: border-box;
	}
	.menu-lista {
		flex-direction: column;
		padding: 30px 0;
	}
	.menu-container-bg {
		background-color: #00000050;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s ease 0s;
	}

	/*
	** Menu ativo
	** ================================================== */
	.menu-container-ativo {
		opacity: 1;
		visibility: visible;
	}
	.menu-container-ativo .menu-container-inner {
		left: 0;
	}
	.menu-container-ativo .menu-container-bg {
		opacity: 1;
		visibility: visible;
	}
}
