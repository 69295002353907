.tela-empresaschat {
	display: flex;
	flex: 1;
	flex-direction: column;
}
.tela-empresaschat-container {
	display: flex;
	flex: 1;
	max-height: 100%;
	min-height: 0;
}
.tela-empresaschat-info-wrap {
	padding: 20px;
	background-color: #2E2F31;
}
.tela-empresaschat-info-box {
	min-width: 400px;
	max-width: 400px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.tela-empresaschat-chat-box {
	margin-left: 20px;
	display: flex;
	max-height: 100%;
	height: 100%;
	width: 100%;
}
.tela-empresaschat-empresainfo {
	background-color: #404040;
	margin: -20px -20px 15px;
	padding: 15px 10px;
	font-size: 16px;
}

/* Lado esquerdo */
.tela-empresaschat-sec + .tela-empresaschat-sec {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid #EEE;
}

/* Hunter */
.tela-empresaschat-info-hunter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Equipe */
.tela-empresaschat-info-equipe {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Habilidades */
.tela-empresaschat-info-dados {
	font-size: 16px;
	max-height: 100%;
	overflow-y: auto;
	flex: 1;
	padding: 20px;
}
