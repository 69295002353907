.cpnt-ptlinkedin-boxwrap {
	display: flex;
	align-items: center;
	background-color: #F0F9FF;
	padding: 15px;
	border: 1px solid #2596E1;
	border-left: 5px solid #2596E1;
	border-radius: 5px;
}
.cpnt-ptlinkedin-link-ico-box {
	display: flex;
	align-items: center;
}
.cpnt-ptlinkedin-txt {
	margin-left: 20px;
}
.cpnt-ptlinkedin-link-info {
	margin-left: auto;
	padding-left: 20px;
}


/* Componente lista info */
.cpnt-ptlinkedin-lista {
	position: relative;
	padding: 15px 0 0;
	border-top: 1px solid #DFDFDF;
	z-index: 1;
}
.cpnt-ptlinkedin-lista:hover:before {
	content: '';
	pointer-events: none;
	/* background-color: #F3F6F9; */
	/* background-color: #e4ebee; */
	border-radius: 5px;
	position: absolute;
	left: -15px;
	right: -15px;
	top: -1px;
	bottom: 0;
	z-index: -1;
	border: 1px solid #DDD;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	background-color: #FFF;
}
.cpnt-ptlinkedin-lista-wrap {
	display: flex;
}
.cpnt-ptlinkedin-lista-infos {
	flex: 1;
	min-width: 0;
	padding-bottom: 15px;
}
.cpnt-ptlinkedin-lista-checkbox {
	display: flex;
	padding-left: 15px;
}

@media (max-width: 680px) {
	.cpnt-ptlinkedin-boxwrap {
		flex-direction: column;
	}
	.cpnt-ptlinkedin-link-info {
		margin-left: 0;
		padding-left: 0;
		margin-top: 15px;
	}
}