.cpnt-disponibilidade {
	padding: 10px;
	font-size: 14px;
	border-radius: 3px;
	color: #FFF;
}
.cpnt-disponibilidade-min {
	padding: 3px 6px;
	font-size: 12px;
	border-radius: 2px;
	color: #FFF;
}
.cpnt-disponibilidade-disponivel {
	background-color: #229d3e;
}
.cpnt-disponibilidade-indisponivel {
	background-color: #E74C3C;
}
.cpnt-disponibilidade-outro {
	background-color: #AAA;
}

/* alt */
.cpnt-disponibilidade-disponivel-alt {
	background-color: #FFF;
	border: 0;
	color: #229d3e;
}
.cpnt-disponibilidade-indisponivel-alt {
	background-color: #FFF;
	border: 0;
	color: #E74C3C;
}
