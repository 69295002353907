.cpnt-inputtelefone2 .PhoneInputCountry {
	border: 1px solid #2E2F31;
	border-right: 0;
	padding: 0 10px;
	border-radius: 4px 0 0 4px;
	margin-right: 0;
}
.cpnt-inputtelefone2-box {
	position: relative;
}
.cpnt-inputtelefone2-box input {
	border: 1px solid #2E2F31;
	border-left: 0;
	border-radius: 0 4px 4px 0;
	background-color: #FFF;
	padding: 11px 10px;
	width: 100%;
	box-sizing: border-box;
	font-family: inherit;
	outline: none;
}
.cpnt-inputtelefone2-box label,
.cpnt-inputtelefone2-box .label {
	position: absolute;
	pointer-events: none;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	font-weight: bold;
	transition: all 0.3s ease 0s;
	background-color: transparent;
	padding: 5px;
	z-index: 1;
}
.cpnt-inputtelefone2-box input:focus ~ label,
.cpnt-inputtelefone2-box input:not([value=""]) ~ label,
.cpnt-inputtelefone2-box-ativo label,

.cpnt-inputtelefone2-box-ativo label,
.cpnt-inputtelefone2-box .label.ativo {
	font-size: 12px;
	top: 0;
	left: 6px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;;
}
.cpnt-inputtelefone2-req {
	color: #cf2c2c;
	margin-left: 3px;
}
.cpnt-inputtelefone2-box-invalido.cpnt-inputtelefone2-box input {
	border-color: #cf2c2c;
	color: #cf2c2c;
	filter: none;
}
.cpnt-inputtelefone2-box-invalido.cpnt-inputtelefone2-box label {
	color: #cf2c2c;
}

.PhoneInputCountryIcon--border {
	border: 1px solid #EAEAEA;
	box-shadow: none;
	background-color: transparent;
	outline: none;
}
.PhoneInputCountryIcon--border svg {
	display: block;
}

@media (max-width: 1680px) {
	.cpnt-inputtelefone2 input {
		padding: 8px 8px;
	}
}