/*
** Geral
** ================================================== */
.overlay-conteudo {
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: all 1s cubic-bezier(.23,1,.32,1) 0s;
	transform: scale(1.2);
	background-color: rgba(255, 255, 255, 0.97);
	display: flex;
	align-items: center;

	max-height: 100%;
	overflow-y: auto;
}
.overlay-conteudo-bg-alt {
	background: rgba(247, 248, 250, 0.95);
}
.overlay-conteudo-ativo {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
}

/*
** Conteúdo
** ================================================== */
.overlay-conteudo-texto {
	margin-top: 20px;
	font-size: 18px;
	text-align: center;
}
.overlay-conteudo-texto a {
	font-weight: bold;
	text-decoration: none;
}
.overlay-conteudo-wrap {
	margin: auto;
}
.overlay-conteudo-icone-wrap {
	margin: auto;
	max-width: 160px;
	width: 100%;
}
.overlay-conteudo-icone-wrap img {
	width: 100%;
	display: block;
}
.overlay-conteudo-fechar-wrap {
	margin-top: 20px;
	text-align: center;
	display: flex;
	justify-content: center;
}
.overlay-conteudo-fechar {
	cursor: pointer;
	color: #0277BD;
	font-weight: bold;
}
