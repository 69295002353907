.boxbg {
	padding: 20px;
	border-radius: 5px;
	background-color: #FFF;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.nome-data-box {
	display: flex;
}
.nome {
	flex: 1;
}
.data {
	font-size: 13px;
	color: #8E93A4;
}
.estrelas {
	width: 130px;
	margin-top: 10px;
}
.desc {
	margin-top: 10px;
}