.pg-perfil-wrapper {
	padding: 10px;
}
.pg-perfil-inner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.pg-perfil-box {
	box-sizing: border-box;
	min-width: 100%;
	max-width: 100%;
	font-size: 15px;
}
.pg-perfil-btn-box {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}
.pg-perfil-box-alt {
	margin: 10px;
}

/*
** Containers
** ================================================== */
.pg-perfil-conteudo-box {
	padding: 20px 0 100px;
	display: table;
	table-layout: fixed;
	width: 100%;
}
.pt-perfil-sidebar {
	display: table-cell;
	vertical-align: top;
	width: 310px;
}
.pg-perfil-conteudo {
	display: table-cell;
	vertical-align: top;
}
.pg-perfil-conteudo-inner {
	padding: 15px;
	background-color: #FFF;
	border-radius: 3px;
	border: 1px solid #D8D8D8;
	position: sticky;
	top: calc(var(--topbar-height) + var(--gap));
}

/*
** Header
** ================================================== */
.pg-perfil-header-inner {
	display: flex;
	background-color: #FFF;
	padding: 15px;
	border: 1px solid #D8D8D8;
	border-radius: 3px;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
}
.pg-perfil-header-meio {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/*
** Sidebar
** ================================================== */
.pt-perfil-sidebar {
	font-size: 16px;
}
.pt-perfil-sidebar ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.pt-perfil-sidebar ul li a:before {
	content: '';
	background: url('@svg/solid/caret-right.svg') no-repeat center center;
	background-size: contain;
	display: inline-flex;
	vertical-align: middle;
	margin-right: 5px;
	width: 15px;
	height: 15px;
}
.pt-perfil-sidebar ul li + li {
	margin-top: 4px;
}
.pt-perfil-sidebar h3 {
	margin: 0;
	padding: 0;
}
.pt-perfil-sidebar-secao-conteudo {
	margin-top: 7px;
}
.pt-perfil-sidebar-secao + .pt-perfil-sidebar-secao {
	margin-top: 20px;
}
.pt-perfil-sidebar .pt-perfil-sidebar-item-ativo {
	color: #676767;
	text-decoration: underline;
}

.pg-perfil-header-dir {
	align-self: self-start;
}
.pg-perfil-nivel-acesso {
	text-align: center;
	font-size: 16px;
	padding-top: 20px;
}

@media (max-width: 980px) {
	.pg-perfil-conteudo-box {
		display: block;
	}
	.pt-perfil-sidebar {
		display: block;
		width: 100%;
	}
	.pg-perfil-conteudo {
		display: block;
		margin-top: 15px;
		width: 100%;
	}
}

@media (max-width: 880px) {
	.pg-perfil-header-inner {
		flex-direction: column;
	}
	.pg-perfil-header-dir {
		align-self: center;
		margin-top: 15px;
	}
}