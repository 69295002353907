.inputtext2-box {
	position: relative;
}
.inputtext2-box input,
.inputtext2-box textarea {
	border: 1px solid #2E2F31;
	border-radius: 4px;
	background-color: #FFF;
	padding: 11px 10px;
	width: 100%;
	box-sizing: border-box;
	font-family: inherit;
	/* filter: grayscale(100%) brightness(110%); */
	outline: none;
	transition: border 0.1s ease 0s, box-shadow 0.1s ease 0s;
	box-shadow: 0 0 3px transparent;
}
.inputtext2-box input:hover,
.inputtext2-box textarea:hover,
.inputtext2-box input:focus,
.inputtext2-box textarea:focus {
	border-color: #2596e1;
	box-shadow: 0 0 5px rgba(37, 150, 225, 0.5);
}
.inputtext2-box label,
.inputtext2-box .label {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	font-weight: bold;
	transition: all 0.3s ease 0s;
	background-color: transparent;
	padding: 5px;
	z-index: 0;
}
.inputtext2-box input:focus ~ label,
.inputtext2-box input:not([value=""]) ~ label,
.inputtext2-box-ativo label,

.inputtext2-box textarea:focus ~ label,
.inputtext2-box textarea:not([value=""]) ~ label,
.inputtext2-box-ativo label,
.inputtext2-box .label.ativo {
	font-size: 12px;
	top: 0;
	left: 6px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;;
}

.inputtext2-box-invalido.inputtext2-box input,
.inputtext2-box-invalido.inputtext2-box textarea {
	border-color: #cf2c2c;
	color: #cf2c2c;
	filter: none;
}
.inputtext2-box-invalido.inputtext2-box label,
.inputtext2-box-invalido.inputtext2-box textarea {
	color: #cf2c2c;
}
.inputtext2-req {
	color: #cf2c2c;
	margin-left: 3px;
}
.inputtext2-dropdown {
	pointer-events: none;
	position: absolute;
	right: 15px;
	width: 16px;
	height: 9px;
	background: url('../../assets/imagens/icones/down-chevron.png') no-repeat center center;
	background-size: contain;
	top: 50%;
	transform: translateY(-50%);
}
.inputtext2-box-dropdown input {
	padding-right: 40px;
}

/* Gerador password */
.inputtext2-btn-password {
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
	bottom: 10px;
	background: url('../../assets/imagens/svg/icones/solid/key.svg') no-repeat center center, linear-gradient(#EEE 25%, #fdfdfd 50%, #eaeaea 50%, #f0f0f0 75%);
	background-size: 14px 14px, 100% 100%;
	color: #2E2F31;
	box-shadow: 0 2px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	border-radius: 3px;
	border: 0;
}

.cpnt-inputtext2-customlabel {
	position: absolute;
	left: 10px;
	top: -8px;
	/* z-index: 10; */
	z-index: 0;
	font-size: 12px;
	font-weight: bold;
	padding: 0 2px;
	text-shadow: -1px -1px #FFF, 0 -1px #FFF, 1px -1px #FFF, 0 1px #FFF, 1px 1px #FFF, 0 1px #FFF, -1px 1px #FFF, -1px 1px #FFF, -1px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -2px -2px #FFF, 0 -2px #FFF, 2px -2px #FFF, 0 2px #FFF, 2px 2px #FFF, 0 2px #FFF, -2px 2px #FFF, -2px 2px #FFF, -2px 0 #FFF, -3px -3px #FFF, 0 -3px #FFF, 3px -3px #FFF, 0 3px #FFF, 3px 3px #FFF, 0 3px #FFF, -3px 3px #FFF, -3px 3px #FFF, -3px 0 #FFF;
}

/*
** Desativado
** ================================================== */
.inputtext2-box input[disabled],
.inputtext2-box textarea[disabled] {
	border: 1px solid #DFDFDF;
	color: #999;
}
.inputtext2-disabled label {
	color: #999;
}
.inputtext2-disabled.inputtext2-box input:hover,
.inputtext2-disabled.inputtext2-box textarea:hover,
.inputtext2-disabled.inputtext2-box input:focus,
.inputtext2-disabled.inputtext2-box textarea:focus {
	border-color: #DFDFDF;
	box-shadow: none;
}

@media (max-width: 1680px) {
	.inputtext2-box input,
	.inputtext2-box textarea {
		padding: 8px 8px;
	}
}