.cpnt-itemlista-container {
	margin-top: 10px;
}
.cpnt-itemlista-titulo-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F2F2F2;
	border: 1px solid #D5D5D5;
	border-radius: 2px;
}
.cpnt-itemlista-titulo {
	color: #0277BD;
}
.cpnt-itemlista-titulo-nowrap {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.cpnt-itemlista-acoes {
	display: flex;
	align-items: center;
	margin-left: auto;
	border-left: 1px solid #D5D5D5;
	padding-left: 15px;
}
.cpnt-itemlista-icone-box {
	min-width: 24px;
	min-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cpnt-itemlista-acao {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.cpnt-itemlista-acao + .cpnt-itemlista-acao {
	margin-left: 15px;
}
.cpnt-itemlista-icone {
	min-width: 16px;
	max-width: 16px;
	min-height: 16px;
	max-height: 16px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center	;
}
.cpnt-itemlista-titulo-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F9F9FB;
	border: 1px solid #D5D5D5;
	border-radius: 3px;
	padding: 10px 15px;
}
.cpnt-itemlista-conteudo-box {
	display: none;
}
.cpnt-itemlista-conteudo-box-visivel {
	display: block;
}
.cpnt-itemlista-conteudo {
	border: 1px solid #D5D5D5;
	border-radius: 2px;
	padding: 15px;
	margin-top: 5px;
}

/* Vermelho */
.cpnt-itemlista-red .cpnt-itemlista-titulo-box {
	/* background-color: #fff8f8; */
	border-color: #E74C3C;
}
.cpnt-itemlista-red .cpnt-itemlista-titulo {
	/* color: #2E2F31; */
}
