.cpnt-itemprof {
	border: 1px solid #2E2F31;
	border-radius: 5px;
	background-color: #FFF;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
	position: relative;
	top: 0;
	transition: box-shadow 0.2s ease 0s, background-color 0.2s ease 0s, border 0.2s ease 0s, top 0.2s ease 0s;
}
.cpnt-itemprof:hover {
	top: -7px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/*
** Colunas
** ================================================== */
.cpnt-itemprof-cols {
	display: flex;
}
.cpnt-itemprof-col-1 {
	display: flex;
	min-width: 100px;
	max-width: 100px;
	justify-content: center;
}
/* coluna 1 */
.cpnt-itemprof-col-wrap-1 {
	flex-direction: column;
	align-items: center;
	flex-direction: column;
	text-align: center;
	display: flex;
}
.cpnt-itemprof-col-2 {
	flex: 1;
	min-width: 0;
}
.cpnt-itemprof-val {
	margin-top: 10px;
	font-size: 13px;
}

/*
** Topbar
** ================================================== */
.cpnt-itemprof-topbar {
	padding: 8px 10px;
	font-size: 15px;
	display: flex;
	justify-content: space-between;
	color: #FFF;
	background-color: #2E2F31;
}

/*
** Box checked
** ================================================== */
.cpnt-itemprof-checked {
	border-color: #2882B8;
	background-color: #f8fcff;
}
.cpnt-itemprof-checked .cpnt-itemprof-topbar {
	background: #2792D2;
}

/*
** Dados profissional
** ================================================== */
.cpnt-itemprof-titulo-destaque {
	font-size: 14px;
}
.cpnt-itemprof-id {
	font-size: 14px;
	margin-top: 10px;
}


/*
** Coluna
** ================================================== */
.cpnt-itemprof-col-1 {
	padding: 10px;
}
/* coluna 2 */
.cpnt-itemprof-col-wrap-2 {
	padding: 10px 10px 10px 0;
}

/*
** Lado esquerdo botões
** ================================================== */
.cpnt-itemprof-perfil-btn {
	margin-top: 5px;
}
.cpnt-itemprof-box-val {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*
** Cores senioridade
** ================================================== */
.cpnt-itemprof-senioridade {
	border-radius: 25px;
	padding: 2px 15px;
	color: #000;
	font-weight: bold;
	font-size: 13px;
	background-color: transparent;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 5px 0;
}
.cpnt-itemprof-senioridade-senior {
	color: #FFF;
	background-color: #229d3e;
}
.cpnt-itemprof-senioridade-pleno {
	color: #FFF;
	background-color: #acbd33;
}
.cpnt-itemprof-senioridade-junior {
	color: #FFF;
	background-color: #d28119;
}

/*
** Lado direito
** ================================================== */
.cpnt-itemprof-checkbox {
	padding-left: 15px;
	margin-left: auto;
	margin-right: 15px;
}
.cpnt-itemprof-checkdisponibilidade {
	display: flex;
	align-items: center;
}
.cpnt-itemprof-icones-exp {
	font-size: 14px;
	margin-top: 15px;
}
.cpnt-itemprof-icones-box {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
}
.cpnt-itemprof-icones-box-1 {
	display: flex;
}
.cpnt-itemprof-icones-box-2 {
	display: flex;
}
.cpnt-itemprof-icone-info-l + .cpnt-itemprof-icone-info-l {
	margin-left: 10px;
}
.cpnt-itemprof-icone-info-r + .cpnt-itemprof-icone-info-r {
	margin-left: 10px;
}

/*
** Descrição
** ================================================== */
.cpnt-itemprof-desctexto {
	max-height: 130px;
	overflow-y: auto;
	font-size: 13px;
	white-space: pre-line;
	border-radius: 3px;
	padding: 5px 0;
	word-break: break-word;
}

.cpnt-itemprof-desctexto::-webkit-scrollbar {
	width: 9px;
	height: 9px;
	background-color: #F5F5F5;
}
.cpnt-itemprof-desctexto::-webkit-scrollbar-thumb {
	background: #D9D9D9;
	border-radius: 3px;
}

.cpnt-itemprof-desctexto em {
	background-color: #fff6c0;
	font-style: inherit;
	border-radius: 5px;
}

/*
** Textos destacados
** ================================================== */
.cpnt-itemprof-nome em {
	background-color: #fff6c0;
	font-style: inherit;
	border-radius: 5px;
}
.cpnt-itemprof-titulo em {
	background-color: #fff6c0;
	font-style: inherit;
	padding: 0;
	border-radius: 3px;
}
.cpnt-itemprof-email em {
	background-color: #fff6c0;
	font-style: inherit;
	padding: 0;
	border-radius: 3px;
}

/*
** Tags no final
** ================================================== */
.cpnt-itemprof-tags-wrapper {
	padding: 15px;
}
.cpnt-itemprof-tags {
	max-width: 100%;
	overflow-x: auto;
	scrollbar-width: thin;
}

/* Chrome */
.cpnt-itemprof-tags::-webkit-scrollbar {
	width: 9px;
	height: 9px;
	background-color: #F5F5F5;
}
.cpnt-itemprof-tags::-webkit-scrollbar-thumb {
	background: #D9D9D9;
	border-radius: 3px;
}

.cpnt-itemprof-tags-wrap {
	display: flex;
}
.cpnt-itemprof-tag-item + .cpnt-itemprof-tag-item {
	margin-left: 5px;
}

/*
** Prevenir scroll desc
** ================================================== */
.cpnt-itemprof-desctexto-wrap {
	position: relative;
	margin-top: 10px;
}
.cpnt-itemprof-prevent-scroll {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
.cpnt-itemprof-desctexto-wrap:focus .cpnt-itemprof-prevent-scroll {
	pointer-events: none;
}

/*
** Conteúdo dos tooltips
** ================================================== */
.cpnt-itemprof-tooltip-conteudo {
	max-width: 300px;
	word-wrap: break-word;
	overflow: hidden;
}

@media (max-width: 480px) {
	.cpnt-itemprof-cols {
		flex-direction: column;
	}
	.cpnt-itemprof-col-1 {
		min-width: auto;
		max-width: 100%;
	}
	.cpnt-itemprof-col-wrap-2 {
		padding: 10px;
	}
	.cpnt-itemprof-val-id {
		display: flex;
		align-items: center;
		margin-top: 15px;
	}
	.cpnt-itemprof-val {
		margin-top: 0;
	}
	.cpnt-itemprof-id {
		margin-left: 15px;
	}
	.cpnt-itemprof-topbar {
		flex-direction: column;
		align-items: center;
	}
	.cpnt-itemprof-checkdisponibilidade {
		display: flex;
		align-items: center;
		margin-top: 15px;
	}
	.cpnt-itemprof-checkbox {
		padding-left: 0;
	}
}