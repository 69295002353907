.container {
	overflow: hidden;
	min-height: 100vh;
	background-color: #FDFDFD;
}
.textos {
	font-size: 18px;
	margin-top: 80px;
}
.aviso-container {
	display: flex;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
}
.aviso-box {

}
.aviso-titulo {
	font-size: 38px;
	line-height: 1.1;
}
.aviso-e {
	align-self: center;
	font-size: 22px;
	color: #000;
	padding: 30px 0;
	display: flex;
	text-align: center;
	flex-direction: column;
	align-items: center;
}
.aviso-d {
	position: relative;
	align-self: center;
}
.aviso-img {
	display: block;
	max-width: none;
	max-height: none;
	width: 68vw;
}
.box-btns-email {
	margin-top: 50px;
	display: flex;
	gap: 30px;
	align-items: center;
	font-size: 16px;
}
.btn-email {
	display: flex;
	align-items: center;
	gap: 10px;
	text-decoration: none;
}
.btn-email img {
	width: 25px;
}
.img-sucesso {
	min-width: 150px;
	max-width: 150px;
}
.box-texto-img-sucesso {
	flex-direction: column;
}

@media (max-width: 980px) {
	.aviso-container {
		flex-direction: column;
		min-height: auto;
		padding: 30px;
	}
	.aviso-img {
		margin: auto;
		width: 300px;
	}
	.aviso-box {
		min-width: auto;
		max-width: none;
	}

	.img-logo {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;
	}
}

@media (max-width: 680px) {
	.aviso-container .input-code {
		width: 100%;
	}
	.aviso-container .btn-liberar-acesso {
		width: 100%;
	}
}
