%reset-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

%x {
	position: absolute;
	right: -12px;
	top: -12px;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: var(--color-red);
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: url('../../images/default/x-branco.png') no-repeat center center;
		background-size: 50% 50%;
	}
}
