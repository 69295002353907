.cpnt-paginacao {
	display: inline-flex;
	align-items: center;
	font-size: 15px;
	position: relative;
}
.cpnt-paginacao a {
	text-decoration: none;
}
.cpnt-paginacao-box {
	display: flex;
	align-items: center;
}
.cpnt-paginacao-paginas {
	display: flex;
	align-items: center;
}

/* Go */
.cpnt-paginacao-info-paginas {
	display: flex;
	margin-right: 20px;
}
.cpnt-paginacao-info-texto {
	display: flex;
	align-items: center;
}
.cpnt-paginacao-info-input {
	display: flex;
	margin-left: 5px;
}
.cpnt-paginacao-info-btn {
	display: flex;
}
.cpnt-paginacao-info-paginas input {
	width: 45px;
	box-sizing: border-box;
	height: 30px;
	line-height: 30px;
	border: 1px solid #D7D7D7;
	border-right: 0;
	font-family: inherit;
	outline: 0;
	padding: 0 6px;
	text-align: center;
	border-radius: 3px 0 0 3px;
}
.cpnt-paginacao-info-btn {
	box-sizing: border-box;
	padding: 0 10px;
	border: 0;
	background-color: #F9F9FB;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #D7D7D7;
	border-radius: 0 3px 3px 0;
}
.cpnt-paginacao-lista {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
}
.cpnt-paginacao-lista li {
	display: flex;
}
.cpnt-paginacao-lista li.disabled a {
	color: #999;
}

/* Voltar avançar */
.cpnt-paginacao-btn-next-prev-box {
	cursor: pointer;
	display: flex;
	align-items: center;
	background-color: #F9F9FB;
	border: 1px solid #D7D7D7;
	padding: 4px 10px;
	transition: all 0.3s ease 0s;
}
.cpnt-paginacao-btn-next-prev-box:hover {
	color: #0277BD;
	background-color: #e4ebee;
	border-color: #D7D7D7;
}
.cpnt-paginacao-lista li:not(:first-child) .cpnt-paginacao-btn-next-prev-box {
	border-left: 0;
}
.cpnt-paginacao-lista li:first-child a {
	border-radius: 3px 0 0 3px;
}
.cpnt-paginacao-lista li:last-child a {
	border-radius: 0 3px 3px 0;
}
.cpnt-paginacao-btn-prev-box {
	border-radius: 3px 0 0 3px;
}
.cpnt-paginacao-btn-next-box {
	border-radius: 0 3px 3px 0;
}
.cpnt-paginacao-prev-next {
	display: flex;
}
.cpnt-paginacao-prev-next-link {
	display: flex;
}

/* Botões */
.cpnt-paginacao-item a,
.cpnt-paginacao-sep a {
	background-color: #F9F9FB;
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 6px 6px;
	min-width: 20px;
	cursor: pointer;
}
.cpnt-paginacao-sep a {
	border: 1px solid #D7D7D7;
	padding: 5px 6px 6px;
	min-width: 20px;
}
.cpnt-paginacao-item + .cpnt-paginacao-item {
	border-left: 1px solid #D7D7D7;
}

/* Item ativo */
.cpnt-paginacao-item-ativo a {
	color: #FFF;
	background-color: #0277BD;
	border-color: #0277BD;
}

@media (max-width: 1080px) {
	.cpnt-paginacao-box {
		flex-direction: column-reverse;
	}
	.cpnt-paginacao-info-paginas {
		margin-right: 0;
		margin-top: 10px;
	}
	.cpnt-paginacao-texto-prev-next {
		display: none;
	}
	.cpnt-paginacao-btn-next-prev-box {
		padding: 4px 10px;
	}
}
