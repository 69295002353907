.cpnt-critsenha-box {
	position: relative;
	overflow: hidden;
}
.cpnt-critsenha-item {
	display: flex;
	align-items: center;
}
.cpnt-critsenha-texto {
	font-size: 15px;
	margin-left: 10px;
}
.cpnt-critsenha-item + .cpnt-critsenha-item {
	margin-top: 10px;
}

/* Sobrescrever */
.cpnt-critsenha .overlay-conteudo-icone-wrap {
	max-width: 50px;
}
.cpnt-critsenha .overlay-conteudo-texto {
	margin-top: 10px;
	font-size: 15px;
}
