/*
** Título das colunas
** ================================================== */
.tbl-projetos-col-produto {
	width: 100px;
}
.tbl-projetos-col-cenario {
	width: 100px;
}

@media (max-width: 1580px) {
	/* Time */
	.pg-projetos th.col-time {
		width: 114px;
	}

	/* Soluções */
	.pg-projetos th.col-solucoes {
		width: 78px;
	}

	.pg-projetos th.col-acao {
		width: 50px;
	}
	.pg-projetos th.col-min-3 {
		width: 55px;
	}
	.pg-projetos th.col-min-4 {
		width: 70px;
	}
}

@media (max-width: 1320px) {
	/* Time */
	.pg-projetos th.col-time {
		width: 80px;
	}
}

@media (max-width: 1180px) {
	.tbl-projetos-col-produto {
		width: 80px;
	}
	.tbl-projetos-col-cenario {
		width: 90px;
	}
}
