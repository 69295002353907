.cpnt-lista-titulodesc-box {
	display: flex;
	flex-direction: column;
}
.cpnt-lista-titulodesc-wrap {
	position: relative;
	display: flex;
	padding: 10px;
	cursor: pointer;
}
.cpnt-lista-titulodesc-wrap {
	background-color: #F7F8FA;
}
.cpnt-lista-titulodesc-wrap:nth-child(2n+1) {
	background-color: #FFF;
}
.cpnt-lista-titulodesc-img-box {
	min-width: 55px;
	max-width: 55px;
}
.cpnt-lista-titulodesc-info {
	display: flex;
	flex-direction: column;
	min-width: 0;
	padding-left: 10px;
	flex: 1;
}
.cpnt-lista-titulodesc-nome-data {
	font-size: 12px;
}
.cpnt-lista-titulodesc-wrap:hover {
	background-color: #e4ebee;
}
