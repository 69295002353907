.cpnt-voltar {
	align-self: center;
}
.cpnt-voltar-btn {
	border-radius: 3px;
	padding: 5px 6px;
	border: 1px solid #DFDFDF;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	font-size: 14px;
}
.cpnt-voltar-btn:hover {
	border-radius: 3px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.cpnt-voltar-texto {
	padding-left: 5px;
}