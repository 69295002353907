.cpnt-bullet {
	display: inline-flex;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.cpnt-bullet-ativo {
	background-color: #229d3e;
}
.cpnt-bullet-inativo {
	background-color: #E74C3C;
}
