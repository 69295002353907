.cpnt-barrah-icone-box {
	margin-right: 10px;
}
.cpnt-barrah-titulowrap {
	display: flex;
	align-items: center;
	font-weight: bold;
}
.cpnt-barrah-titulo-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border: 1px solid #DFDFDF;
	background-color: #FFF;
	border-radius: 3px;
	font-size: 14px;
}
.cpnt-barrah-titulo-nowrap {
	white-space: no-wrap;
}
.cpnt-barrah-acoes {
	display: flex;
	align-items: center;
	border-left: 1px solid #D5D5D5;
	padding-left: 10px;
	margin-left: 15px;
}
.cpnt-barrah-icone {
	width: 18px;
	height: 18px;
	cursor: pointer;
}
.cpnt-barrah-acao-box + .cpnt-barrah-acao-box {
	margin-left: 10px;
}
.cpnt-barrah-acao {
	padding: 5px;
	cursor: pointer;
}