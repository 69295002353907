.emoji-mart-anchor.emoji-mart-anchor-selected {
	color: #2596e1 !important;
}
.emoji-mart-anchor-bar {
	background-color: #2596e1 !important;
}


.emoji-mart-category-label {
	border-bottom: 1px solid #EEE;
	margin: 0 -10px;
	padding: 0 10px;
	background-color: #FFF;
}
.emoji-mart-preview {
	height: 40px;
}
.emoji-mart-title-label {
	font-size: 16px;
}
.emoji-mart-preview-data {
	left: 10px;
}
.emoji-mart-preview-skins {
	right: 10px;
	cursor: pointer;
}
