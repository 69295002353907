.container {
	display: flex;

	.req {
		color: var(--color-red);
		margin-left: 3px;
	}

	.icone-wrap {
		width: 30px;
		margin-right: 15px;
		height: 30px;
		display: flex;
		border-radius: var(--border-radius-50);
		overflow: hidden;

		.icone {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
		}
	}
}
