.cpnt-chatcontainer-full {
	display: flex;
	flex: 1 1;
	max-height: 100%;
	min-height: 0;
}
.cpnt-chatcontainer-box {
	background-color: #FFF;
	border-radius: 5px;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
	border: 1px solid #DDD;
	overflow: hidden;
}
.cpnt-chatcontainer-btn {
	position: fixed;
	left: 10px;
	bottom: 10px;
	z-index: 999;
	/* background-color: #FFF; */
	/* border: 1px solid #CCC; */

}
.cpnt-chatcontainer-btn-res {
	cursor: pointer;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

@media (max-width: 680px) {
	.cpnt-chatcontainer-box {
		border: 0;
	}
}