.footer {
	box-shadow: 0 -1px 1px rgba(0,0,0,0.05);
	background-color: #FFF;
	margin-top: 70px;
}
.footer-inner {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
	box-sizing: border-box;
}
.footer-col {
	padding: 50px 0;
}

/*
** Coluna 1
** ================================================== */
.footer-col-1-texto {
	padding: 30px 0;
}
.footer-logo {
	max-width: 180px;
}

/*
** Menu
** ================================================== */
.footer-menu {
	list-style: none;
	margin: 0;
	padding: 0;
}
.footer-menu a {
	text-decoration: none;
	color: #3D455C;
}
.footer-menu li + li {
	margin-top: 10px;
}
.footer-menu a:hover {
	color: #000;
	font-weight: bold;
}

/*
** Bottombar
** ================================================== */
.bottombar {
	background-color: #C7C9D1;
}
.bottombar-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
	font-size: 14px;
}
.bottombar-inner a {
	text-decoration: none;
	color: #3D455C;
}
.bottombar-termos {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

/*
** Outros
** ================================================== */
.footer-btn {
	cursor: pointer;
	background-color: #000;
	padding: 6px 7px;
	text-decoration: none;
	font-size: 14px;
	color: #FFF;
	border-radius: 5px;
	display: inline-flex;
	font-weight: bold;
	text-transform: uppercase;
}

@media (max-width: 980px) {
	.footer-inner {
		grid-template-columns: repeat(2, 1fr);
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 40px 0;
	}
	.footer-col {
		padding: 10px 0;
	}
	.footer-logo {
		margin: auto;
	}
}

@media (max-width: 680px) {
	.footer-inner {
		grid-template-columns: repeat(1, 1fr);
	}
	.bottombar-inner {
		flex-direction: column;
		row-gap: 10px;
		text-align: center;
	}
}