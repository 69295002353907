@import '@scss/imports.scss';

.container {
	max-width: 540px;

	.conteudo {
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
	}
	.circulos {
		display: flex;
		gap: var(--gap-large);
	}
	.circulo {
		width: 40px;
		height: 40px;

		&.active {
			box-shadow: 0 0 5px var(--color-1);
		}
	}
	.x {
		@extend %x;
	}
	.btn {
		margin-top: 30px;
	}

	.box-estrelas {
		margin-bottom: 15px;
	}

	@media (max-width: 580px) {
		.texto-dia {
			font-size: 4.4vw;
		}
		.texto-alerta {
			font-size: 2.9vw;
		}
		.texto-avalie {
			font-size: 4.4vw;
		}
		.texto-compartilhe {
			font-size: 2.9vw;
		}
	}

	@media (max-width: 420px) {
		.texto-dia {
			font-size: 4.2vw;
		}
		.texto-alerta {
			font-size: 2.7vw;
		}
		.texto-avalie {
			font-size: 4.2vw;
		}
		.texto-compartilhe {
			font-size: 2.7vw;
		}
	}
}
