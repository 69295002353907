.cpnt-checkbox2 label {
	cursor: pointer;
	display: inline-flex;
}
.cpnt-checkbox2 [type="checkbox"] {
	display: none;
}
.cpnt-checkbox2 .cpnt-checkbox2-check {
	position: relative;
	display: inline-flex;
	min-width: 14px;
	max-width: 14px;
	min-height: 14px;
	max-height: 14px;
	border-radius: 2px;
	border: 2px solid #929292;
	background-color: #FFF;
	transition: all 0.3s ease 0s;
}
.cpnt-checkbox2 .cpnt-checkbox2-label {
	font-size: 16px;
	padding-left: 10px;
	margin-top: -3px;
}
.cpnt-checkbox2 .cpnt-checkbox2-label-small {
	font-size: 13px;
	margin-top: 0;
}
.cpnt-checkbox2 [type="checkbox"]:checked ~ .cpnt-checkbox2-check {
	background-color: #2E2F31;
	background: #000 url('../../assets/imagens/icones/check.png') no-repeat center center;
	background-size: 90% 90%;
	border-color: #2E2F31;
}

.cpnt-checkbox2 a {
	font-weight: bold;
	text-decoration: none;
}
.cpnt-checkbox2-small-text .cpnt-checkbox2-label {
	font-size: 13px;
}
.cpnt-checkbox2-small-text .cpnt-checkbox2-label {
	margin-top: 0;
}
.cpnt-checkbox2-invalido .cpnt-checkbox2-check {
	border-color: #cf2c2c;
}
.cpnt-checkbox2-invalido .cpnt-checkbox2-label {
	color: #cf2c2c;
}
.cpnt-checkbox2-req {
	color: #cf2c2c;
	margin-left: 3px;
}
