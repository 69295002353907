.cpnt-loadingbar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cpnt-loadingbar-absolute {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	overflow: hidden;
}
.cpnt-loadingbar-barra {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 0;
	transition: all 1s linear 0s;
	background-color: rgba(34, 157, 63, 0.1);
}
.cpnt-loadingbar-infotexto {
	background-color: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(3px);
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.3);
	padding: 5px 10px;
	font-size: 14px;
	position: relative;
}
