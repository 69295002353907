/*
** Geral
** ================================================== */
.cpnt-loading-img {
	width: 100px;
}
.cpnt-loading-icone-wrap {
	padding-bottom: 20px;
}

.cpnt-loading-absolute {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
}
.cpnt-loading-wrap {
	margin: auto;
}
.cpnt-loading-bgalt {
	background-color: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(2px);
}
.cpnt-loading-bgalt2 {
	background-color: rgba(247, 248, 250, 0.7);
}
.cpnt-loading-bgalt3 {
	background-color: rgba(247, 248, 250, 0.95);
}
.cpnt-loading-absolute-input {
	top: -5px;
	bottom: -5px;
}
.cpnt-loading-texto-wrap {
	padding: 5px 10px;
	background-color: #FFF;
	border-radius: 3px;
}
.cpnt-loading-texto-default {
	text-align: center;
}

/* Alt */
.cpnt-loading-alt {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.cpnt-loading-alt .cpnt-loading-icone-wrap {
	padding-bottom: 0;
	padding-right: 20px;
}
.cpnt-loading-alt .cpnt-loading-img {
	width: 30px;
}
.cpnt-loading-min .cpnt-loading-img {
	width: 20px;
}
