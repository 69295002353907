.buscar-profissionais-subtitulo {
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 -10px;
}
.buscar-profissionais-selecionados {
	position: sticky;
	top: -1px;
	background-color: #FFF;
	z-index: 11;
	/* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
	border-top: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
}
.buscar-profissionais-selecionados:hover {
	/* z-index: 12; */
}

/* Infinite Scroll */
.infinite-scroll-component__outerdiv {
	width: 100%;
}
.infinite-scroll-component {
	height: auto;
	overflow: auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 1680px) {
	.buscar-profissionais-lista-box .buscar-profissionais-box {
		min-width: 50%;
		max-width: 50%;
	}
}