.portal {
	width: 100%;
}
.portal-wrap {
	margin: auto;
	min-width: 0;
	width: 100%;
}
.portal-conteudo {
	background-color: #F7F8FA;
}
.box-opcoes {
	display: flex;
	gap: 15px;
}
.box-opcao {
	flex: 1;
	padding: 15px;
}
.texto-como-funciona {
	color: #0277BD;
	margin: 10px 0 20px;
}
.box-opcao-meio {
	align-self: center;
}

.box-geral {
	display: flex;
}
.box-img {
	position: relative;
}
.box-img-bg {
	position: absolute;
	left: -25px;
	right: 0;
	top: -20px;
	bottom: -25px;
}
.box-form {
	padding-left: 30px;
	box-sizing: border-box;
}
.captcha-box {
	min-height: 78px;
}
.box-form-side {
	flex-basis: 50%;
}

@media (max-width: 1080px) {
	.box-img {
		flex-basis: 40%;
	}
	.box-form {
		flex-basis: 60%;
	}
}

@media (max-width: 980px) {
	.box-img {
		display: none;
	}
	.box-form {
		flex-basis: auto;
		padding-left: 0;
	}
}