.cpntpg-induser-texto {
	font-style: italic;
	margin-top: 10px;
}
.cpntpg-induser-texto:before {
	content: '"';
}
.cpntpg-induser-texto:after {
	content: '"';
}
.cpntpg-induser-nomedesc {
	margin-top: 20px;
}
