.cpnt-imgdesc-foto-box {
	position: relative;
	z-index: 10;
}
.cpnt-imgdesc-box {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.cpnt-imgdesc-foto-preview-box {
	width: 200px;
}
.cpnt-itemfotodesc-img,
.cpnt-imgdesc-foto-preview {
	position: relative;
}
.cpnt-imgdesc-info-box {
	max-width: 100%;
}
@media (max-width: 680px) {
	.cpnt-imgdesc-resp {
		flex-direction: column;
	}
	.cpnt-imgdesc-resp .cpnt-imgdesc-info-box {
		text-align: center;
	}
	.cpnt-imgdesc-foto-box {
		margin-right: 0;
		margin-bottom: 10px;
	}
}