.cadprocsel-box {
	padding: 30px 0;
}
.cadprocsel-info-steps {
	margin-left: 30px;
}
.cadprocsel-info-rascunho {
	margin-left: 30px;
}
.pg-carprocsel-bottombar {
	display: flex;
	align-items: center;
}
.cadprocsel-radio-tipo {
	margin-right: 30px;
}
.cadprocsel-select-box {
	display: flex;
}
.cadprocsel-select {
	min-width: 250px;
}
.cadprocsel-select-boxbtn {
	display: flex;
}
.pg-carprocsel-bottombar-btn-r-box {
	margin-left: auto;
}

/*
** Passo 2
** ================================================== */
.cadprocsel-p2-titulo-box {
	display: flex;
}
.cadprocsel-p2-btn-template {
	padding-top: 5px;
	margin-left: 20px;
}

/*
** Ações
** ================================================== */
.cadprocsel-acoes-pesquisa {
	margin-top: -1px;
	margin-bottom: -1px;
	padding: 10px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	background-color: #FFF;
	display: flex;
	justify-content: space-between;
}
.cadprocsel-acoes-btn {
	display: flex;
}

/*
** Final
** ================================================== */
.cadprocsel-overlay-sucesso {
	width: 700px;
}

@media (max-width: 1380px) {
	.cadprocsel-select {
		min-width: 200px;
	}
}

@media (max-width: 1080px) {
	body .cadprocsel-topbar-info {
		flex-direction: column;
		align-items: flex-start;
	}
	.cadprocsel-info-steps {
		margin-left: 0;
	}
}

@media (max-width: 780px) {
	.pg-cadprocsel .titulo-box {
		flex-direction: column;
	}
	.cadprocsel-select-box {
		margin-top: 15px;
	}
	body .cadprocsel-topbar-info {
		justify-content: center;
	}
}

@media (max-width: 680px) {
	.cadprocsel-radio {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.cadprocsel-radio-tipo + .cadprocsel-radio-tipo {
		margin-top: 10px;
	}
	.cadprocsel-radio-tipo {
		margin-right: 0;
	}
	.cadprocsel-p2-titulo-box {
		flex-direction: column;
		margin-bottom: 5px;
	}
	.cadprocsel-p2-btn-template {
		padding-top: 5px;
		margin-left: 0;
		padding-left: 0;
	}
}

@media (max-width: 580px) {
	.cadprocsel-select-box {
		margin-top: 15px;
		flex-direction: column;
	}
	.cadprocsel-select-boxbtn {
		margin-top: 10px;
	}
	.cadprocsel-select-boxbtn .cadprocsel-select-rascunho {
		margin-left: 0;
	}
	.cadprocsel-select-box .cadprocsel-q-info {
		margin: 0 0 10px;
	}
	.pg-carprocsel-btn-pesquisar .cpnt-textoicone-texto {
		display: none;
	}
	.pg-carprocsel-btn-pesquisar .cpnt-textoicone-icone {
		margin-right: 0;
	}
	.cadprocsel-acoes-btn {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	.cadprocsel-acoes-btn .cadprocsel-acoes-btn-manual {
		margin-left: 0;
		margin-top: 10px;
	}
}
