.container {
	background-color: var(--color-2);
	border-radius: var(--border-radius);
	display: flex;
	flex-direction: column;
	gap: var(--gap-item);
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: var(--gap-large);
	cursor: pointer;
	text-decoration: none;
	color: inherit;

	@media (max-width: 680px) {
		padding: var(--gap-item);
	}
}
