.cpnt-portallateral {
	z-index: 999;
}
.cpnt-portallateral,
.cpnt-portallateral-bg {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
.cpnt-portallateral-wrap-box {
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	background: rgba(77, 80, 88, 0.8);
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
}
.cpnt-portallateral-bg {
	z-index: -1;
}

.cpnt-portallateral-wrap {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	background: white;
	width: 400px;
	max-height: 100%;
	max-width: 90vw;
}
.cpnt-portallateral-wrap-left {
	right: unset;
	left: 0;
}
.cpnt-portallateral-inner {
	position: relative;
	max-height: 100%;
	height: 100%;
}

.cpnt-portallateral-close {
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 15px;
	height: 15px;
	z-index: 10;
	padding: 5px;
}
.cpnt-portallateral-close:before {
	content: '';
	mask: url('./x-cinza.png') no-repeat center center;
	mask-size: contain;
	background-color: #6A6A6A;
	position: absolute;
	left: 2px;
	top: 2px;
	right: 2px;
	bottom: 2px;
}
.cpnt-portallateral-default {
	height: 100%;
    max-height: 100%;
    min-height: 0;
}
.cpnt-notificacoes {
	height: 100%;
}
.cpnt-notificacoes-box {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.cpnt-notificacoes-conteudo {
	height: 100%;
	min-height: 0;
	max-height: 100%;
	flex: 1 1;
	overflow-y: auto;
	background-color: #ECF0F2;
}
.cpnt-portallateral-default-alt {
	background-color: #FFF;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	border-radius: 3px;
	/* overflow: hidden; */
}
