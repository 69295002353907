.estrelas {
	font-size: 52px;
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 6px;

	.rate i,
	.rate i:hover ~ i {
		color: #CCC;
		text-shadow: none;
		transition: color 200ms, text-shadow 200ms;
		transition-delay: 0;
	}

	.rate:hover i,
	i.active {
		color: #fc0;
		text-shadow: rgba(255, 204, 0, 0.5) 0 0 15px;
	}

	.rate:hover i:nth-child(2) {
		transition-delay: 20ms;
	}

	.rate:hover i:nth-child(3) {
		transition-delay: 40ms;
	}

	.rate:hover i:nth-child(4) {
		transition-delay: 60ms;
	}

	.rate:hover i:nth-child(5) {
		transition-delay: 80ms;
	}

	.rate i {
		filter: drop-shadow(0 0 1px rgba(255,255,255,0.5));
		cursor: pointer;
		font-style: normal;
	}

	// .estrelas {
	// 	width: 100%;
	// 	display: flex;
	// 	align-items: center;

	// 	.estrela {
	// 		width: 50px;
	// 		height: 50px;
	// 		border: 1px solid var(--color-yellow);
	// 		box-sizing: border-box;

	// 		background: url('./star.svg') no-repeat center center;
	// 		background-size: 100% 100%;

	// 	}

	// 	&:hover > .estrela {
	// 		background-image: url('./star.svg');
	// 	}

	// 	&:hover > .estrela:hover ~ .estrela {
	// 		background-image: url('./star-fill.svg');
	// 	}
	// }

}