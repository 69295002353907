.tela-detalhesjob {
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 0;
}
.tela-detalhejob-container {
	display: flex;
	flex: 1;
	max-height: 100%;
	min-height: 0;
	padding: 10px;
}
.tela-detalhejob-info-wrap {
	padding: 15px 20px;
	background-color: #FBFBFB;
	border-bottom: 1px solid #DDD;
}
.tela-detalhejob-info-box {
	min-width: 400px;
	max-width: 400px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.tela-detalhejob-chat-box {
	margin-left: 10px;
	display: flex;
	max-height: 100%;
	height: 100%;
	width: 100%;
}

/* Lado esquerdo */
.tela-detalhejob-sec + .tela-detalhejob-sec {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid #EEE;
}

/* Hunter */
.tela-detalhejob-info-hunter {
	font-size: 14px;
}

/* Equipe */
.tela-detalhejob-info-equipe {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Habilidades */
.tela-detalhejob-info-dados {
	font-size: 16px;
	max-height: 100%;
	overflow-y: auto;
	flex: 1;
	padding: 20px;
}

@media (max-width: 1580px) {
	.tela-detalhejob-info-box {
		min-width: 300px;
		max-width: 300px;
	}
}

@media (max-width: 980px) {
	.tela-detalhejob-info-box {
		display: none;
	}
	.tela-detalhejob-chat-box {
		margin-left: 0;
	}
}
