.foto-borda-inner {
	border-radius: 50%;
}
.foto-borda-1 {
	border-width: 1px;
}
.foto-borda-2 {
	border-width: 2px;
}
.foto-borda-3 {
	border-width: 3px;
}
.foto-borda-4 {
	border-width: 4px;
}
.foto-borda-5 {
	border-width: 5px;
}
