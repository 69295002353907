/*
** Geral
** ================================================== */
.td-wrap {
	max-width: 100%;
	overflow: hidden;
	width: 100%;
}
.td-wrap-placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
}
.tabela-titulo-fixo {
	position: sticky;
	top: 0;
	z-index: 10;
}

/*
** Conteúdo
** ================================================== */
.td-info {
	display: inline-flex;
	flex-direction: column;
	max-width: 100%;
}
.td-titulo {
	font-weight: bold;
}
.td-desc {
	color: #6c7c82;
}
.td-desc-alt {
	color: #6c7c82;
	font-size: 12px;
	font-weight: normal;
}

/*
** Colunas e linhas
** ================================================== */

/* id */
th.col-id {
	width: 60px;
}
td.col-id {
	font-weight: bold;
	text-align: center;
	font-size: 12px;
}

/* responsável */
th.col-responsavel {
	width: 90px;
}

/* data */
th.col-data {
	width: 105px;
}

/* valor */
th.col-valor {
	width: 120px;
}

/* Editar */
th.col-acao {
	width: 56px;
}

/* Telefone */
th.col-telefone {
	width: 145px;
}

/* Categoria */
th.col-area {
	width: 150px;
}

/* Min */
th.col-min {
	width: 50px;
}
th.col-min-2 {
	width: 60px;
}
th.col-min-3 {
	width: 70px;
}
th.col-min-4 {
	width: 80px;
}
th.col-min-5 {
	width: 90px;
}
th.col-100 {
	width: 100px;
}
th.col-120 {
	width: 120px;
}

/* Soluções */
th.col-solucoes {
	width: 120px;
}

/* Time */
th.col-time {
	width: 158px;
}

/*
** Tabela
** ================================================== */
.table-wrap {
	margin: 10px;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.tabela {
	border-collapse: collapse;
	width: 100%;
	font-size: 14px;
	max-width: 100%;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
}
.tabela .a:hover {
	text-decoration: underline;
}
.tabela [data-titulo="ID"] {
	font-size: 12px;
}

/*
** Tabela placeholder
** ================================================== */
.tabela-placeholder {
	border-collapse: collapse;
	width: 100%;
	font-size: 14px;
	max-width: 100%;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
}
.tabela-placeholder-container {
	margin: 10px;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
	border: 1px solid #D7D7D7;
}
.table-placeholder-item-box:not(:first-child) {
	border-top: 1px solid #D7D7D7;
}
.table-placeholder-item-box:nth-child(even) {
	background-color: #F7F9FA;
}
.table-placeholder-item-box:nth-child(odd) {
	background-color: #FFF;
}
.tabela-placeholder th {
	text-align: center;
	font-size: 14px;
	padding: 10px;
	box-sizing: border-box;
}

/* TH */
.tabela th {
	text-align: center;
	color: #FFF;
	background-color: #2E2F31;
	font-size: 14px;
	border-top: 1px solid #D7D7D7;
	position: relative;
}

/* TD */
.tabela td,
.tabela th {
	border-left: 1px solid #D7D7D7;
	border-right: 1px solid #D7D7D7;
	padding: 10px;
	box-sizing: border-box;
}
.tabela td {
	word-break: break-word;
}
.tabela-td-alt td {
	padding-top: 3px;
	padding-bottom: 3px;
}
.tabela td.shrink {
	width: 0.1%;
}
.tabela td.grow {
	width: 100%;
}

/* TR */
.tabela tr:nth-child(even) {
	background-color: #FFF;
}
.tabela tr:nth-child(odd) {
	background-color: #F7F9FA;
}
.tabela tr {
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
}
.tabela tr:not(.tr-disabled):hover {
	background-color: #e4ebee;
}
.tabela tr.tr-checked {
	background-color: #DFEEF4;
}
.tabela tr.tr-checked:hover {
	background-color: #c8e5ee;
}

@media (max-width: 1680px) {
	/* Geral */
	.tabela th {
		font-size: 12px;
	}
	.tabela td {
		padding: 5px 7px;
		font-size: 13px;
	}

	/* id */
	th.col-id {
		width: 55px;
	}
	td.col-id {
		font-size: 11px;
	}

	/* responsável */
	th.col-responsavel {
		width: 80px;
	}

	/* valor */
	th.col-valor {
		width: 110px;
	}

	/* subtitulo */
	.tabela .col-subtitulo {
		font-size: 12px;
	}
}

@media (max-width: 1580px) {
	/* Geral */
	.tabela th,
	.tabela td {
		font-size: 12px;
		padding: 5px;
	}

	/* responsavel */
	th.col-responsavel {
		width: 65px;
	}

	/* data */
	th.col-data {
		width: 90px;
	}

	/* Telefone */
	th.col-telefone {
		width: 117px;
	}

	/* valor */
	th.col-valor {
		width: 90px;
	}
}

@media (max-width: 980px) {
	.tabela thead {
		display: none;
	}

	/* TD */
	.tabela td {
		display: flex;
	}
	.tabela td::before {
		content: attr(data-titulo);
		font-weight: bold;
		background-color: #2E2F31;
		color: #FFF;
		min-width: 150px;
		max-width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 5px 8px;
		text-align: center;
	}
	.tabela td + td {
		border-top: 1px solid #D7D7D7;
	}
	.tabela tr td {
		padding: 0;
	}
	.tabela td,
	.tabela th {
		border-left: 0;
		border-right: 0;
	}
	.tabela td {
		background-color: #FAFAFA;
	}
	.tabela td:nth-child(odd) {
		background-color: #FFF;
	}
	.td-wrap {
		padding: 10px 15px;
		box-sizing: border-box;
		width: 100%;
		font-size: 14px;
		text-align: center;
	}

	/* TR */
	.tabela tr {
		border-radius: 5px;
		overflow: hidden;
		background-color: #FFF;
		border: 1px solid #2E2F31;
	}
	.tabela tr + tr {
		margin-top: 15px;
	}
	.tabela tr {
		display: block;
	}

	/*
	** Componentes
	** ================================================== */

	/* Select3 */
	.tabela .cpnt-select3-dropdown-left {
		transform: none;
		position: static;
		margin: 10px 0 10px;
		box-shadow: none;
	}
	.tabela .cpnt-select3-dropdown:before,
	.tabela .cpnt-select3-dropdown:after {
		content: none;
	}
}

@media (max-width: 580px) {
	.tabela td::before {
		min-width: 110px;
		max-width: 110px;
	}
}
