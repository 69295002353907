.cpnt-textowrap-box {
	padding: 5px 20px;
	background-color: #F2F2F2;
	border-radius: 50px;
}
.cpnt-textowrap-subtitulo {
	color: #757575;
	font-size: 0.8em;
	margin-top: -3px;
	padding-bottom: 3px;
}

/* Cor 1 */
.cpnt-textwrap-coralt-1 {
	color: #2E2F31;
}
.cpnt-textwrap-coralt-1 .cpnt-textowrap-box {
	background-color: #F2F2F2;
}
.cpnt-textwrap-coralt-1 .cpnt-textowrap-subtitulo {
	color: #757575;
}

/* Cor 2 */
.cpnt-textwrap-coralt-2 {
	color: #FFF;
}
.cpnt-textwrap-coralt-2 .cpnt-textowrap-box {
	background-color: #404040;
}
.cpnt-textwrap-coralt-2 .cpnt-textowrap-subtitulo {
	color: #9f9f9f;
}

/* Cor 2 */
.cpnt-textwrap-borda {
	border: 1px solid #DFDFDF;
}

/* Cor 3 */
.cpnt-textwrap-coralt-3  .cpnt-textowrap-box {
	background-color: #FFF;
	border: 1px solid #DDD;
}
.cpnt-textwrap-coralt-3 .cpnt-textowrap-subtitulo {
	color: #9f9f9f;
}
