/*
** Default
** https://fkhadra.github.io/react-toastify/how-to-style/
** ================================================== */
.Toastify__toast-container {
	width: 360px;
	word-break: break-word;
	z-index: 999999;
}
.Toastify__toast {
	border-radius: 5px;
	padding: 20px 20px 22px;
	font-size: 16px;
	font-family: inherit;
	position: relative;
}
.Toastify__close-button {
	position: absolute;
	top: 4px;
	right: 4px;
}
.Toastify__close-button > svg {
	height: 20px;
	width: 20px;
}

/* Skin Geral */
.Toastify__progress-bar {
	background-color: rgba(0, 0, 0, 0.27);
}

/* Success Skin */
.Toastify__toast--success {
	background: #229d3e;
	/* E74C3C */
}

/* Default Skin */
.Toastify__progress-bar--default {
	background: #c8c8c8;
}
.Toastify__toast--default {
	color: #333;
}

/* Info Skin */
.Toastify__toast--info {
	background: #2596e1;
}

/* Warning Skin */
.Toastify__toast--warning {
	background: #eab20e;
}

/* Dark */
.Toastify__toast--dark {
	background: #4d5058;
	color: #fff;
}
.Toastify__progress-bar--dark {
	background: #717171;
}

/* Error Skin */
.Toastify__toast--error a {
	color: #FFF;
	font-weight: bold;
}

@media (max-width: 480px) {
	.Toastify__toast-container {
		width: 100%;
	}
	.Toastify__toast-container--bottom-left,
	.Toastify__toast-container--bottom-center,
	.Toastify__toast-container--bottom-right {
		bottom: 0;
		right: 0;
	}

	.Toastify__toast {
		border-radius: 0;
		padding: 20px 20px 22px;
		font-size: 16px;
		font-family: inherit;
		position: relative;
		margin-bottom: 0;
	}

}
