.formcliente-info-container {
	display: flex;
}
.formcliente-logo-box {
	min-width: 170px;
	max-width: 170px;
	padding: 5px;
}
.formcliente-logo-info-box {
	flex: 1 1;
	padding-left: 10px;
	display: flex;
	flex-wrap: wrap;
}
