/* Container */
.cpnt-chat-wrap {
	display: flex;
	flex: 1;
	min-width: 0;
}
.cpnt-chat {
	background-color: #FFF;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;
}
.cpnt-chat-wrapdefault {
	border-radius: 5px;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
	border: 1px solid #DDD;
}

/* Chat */
.cpnt-chat-box-wrap {
	display: flex;
	flex: 1;
	max-height: 100%;
	position: relative;
	flex-direction: column;
	min-height: 0;
}
.cpnt-chat-box {
	position: relative;
	display: flex;
	flex-direction: column;
	background: url('../../assets/imagens/bg/bg-alt2.png') repeat;
    animation: bgAnimadoTopLeft 120s linear infinite;
	will-change: backrground-position;
	max-height: 100%;
	overflow-y: auto;
	flex: 1 1;
	scroll-snap-align: end;
	padding-bottom: 40px;
}
.cpnt-chat-digitando {
	position: absolute;
	left: 10px;
	bottom: 6px;
	font-size: 13px;
	z-index: 10;
}
.cpnt-chat-item {
	display: flex;
	padding: 15px;
}
.cpnt-chat-sem-mensagem-wrap {
	align-self: center;
	margin: auto;
	padding: 15px;
}
.cpnt-chat-sem-mensagem {
	/* border: 1px solid red; */
}

/* Item */
.cpnt-chat-item:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.02);
}

/* Titulo chat */
.cpnt-chat-titulo-wrap {
	font-size: 16px;
	padding: 10px 15px;
	background-color: #FBFBFB;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* Footer */
.cpnt-chat-footer-wrap {
	border-top: 1px solid #DDDDDD;
	display: flex;
	padding: 10px;
}
.cpnt-chat-footer-info {
	display: flex;
	align-items: center;
	padding: 5px;
	border-top: 1px solid #DDDDDD;
}
.cpnt-chat-footer {
	background-color: #FBFBFB;
	position: relative;
}
.cpnt-chat-mensagem-bloqueado {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(251, 251, 251, 0.7);
	z-index: 2;
	font-size: 16px;
}

/* Mensagem */
.cpnt-chat-mensagem-box {
	flex: 1;
	padding-left: 15px;
}
.cpnt-chat-acoes {
	display: flex;
	align-items: flex-start;
}
.cpnt-chat-btn-box {
	display: flex;
}

/* Emoji */
.cpnt-chat-emoji-box {
	position: relative;
}
.cpnt-chat-emoji-wrap {
	position: absolute;
	left: 7px;
	bottom: 41px;
	box-shadow: 0 3px 10px rgba(0,0,0,0.1);
	border-radius: 5px;
}
.cpnt-chat-mensagem {
	width: 100%;
	box-sizing: border-box;
	padding: 8px 10px;
	border: 1px solid #DDDDDD;
	border-radius: 4px;
	background-color: #FFF;
	outline: none;
	font-family: inherit;
	min-height: 44px;
	margin: 0;
	min-width: 100px;
}
.cpnt-chat-btnenviar-box {
	padding-left: 20px;
	display: flex;
	align-items: flex-start;
}
.cpnt-chat-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	box-sizing: border-box;
	padding: 8px;
	border: 0;
	border-radius: 50%;
	background-color: transparent;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	min-height: 40px;
}
.cpnt-chat-btn-icone {
	background-color: #2E2F31;
	transition: all 0.3s ease 0s;
}
.cpnt-chat-btn:hover .cpnt-chat-btn-icone {
	background-color: #2596e1;
}

/* .cpnt-chat-btn-proposta {
	display: flex;
	align-items: center;
	background-color: #2E2F31;
	font-size: 15px;
	box-sizing: border-box;
	padding: 0 10px;
} */

/*
** Preview de respostas
** ================================================== */
.cpnt-chatmsg-resposta-preview {
	display: flex;
}
.cpnt-chatmsg-resposta-preview-msg {
}
.cpnt-chatmsg-resposta-preview-acoes {
	padding-left: 50px;
}
.cpnt-chat-notificacoes-txt {
	display: none;
}

@media (max-width: 980px) {
	.cpnt-chat-acoes {
		width: 100%;
		margin-bottom: 10px;
	}
	.cpnt-chat-footer-wrap {
		flex-wrap: wrap;
	}
	.cpnt-chat-mensagem-box {
		padding-left: 0;
	}
	.cpnt-chat-btnenviar-box {
		padding-left: 5px;
		align-items: center;
	}
	.cpnt-chat-notificacao {
		display: flex;
		align-items: center;
	}
	.cpnt-chat-notificacoes {
		flex-direction: column;
		margin-right: 15px;
	}
	.cpnt-chat-notificacoes-txt {
		display: block;
		font-size: 12px;
	}
}

@media (max-width: 680px) {
	.cpnt-chat-box-wrap {
		min-height: 100px;
	}
	.cpnt-foto-img {
		width: 40px;
		height: 40px;
	}
	.cpnt-chat-item {
		padding: 5px;
	}
}