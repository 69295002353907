.box {
	display: inline-flex;
	align-items: center;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 12px;
}
.box-disponivel {
	background-color: #EBF5EA;
}
.box-indisponivel {
	background-color: #f5eaea;
}

.bullet {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.bullet-disponivel {
	background-color: #4E9F4B;
}
.bullet-indisponivel {
	background-color: #9f4b4b;
}

.texto {
	margin-left: 10px;
}