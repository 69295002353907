.cpnt-avaliacoes-linhasep {
	flex: 1 1;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 0 5px 3px;
	align-self: flex-end;
	min-width: 20px;
}
.cpnt-avaliacoes-item {
	display: flex;
	align-items: center;
}
.cpnt-avaliacoes-item + .cpnt-avaliacoes-item {
	margin-top: 5px;
}
.cpnt-avaliacoes-item-numero {
	margin: 0 10px;
	color: #AAA;
}
