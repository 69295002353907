.cpnt-perfilusuario-header {
	width: 100%;
	display: flex;
	align-items: center;
	background: #222 url('./bg.png');
    animation: bgAnimadoTopLeft 120s linear infinite;
	box-sizing: border-box;
	padding: 30px;
}
.cpnt-perfilfreelancer-info-box {
	margin: 10px 15px 0;
}

.cpnt-perfilusuario-nome-container {
	flex: 1;
	padding: 0 0 0 20px;
}
.cpnt-perfilusuario-img {
	width: 180px;
	margin: 0 85px;
}
.cpnt-perfilusuario-nome {
	font-size: 28px;
	font-weight: bold;
}
.cpnt-perfilusuario-infobox {
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 14px;
}
.cpnt-perfilusuario-info {
	font-size: 15px;
}

.cpnt-perfilusuario-empersainfo {
	margin-top: 10px;
}
.cpnt-perfilusuario-empresainfo-dados {
	margin-top: 10px;
	display: flex;
}
.cpnt-perfilusuario-empresainfo-dados-2 {
	margin-left: 30px;
}