.cpnt-pesquisaalgolia-resultado-popup {
	position: relative;
}
.cpnt-pesquisaalgolia-resultado-lista {
	position: absolute;
	top: 5px;
	left: 0;
	right: 0;
	background-color: #FFF;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 3px 7px rgba(0,0,0,0.1);
	border: 1px solid #DDD;
	z-index: 11;
	max-height: 400px;
	overflow-y: auto;
	scrollbar-width: thin;
}
.cpnt-pesquisaalgolia-inputbox {
	padding: 10px;
}
.cpnt-pesquisaalgolia-resultado-lista::-webkit-scrollbar {
	width: 12px;
}
.cpnt-pesquisaalgolia-resultado-lista::-webkit-scrollbar-track {
	background: #F0F0F0;
}
.cpnt-pesquisaalgolia-resultado-lista::-webkit-scrollbar-thumb {
	background-color: #CDCDCD;
	border-radius: 20px;
	border: 3px solid #F0F0F0;
}

.cpnt-pesquisaalgolia-resultado-head {
	position: sticky;
	top: 0;
	background-color: #FFF;
	z-index: 11;
	border-bottom: 1px solid #DDD;
}
.cpnt-pesquisaalgolia-selecionados {
	position: relative;
	border-top: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	padding: 3px;
	white-space: nowrap;
	max-width: 100%;
	overflow-x: auto;
	scrollbar-width: thin;
}
.cpnt-pesquisaalgolia-selecionados-inputstyle {
	border: 1px solid #2E2F31;
	border-radius: 4px;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
	flex-wrap: wrap;
	background-color: #FFF;
	width: 100%;
	min-height: 44px;
}
.cpnt-pesquisaalgolia-selecionados::-webkit-scrollbar {
	height: 12px;
}
.cpnt-pesquisaalgolia-selecionados::-webkit-scrollbar-track {
	background: #F0F0F0;
}
.cpnt-pesquisaalgolia-selecionados::-webkit-scrollbar-thumb {
	background-color: #CDCDCD;
	border-radius: 20px;
	border: 3px solid #F0F0F0;
}
.cpnt-pesquisaalgolia-info {
	padding: 5px;
	font-size: 12px;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #DDD;
}
.cpnt-pesquisaalgolia-resultado-item {
	position: relative;
	cursor: pointer;
	padding: 5px;
	border-top: 1px solid #DDD;
	box-sizing: border-box;
}
.cpnt-pesquisaalgolia-resultado-item:hover {
	background-color: #F9F9FB;
}
.cpnt-pesquisaalgolia-resultado-item-selecionado {
	background-color: #edf7ff;
}
.cpnt-pesquisaalgolia-resultado-item-selecionado.cpnt-pesquisaalgolia-resultado-item:hover {
	background-color: #e6f4ff;
}
.cpnt-pesquisaalgolia-remover.x {
	right: 3px;
	top: 5px;
}
.cpnt-pesquisaalgolia-itemsingle-selecionado {
	font-size: 14px;
	padding: 2px 7px;
	pointer-events: none;
}
.cpnt-pesquisaalgolia-x {
	cursor: pointer;
	position: absolute;
	background: #FFF url('./x-select.png') no-repeat center center;
	right: 9px;
	top: 50%;
	transform: translateY(-50%);
	width: 22px;
	height: 22px;
	background-size: cover;
	z-index: 10;
	padding: 5px;
	box-sizing: border-box;
}
.cpnt-pesquisaalgolia-infousuario {
	position: absolute;
	right: 3px;
	bottom: 3px;
	width: 16px;
	height: 16px;
	opacity: 0.8;
	border-radius: 50%;
	background-color: #FFF;
	border: 1px solid #0277BD;
	display: flex;
	justify-content: center;
	align-items: center;
}