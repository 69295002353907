.cpnt-chatmsgcustom-templates-lista {
	display: flex;
	flex-wrap: wrap;
	margin: 5px -10px -10px;
}

/*
** Checkbox
** ================================================== */
.cpnt-chatmsgcustom-templates-lista .cpnt-chatmsgcustom-radio-select-template {
	margin: 2px 10px;
}
.cpnt-chatmsgcustom-templates-lista .cpnt-chatmsgcustom-radio-label-template {
	font-size: 14px;
	padding-left: 5px;
	margin-top: -1px;
}

/*
** Parte de baixo
** ================================================== */
.cpnt-chatmsgcustom-dados-preview {
	display: flex;
}

/*
** Lado esquerdo
** ================================================== */
.cpnt-chatmsgcustom-dados-mensagem-box {
	min-width: 350px;
	max-width: 350px;
}
.cpnt-chatmsgcustom-dados-mensagem {
	margin-top: 10px;
}

/*
** Lado direito
** ================================================== */
.cpnt-chatmsgcustom-preview-box {
	padding-left: 50px;
	flex: 1;
}
.cpnt-chatmsgcustom-preview {
	margin-top: 15px;
}
.cpnt-chatmsgcustom-preview .cpnt-chatmsg-mensagem-box {
	flex: 1;
}
.cpnt-chatmsgcustom-info-footer {
	margin-top: 20px;
	padding-left: 85px;
}

/*
** Botões
** ================================================== */
.cpnt-chatmsg-btns-box {
	margin-top: 15px;
}
.cpnt-chatmsg-btns-item .x {
	right: -4px;
	top: 3px;
}

@media (max-width: 980px) {
	.cpnt-chatmsgcustom-dados-preview {
		flex-direction: column;
	}
	.cpnt-chatmsgcustom-dados-mensagem-box {
		min-width: auto;
		max-width: none;
	}
	.cpnt-chatmsgcustom-preview-box {
		padding-left: 0;
		padding-top: 25px;
	}
}
