.lista-jobsadmin-col-id {
	width: 60px;
	max-width: 60px;
}
.lista-jobsadmin-col-responsavel,
.lista-jobsadmin-col-freelancer,
.lista-jobsadmin-col-negociacao {
	width: 105px;
	max-width: 105px;
}
.lista-jobsadmin-col-data {
	width: 110px;
	text-align: center;
}
.lista-jobsadmin-col-valor {
	width: 110px;
	text-align: center;
}
.lista-jobsadmin-col-status {
	width: 110px;
	text-align: center;
}
.lista-jobsadmin-col-nf {
	width: 75px;
	text-align: center;
}
.lista-jobsadmin-col-acoes {
	width: 70px;
	text-align: center;
}

/* Popup */
.cpnt-process-info-dados {
	display: flex;
	align-items: flex-start;
}

.cpnt-process-info-dados-geral {
	flex: 1;
}
.cpnt-process-info-dados-acoes {
	min-width: 400px;
	padding-left: 80px;
}

@media (max-width: 1780px) {

	/* Tabela */
	div .lista-jobsadmin table {
		font-size: 12px;
	}
	div .lista-jobsadmin th {
		font-size: 12px;
		padding: 3px;
	}
	div .lista-jobsadmin td {
		padding: 3px;
	}

	.lista-jobsadmin-col-id {
		width: 50px;
		max-width: 50px;
	}
	.lista-jobsadmin-col-responsavel,
	.lista-jobsadmin-col-freelancer,
	.lista-jobsadmin-col-negociacao {
		width: 70px;
		max-width: 70px;
	}
	.lista-jobsadmin-col-data {
		width: 85px;
		text-align: center;
	}
	.lista-jobsadmin-col-valor {
		width: 85px;
		text-align: center;
	}
	.lista-jobsadmin-col-status {
		width: 95px;
		text-align: center;
	}
	.lista-jobsadmin-col-nf {
		width: 55px;
		text-align: center;
	}
	.lista-jobsadmin-col-acoes {
		width: 45px;
		text-align: center;
	}
}

@media (max-width: 980px) {
	.cpnt-process-info-dados {
		flex-direction: column;
	}
	.cpnt-process-info-dados-acoes {
		min-width: auto;
		padding-left: 0;
		padding-top: 20px;
	}
}
