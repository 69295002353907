.cpnt-usuariosqtdresultado {
	flex: 1;
}
.cpnt-usuariosqtdresultado-inner {
	display: flex;
	justify-content: space-between;
	font-size: 15px;
}
.cpnt-usuariosqtdresultado-box {
	display: flex;
	align-items: center;
}
.cpnt-usuariosqtdresultado-item + .cpnt-usuariosqtdresultado-item:before {
	content: '•';
	display: inline-flex;
	margin: 0 10px;
	opacity: 0.5;
}

@media (max-width: 680px) {
	.cpnt-usuariosqtdresultado-box + .cpnt-usuariosqtdresultado-box {
		margin-top: 5px;
	}
	.cpnt-usuariosqtdresultado-inner {
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 480px) {
	.cpnt-usuariosqtdresultado-box {
		flex-direction: column;
	}
	.cpnt-usuariosqtdresultado-item + .cpnt-usuariosqtdresultado-item::before {
		content: none;
	}
}
