@import '@scss/imports.scss';

.espacamento {
	display: flex;
	flex-direction: column;
	gap: var(--gap-item);
}
.box-radio {
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap-inputs);
}
.box-inputs {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: var(--gap-small) var(--gap-item);
	position: relative;

	@media (max-width: 980px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (max-width: 680px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.box-template {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: var(--gap-inputs);

	.btn-template {
		align-self: flex-start;
	}

	.btn-match {
		margin-top: var(--gap-item);
		margin-left: auto;
	}
}

.info-selecao {
	@extend %flex;
	@extend %fill;
	padding: var(--gap-item);
	z-index: 10;
	background-color: var(--color-bg-aplha-0-7);
	font-weight: bold;

	.info-selecao-texto {
		background-color: var(--color-bg-aplha-0-7);
		padding: var(--padding-btn);
	}
}
