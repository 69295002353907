.icone {
	font-size: var(--fs-icone-g);
}
.titulo {
	font-weight: bold;
	font-size: var(--fs-titulo-m);
}

@media (max-width: 680px) {
	.icone {
		font-size: var(--fs-icone-m);
	}
	.titulo {
		font-size: var(--fs-default);
	}
}