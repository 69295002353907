.cpnt-optionalgolia-box {
	position: relative;
	padding-right: 25px;
}
.cpnt-optionalgolia-info {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	cursor: pointer;
}
.cpnt-optionalgolia-box .cpnt-optionalgolia-desc-selected {
	color: #DDD;
}