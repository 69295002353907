.cpnt-statusradio {
	position: absolute;
	background: #FAFAFA;
	border-radius: 5px;
	width: 200px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	z-index: 11;
}

/* Conteudo */
.cpnt-statusradio-box {
	padding: 20px 15px 15px;
}
.cpnt-statusradio-item + .cpnt-statusradio-item {
	margin-top: 5px;
}

/* Posição */
.cpnt-statusradio:before,
.cpnt-statusradio:after {
	content: '';
	border: 10px solid transparent;
}

/* Esquerda seta lado direito */
.cpnt-statusradio-left {
	right: 100%;
	margin-right: -5px;
	top: 50%;
	transform: translateY(-50%);
}
.cpnt-statusradio-left:after {
	border-left-color: #FAFAFA;
}
.cpnt-statusradio-left:before {
	border-left-color: #D8D8D8;
	margin-right: -1px;
}
.cpnt-statusradio-left:after,
.cpnt-statusradio-left:before {
	position: absolute;
	top: 50%;
	right: -30px;
	margin-top: -10px;
	transform: translateX(-50%);
}

/* Bottom seta cima */
.cpnt-statusradio-bottom {
	left: 50%;
	margin-top: 10px;
	top: 100%;
	transform: translateX(-50%);
}

.cpnt-statusradio-bottom:after {
	border-bottom-color: #FAFAFA;
}
.cpnt-statusradio-bottom:before {
	/* border-bottom-color: #D8D8D8; */
	border-bottom-color: #9D9D9D;
	margin-right: -1px;
}

.cpnt-statusradio-bottom::after,
.cpnt-statusradio-bottom::before {
	position: absolute;
	top: 0;
	left: 50%;
	margin-top: -20px;
	transform: translateX(-50%);
}
