/*
** Default
** ================================================== */
.cpnt-tip {
	font-size: 14px;
	background-color: #FFF;
	border-radius: 3px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	border: 1px solid #D8D8D8;
	padding: 15px;
	z-index: 99999;
}
.cpnt-tip-tip {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 0;
	max-width: 100%;
}
.cpnt-tip-titulo {
	padding: 10px 15px;
	border-bottom: 1px solid #D7D7D7;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -15px;
	margin-bottom: 10px;
	background-color: #F7F9FA;
}
.cpnt-tip-conteudo-info {
	max-width: 300px;
}