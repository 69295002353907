.cpnt-chatsidebar {
	min-width: 400px;
	max-width: 400px;
	box-sizing: border-box;
	display: flex;
	border-right: 1px solid #DDDDDD;
}

/*
** Header
** ================================================== */
.cpnt-chatsidebar-header {
	padding: 10px;
	color: #FFF;
	background-color: #404040;
	margin-right: -1px;
}
.cpnt-chatsidebar-pesquisa {
	width: 100%;
	box-sizing: border-box;
	outline: 0;
	padding: 10px 45px 10px 15px;
	border-radius: 20px;
	border: 1px solid #DDD;
}
.cpnt-chatsidebar-pesquisa-wrap {
	position: relative;
	padding: 13px 10px 14px;
	display: flex;
	align-items: center;
	background-color: #FBFBFB;
}
.cpnt-chatsidebar-pesquisa-box {
	position: relative;
	flex: 1;
}
.cpnt-chatsidebar-pesquisa-box:after {
	content: '';
	width: 20px;
	height: 20px;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
	background: url('./magnifying-glass.svg') no-repeat center center;
	background-size: contain;
}
.cpnt-chatsidebar-countfiltro {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 7px 15px;
	border-top: 1px solid #DDDDDD;
	border-bottom: 1px solid #DDDDDD;
	font-size: 12px;
	margin-top: -1px;
}

/*
** Pesquisa
** ================================================== */
.cpnt-chatsidebar-filtros-wrap {
	display: flex;
	padding: 7px 15px;
	border-bottom: 1px solid #DDDDDD;
	border-top: 1px solid #DDDDDD;
	font-size: 12px;
	justify-content: space-between;
	align-items: center;
}
.cpnt-chatsidebar-filtros-wrap .cpnt-chatsidebar-pesquisa-label {
	font-size: 13px;
	align-self: center;
	padding-left: 5px;
}

/*
** Lista
** ================================================== */
.cpnt-chatsidebar-box {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	flex: 1;
}
.cpnt-chatsidebar-lista {
	flex: 1;
	max-height: 100%;
	overflow-y: auto;
}
.cpnt-chatsidebar-semresultado {
	padding: 15px;
	font-size: 14px;
	text-align: center;
}

/*
** Vazio
** ================================================== */
.cpnt-chatsidebar-info-vazio {
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
}
.cpnt-chatsidebar-info-qtd {
	color: var(--color-1);
	font-size: 80px;
}

@media (max-width: 1580px) {
	.cpnt-chatsidebar {
		min-width: 320px;
		max-width: 320px;
	}
	.cpnt-chatlistaitem-nome {
		font-size: 14px;
	}
	.cpnt-chatlistaitem-msg-status {
		font-size: 12px;
	}
	.cpnt-chatsidebar-countfiltro {
		justify-content: space-between;
		padding: 7px 10px;
	}

	/*
	** Responsivo portal
	** ================================================== */
	.cpnt-chatcontainer-res .cpnt-chatsidebar {
		min-width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;
	}
}
