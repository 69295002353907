.aviso-container {
	display: flex;
	padding: 20px;
}
.aviso-box {
	min-width: 50%;
	max-width: 50%;
}
.aviso-titulo {
	font-size: 38px;
	line-height: 1.1;
}
.aviso-e {
	align-self: center;
	font-size: 22px;
	color: #000;
	padding: 30px 0;
}
.aviso-d {
	position: relative;
	align-self: center;
}

@media (max-width: 980px) {
	.aviso-container {
		flex-direction: column;
		padding: 15px;
	}
	.aviso-box {
		min-width: auto;
		max-width: none;
	}
}
