.container {
	width: 690px;
}
.textos {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--gap-item);

	.img-sucesso {
		max-width: 150px;
	}

	.titulo {
		text-align: center;
	}

	@media (max-width: 480px) {
		flex-direction: column;
	}
}
