.box-topo {
	display: flex;
	justify-content: space-between;
}
.left {
	text-align: center;
}
.right {
	width: 300px;
}
.avaliacoes {
	text-align: center;
}
.total {
	font-size: 42px;
	font-weight: bold;
}
.boxes-avaliacoes {
	margin-top: 30px;
}
.avaliacao {
	margin-top: 15px;
}
.pt-aval-box-avaliacao + .pt-aval-box-avaliacao {
	margin-top: 15px;
}

@media (max-width: 680px) {
	.box-topo {
		flex-direction: column;
		align-items: center;
		row-gap: 30px;
	}
	.right {
		width: 96%;
	}
}