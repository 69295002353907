.cpnt-radio2 label {
	cursor: pointer;
	display: inline-flex;
}
.cpnt-radio2 [type="radio"] {
	display: none;
}
.cpnt-radio2 .cpnt-radio2-check {
	position: relative;
	display: inline-flex;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	border: 2px solid #929292;
	background-color: #FFF;
	vertical-align: middle;
	transition: all 0.3s ease 0s;
}
.cpnt-radio2 .cpnt-radio2-label {
	font-size: 16px;
	padding-left: 10px;
	margin-top: -3px;
}
.cpnt-radio2 [type="radio"]:checked ~ .cpnt-radio2-check {
	background-color: #2E2F31;
	background: #000 url('../../assets/imagens/icones/check.png') no-repeat center center;
	background-size: 90% 90%;
	border-color: #2E2F31;
}

.cpnt-radio2 a {
	font-weight: bold;
	text-decoration: none;
}
.cpnt-radio2-small-text .cpnt-radio2-label {
	font-size: 13px;
}
.cpnt-radio2-small-text .cpnt-radio2-label {
	margin-top: 0;
}
.cpnt-radio2-invalido .cpnt-radio2-check {
	border-color: #cf2c2c;
}
.cpnt-radio2-invalido .cpnt-radio2-label {
	color: #cf2c2c;
}
