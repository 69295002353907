.cpnt-btnicodesc-wrapper {
	cursor: pointer;
	font-size: 15px;
	display: flex;
	border-radius: 3px;
	padding: 10px;
	/* box-shadow: 0 1px 1px rgba(0,0,0,0.1); */
	border: 1px solid #D8D8D8;
	align-items: center;
}
.cpnt-btnicodesc-verde {
	background-color: #f4fff6;
}
.cpnt-btnicodesc-vermelho {
	background-color: #fff9f9;
}
.cpnt-btnicodesc-azul {
	background-color: #f6fcff;
}
.cpnt-btnicoedesc-alt {
	font-size: 14px;
	padding: 8px;
}