.cpnt-info-texto {
	margin-top: 20px;
	font-size: 18px;
	text-align: center;
}
.cpnt-info-texto a {
	font-weight: bold;
	text-decoration: none;
}
.cpnt-info-wrap {
	margin: auto;
}
.cpnt-info-icone-wrap {
	margin: auto;
	max-width: 160px;
	width: 100%;
}
.cpnt-info-icone-wrap img {
	width: 100%;
	display: block;
}

.cpnt-info-icone-wrap-alt {
	max-width: 100px;
}