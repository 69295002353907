.cpnt-boxwrap-box {
	background-color: #FFF;
	border-radius: 3px;
	border: 1px solid #D8D8D8;
	padding: 15px;
}
.cpnt-boxwrap-titulo-wrap {
	padding: 10px 15px;
	border-bottom: 1px solid #D7D7D7;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -15px;
	margin-bottom: 10px;
	background-color: #F7F9FA;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.cpnt-boxwrap-titulo {
	margin: 0;
}
