.cpnt-porcentagemhorizontal-inner-wrap {
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #F7F9FA;
	position: relative;
	border: 1px solid #DFDFDF;
	font-size: 13px;
	padding: 2px 0;
}
.cpnt-porcentagemhorizontal-porcentagem {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 0;
}

.cpnt-porcentagemhorizontal-texto {
	z-index: 1;
	text-shadow: 1px 1px rgba(255, 255, 255, 0.3), -1px -1px rgba(255, 255, 255, 0.3), -1px 1px rgba(255, 255, 255, 0.3), 1px -1px rgba(255, 255, 255, 0.3);
	filter: drop-shadow(0 0 2px #FFF);
}
