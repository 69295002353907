.cpnt-tooltip-titulo {
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	padding: 10px 15px;
	border-bottom: 1px solid #DDD;
}
.cpnt-tooltip-conteudo {
	padding: 15px;
}
.cptn-tooltip-circle {
	margin-left: 5px;
	margin-right: 5px;
	display: inline-flex;
	width: 16px;
	height: 16px;
}
