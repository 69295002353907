@import '@scss/imports.scss';

.header {
	@extend %flex-btw;

	font-size: var(--fs-default);
	padding: var(--gap);
	color: var(--color-white);
	background-color: var(--color-primary);
	height: var(--topbar-height);
	box-sizing: border-box;
	position: sticky;
	top: 0;
	z-index: 998;

	.logo-box {
		width: 150px;
		display: none;

		@media (max-width: 1280px) {
			display: block;
		}
	}

	.left,
	.right {
		position: relative;
		box-sizing: border-box;
		width: 160px;
		display: flex;
	}

	.right {
		justify-content: flex-end;
	}

	.texto-cadastro-incompleto {
		font-size: 13px;
		text-align: center;
	}

	@media (max-width: 680px) {
		.right-texto {
			display: none;
		}
		.right {
			font-size: var(--fs-icone-small);
		}
		.left,
		.right {
			width: 50px;
		}
		.texto-cadastro-incompleto {
			font-size: 13px;
		}
	}
}
