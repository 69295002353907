.cpnt-boxwrap2-titulo-wrap {
	padding: 10px 15px;
	border-bottom: 1px solid #D7D7D7;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -15px;
	margin-bottom: 15px;
	background-color: #F7F9FA;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.cpnt-boxwrap2-titulo {
	margin: 0;
}
