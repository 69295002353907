.pg-indiquecrowd-box {
	padding: 50px 15px;
	overflow: hidden;
}
.pg-indique-box {
	font-size: 16px;
	display: flex;
}
.pg-indique-l {
	padding-right: 50px;
}
.pg-indique-r {
	max-width: 350px;
	min-width: 350px;
}
.pg-indique-icones-box {
	display: flex;
	flex-wrap: wrap;
	font-size: 15px;
}
.pg-indique-icone {
	text-align: center;
	min-width: 14.2857%;
	max-width: 14.2857%;
	padding: 5px;
	box-sizing: border-box;
}
.pg-indique-icone img {
	display: block;
	width: 100%;
	max-width: 118px;
	margin: auto;
}
.indique-box-inner {
	padding: 30px;
}
.pg-indique-como-funciona-img {
	padding-left: 50px;
}
.pg-indique-como-funciona-lista li + li {
	margin-top: 10px;
}

@media (max-width: 980px) {
	.pg-indiquecrowd-box {
		padding: 15px 10px 50px;
	}
	.pg-indique-icone {
		min-width: 25%;
		max-width: 25%;
	}
	.pg-indique-box {
		flex-wrap: wrap;
	}
	.indique-box-inner {
		padding: 15px 0 0;
	}
}

@media (max-width: 980px) {
	.pg-indique-icone {
		min-width: 33.3333%;
		max-width: 33.3333%;
	}
}

@media (max-width: 680px) {
	.pg-indique-icone {
		min-width: 50%;
		max-width: 50%;
	}
}
