.cpnt-filtroswrap {
	padding: 10px;
}
.cpnt-filtros-wrap-btn-box {
	display: none;
}
@media (max-width: 980px) {
	.cpnt-filtros-wrap-btn-box {
		display: flex;
		justify-content: flex-end;
		padding-left: 15px;
	}
	.cpnt-filtroswrap-conteudo {
		display: none;
	}
	.cpnt-filtroswrap-show {
		display: block;
		padding-top: 5px;
	}
}
