/*
** Geral
** ================================================== */
.cpnt-portalconfirm-portal {
	background-color: #FFF;
	border-radius: 5px;
	width: 600px;
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	overflow: hidden;
	max-width: 100%;
}

/*
** Título
** ================================================== */
.cpnt-portalconfirm-titulo-default {
	border-bottom: 1px solid #CCC;
	background-color: #FAFAFA;
	font-size: 16px;
}
.cpnt-portalconfirm-titulo {
	padding: 15px;
}

/* Descrição */
.cpnt-portalconfirm-desc {
	padding: 15px;
	font-size: 16px;
}

/*
** Botões
** ================================================== */
.cpnt-portalconfirm-btns-default {
	border-top: 1px solid #CCC;
	background-color: #FAFAFA;
}
.cpnt-portalconfirm-btns {
	padding: 15px;
	display: flex;
	justify-content: flex-end;
}
