.cpnt-select3-inner {
	position: relative;
}

/* Menu */
.cpnt-select3-dropdown {
	position: absolute;
	background-color: #FFF;
	border-radius: 5px;
	box-shadow: 0 10px 15px rgba(0,0,0,0.1);
	z-index: 100;
	min-width: 150px;
	padding: 10px;
	border: 1px solid #D7D7D7;
	text-align: left;
}
.cpnt-select3-dropdown-ativo {
	display: block;
}
.cpnt-select3-dropdown:before,
.cpnt-select3-dropdown:after {
	content: '';
	position: absolute;
	border: 10px solid transparent;
}

/* Item */
.cpnt-select3-item,
.select3-input-box label {
	font-size: 14px;
	display: flex;
	cursor: pointer;
	padding: 7px 10px;
	border-radius: 3px;
	background-color: transparent;
	transition: all 0.1s ease 0s;
}
.cpnt-select3-item:hover,
.select3-input-box label:hover {
	background-color: #e4ebee;
}

/* Titulo */
..cpnt-select3-titulo:not(:first-child) {
	margin-top: 10px;
	font-size: 14px;
	padding: 7px 10px;
}
.cpnt-select3-titulo-box {
	padding: 10px;
	border-bottom: 1px solid #D7D7D7;
	margin-left: -10px;
	margin-right: -10px;
	margin-top: -10px;
	margin-bottom: 10px;
}
.cpnt-select3-titulo-secao {
	text-align: center;
	font-weight: bold;
	font-size: 18px;
}
.cpnt-select3-desc-secao {
	text-align: center;
	font-size: 13px;
	color: #757575;
}
.cpnt-select3-titulo-lista {
	padding: 10px 15px;
	border-bottom: 1px solid #D7D7D7;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -15px;
	margin-bottom: 10px;
	background-color: #F7F9FA;
}

/*
** Bottom
** ================================================== */
.cpnt-select3-dropdown-bottom {
	left: 50%;
	top: 100%;
	margin-top: 10px;
	transform: translateX(-50%);
}
.cpnt-select3-dropdown-bottom:before,
.cpnt-select3-dropdown-bottom:after {
	top: 0;
	left: 50%;
	margin-top: -10px;
	transform: translateX(-50%);
}
.cpnt-select3-dropdown-bottom::before {
	border-bottom-color: #D8D8D8;
	margin-top: -21px;
}
.cpnt-select3-dropdown-bottom::after {
	border-bottom-color: #FFF;
	margin-top: -20px;
}

/*
** Left
** ================================================== */
.cpnt-select3-dropdown-left {
	right: 100%;
	top: 50%;
	margin-right: 10px;
	transform: translateY(-50%);
}
.cpnt-select3-dropdown-left:before,
.cpnt-select3-dropdown-left:after {
	top: 50%;
	right: 0;
	margin-right: -10px;
	transform: translateY(-50%);
}
.cpnt-select3-dropdown-left::before {
	border-left-color: #D8D8D8;
	margin-right: -21px;
}
.cpnt-select3-dropdown-left::after {
	border-left-color: #FFF;
	margin-right: -20px;
}

/*
** Loading
** ================================================== */
/* Dropdown */
.cpnt-select3-dropdown-loading-dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	background-color: rgba(255,255,255,0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
}
.cpnt-select3-dropdown-loading-dropdown:before {
	content: '';
	position: absolute;
	background: url('@imgdefault/loading-azul.svg') no-repeat center center;
	background-size: contain;
	min-width: 50px;
	min-height: 50px;
}

/* Label */
.cpnt-select3-dropdown-loading-label {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	background-color: rgba(255,255,255,0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}
.cpnt-select3-dropdown-loading-label:before {
	content: '';
	position: absolute;
	background: url('@imgdefault/loading-azul.svg') no-repeat center center;
	background-size: contain;
	width: 100%;
	height: 100%;
	max-width: 50px;
	max-height: 50px;
}
